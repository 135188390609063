import React from 'react';
import {observer} from 'mobx-react-lite';
import {Col, FormGroup, Row} from 'reactstrap';
import {useStores} from '../../hooks/use-stores';
import generalConstants from '../../utils/constants';
import FormSelect from '../../components/common/forms/FormSelect';
import ResetPassword from '../../components/containers/resetPassword/ResetPassword';
import RemoveLocation from '../BrowseAllLocations/RemoveLocation';
import FormInput from '../../components/common/forms/FormInput';


const AdminTools = (props) => {
  const {locationsStore, dialogStore, usersStore} = useStores();
  const location_categories = locationsStore.categoriesParams;
  const location_types = locationsStore.typesParams;
  const featured_types = [
    {
      id: 0,
      label: 'Standard location',
      value: 0
    },
    {
      id: 1,
      label: 'Featured location',
      value: 1
    }
  ];
  // const approved_types = [{id: 1, label: 'Approved'}];
  const skipFilterName = +props.values.location_type === 1;
  const filterByName= props.values.location_type ? 'Residential' : '';
  const options = location_categories.filter(cat => {
    const res = cat.parentCatName.toLowerCase().includes(filterByName.toLowerCase());
    return skipFilterName ? !res : res;
  });
  options.sort((a, b) => {
    if (a.label.toLowerCase() < b.label.toLowerCase()) return -1;
    if (a.label.toLowerCase() > b.label.toLowerCase()) return 1;
    return 0;
  });
  
  const resetUserPassword = (row) => () => {
    dialogStore.openDialog(<ResetPassword user={row}/>, 'ResetPassword', {title: `Reset Password`, className: 'modal-confirm'});
  };

  const submit = (type) => () => {
    // props.checkImg();
    props.setFieldValue('type', type);
    props.submitForm();
  };

  const print = (id) => () => {
    locationsStore.printLocation(id)
  };

  const removeLocation = (location) => () => {
    dialogStore.openDialog(<RemoveLocation location={location} fromEdit/>, 'RemoveLocation', {title: `Remove location #${location.id}`, className: 'modal-confirm'})
  };
  return (
    <div className={`admin-tools align-self-start ${props.locationId ? 'admin-tools--edit' : ''}`}>
      <div className="bg-white p-3 mb-3 admin-tools-inner">
        <div className="form-title mb-3 font-weight-bold">Admin tools</div>
        <Row className="location-row-holder">
          <Col md={12}>
            <FormGroup>
              {props.locationId &&
              <div className="mb-2">
                <div className="form-title form-title-sub mb-3 font-weight-bold">This listing is linked to the following
                  user:
                </div>
                <FormGroup>
                  <FormSelect
                    className="form-select-container--light"
                    type="email"
                    name="user_email"
                    placeholder="User email"
                    error={props.errors.user}
                    value={props.values.user}
                    onChange={props.setFieldValue}
                    onlyValue={true}
                    options={usersStore.locationOwnersEmails}
                    onBlur={props.setFieldTouched}
                    touched={props.touched.user}
                  />
                </FormGroup>
              </div>
              }
              <FormGroup>
                <FormSelect
                  className="form-select-container--light"
                  name="location_type"
                  value={props.values.location_type}
                  onChange={props.setFieldValue}
                  placeholder="Select location type"
                  options={location_types}
                  error={props.errors.location_type}
                  onlyValue={true}
                  touched={props.touched.location_type}
                  onBlur={props.setFieldTouched}
                />
              </FormGroup>
              <FormGroup>
                <FormSelect
                  className="form-select-container--light"
                  name="location_category"
                  values={props.values}
                  isClearable={false}
                  onChange={props.setFieldValue}
                  onBlur={props.setFieldTouched}
                  touched={props.touched.location_category}
                  placeholder="Select location category(s)"
                  options={options}
                  error={props.errors.location_category}
                  setValues={props.setValues}
                  maximum={10}
                  isMulti
                />
              </FormGroup>
              <FormGroup>
                <FormSelect
                  className="form-select-container--light"
                  name="featured_location"
                  value={props.values.featured_location}
                  onChange={props.setFieldValue}
                  placeholder="Choose featured location"
                  onBlur={props.setFieldTouched}
                  touched={props.touched.featured_location}
                  options={featured_types}
                  error={props.errors.featured_location}
                  onlyValue={true}
                />
              </FormGroup>
              <FormGroup className={props.locationId && props.values.web_url ? 'd-flex align-items-end' : ''}>
                <FormInput
                  type="text"
                  name="web_url"
                  id="web_url"
                  value={props.values.web_url}
                  onBlur={props.handleBlur}
                  touched={props.touched.web_url}
                  // onChange={(e) => {
                  //   handleChange(e)
                  // }}
                  placeholder="URL"
                  errMessage={props.errors.web_url}
                />
                {props.locationId && locationsStore.editedLocation.web_url && 
                  <a className="btn btn-red btn-go mb-2 ml-3" rel="noopener noreferrer" role="button" target="_blank" href={`http://${locationsStore.editedLocation.web_url}`}>go</a>}
              </FormGroup>
              {/*for edit page*/}
              {/*{props.locationId && <FormSelect*/}
              {/*  className="form-select-container--light"*/}
              {/*  name="approved_location"*/}
              {/*  value={props.values.approved_location}*/}
              {/*  error={props.errors.approved_location}*/}
              {/*  touched={props.touched.approved_location}*/}
              {/*  onBlur={props.setFieldTouched}*/}
              {/*  onChange={props.setFieldValue}*/}
              {/*  placeholder="Approve location"*/}
              {/*  options={approved_types}*/}
              {/*/>}*/}
            </FormGroup>
          </Col>
          {/*for edit page*/}
          {props.locationId &&
            <div className="col-md-12 d-flex justify-content-between flex-wrap">
              <a href={`${generalConstants.stagingUrl}location_page/${props.locationId}`} target="_blank" rel="noopener noreferrer" className="btn-admin icon icon-eye-open p-1 border-0 font-weight-bold bg-transparent text-uppercase"><span>View</span></a>
              <button type="button" className="btn-admin icon icon-print p-1 border-0 font-weight-bold bg-transparent text-uppercase" onClick={print(props.locationId)}><span>PRINT</span></button>
              <button type="button" className="btn-admin icon icon-key p-1 border-0 font-weight-bold bg-transparent" onClick={resetUserPassword(props.values)}><span>SEND PASS RESET</span></button>
              <button type="button" className="btn-admin icon icon-trash-alt p-1 btn-del border-0 font-weight-bold bg-transparent" onClick={removeLocation(props.values)}><span>DELETE</span></button>
            </div>
          }
        </Row>
      </div>
      {props.locationId ?
        <div className="text-right">
          <button disabled={locationsStore.fetchingImg} className="btn btn-outline mr-4" onClick={submit(0)}>SAVE AS DRAFT</button>
          <button disabled={locationsStore.fetchingImg} className="btn btn-red" onClick={submit(1)}>SAVE PUBLISHED</button>
        </div> :
        <div className="text-right">
          <button disabled={locationsStore.fetchingImg} type="button" className="btn btn-outline mr-4" onClick={submit(0)}>SAVE AS DRAFT</button>
          <button disabled={locationsStore.fetchingImg} type="button" className="btn btn-red" onClick={submit(1)}>PUBLISH LOCATION</button>
        </div>
      }
    </div>
  );
};

export default observer(AdminTools);