import React from 'react';
import {observer} from 'mobx-react-lite';
import {useStores} from '../../hooks/use-stores';
import DataTable from '../../components/common/table/Table';
import {useTableParams} from '../../hooks/useTableParams';
import IndustryForm from './NewIndustry/IndustryForm';
import ViewIndustry from './NewIndustry/ViewIndustry';
import ResetPassword from '../../components/containers/resetPassword/ResetPassword';
import IndustryRemove from './NewIndustry/IndustryRemove';

const head = [
  {disableOrder: true, id: 'name', label: 'Name', className: 'wrapped-cell'},
  {disableOrder: true, id: 'jobTitle', label: 'Job Title', className: 'wrapped-cell'},
  {disableOrder: true, id: 'email', label: 'Email', className: 'wrapped-cell'},
  {disableOrder: true, id: 'telephone', label: 'Telephone'},
  {disableOrder: true, id: 'cityCounty', label: 'City/County'},
  {disableOrder: true, id: 'createdOn', label: 'Created on'},
  {disableOrder: true, id: 'lastModified', label: 'Last modified'},
  // {disableOrder: true, id: 'approved', label: 'Approved'},
  {disableOrder: true, id: 'actions', label: 'Actions'}
];

const LocationTable = () => {
  const {industryStore, dialogStore} = useStores();
  const tableParams = useTableParams();
  const isJob = (job) => {
    return job  ? `(${job})` : ''
  };
  const showModalEdit = (row) => () => {
    dialogStore.openDialog(<IndustryForm user={row}/>, 'IndustryForm',{title: `${row.first_name || ''} ${row.last_name || ''} ${isJob(row.job)}`.trim()})
  };

  const showModalView = (row) => () => {
    dialogStore.openDialog(<ViewIndustry user={row}/>, 'ViewIndustry', {title: `${row.first_name || ''} ${row.last_name || ''} ${isJob(row.job)}`.trim()})
  };

  const resetUserPassword = (row) =>  () => {
    dialogStore.openDialog(<ResetPassword user={row}/>, 'ResetPassword', {title: `Reset Password`, className: 'modal-confirm'})
  };

  const removeIndustry = (row) => () => {
    dialogStore.openDialog(<IndustryRemove user={row} />, 'IndustryRemove', {title: `Remove ${row.first_name || ''} ${row.last_name || ''}`.trim(), className: 'modal-confirm'})
  };

  return (
    <DataTable
      params={tableParams}
      head={head}
      data={industryStore.industryProfessionals.data}
    >{
      (row, i) => (<tr key={i}>
        <td className="border-top-0 p-1 align-top wrapped-cell">{`${row.first_name} ${row.last_name}`}</td>
        <td className="border-top-0 p-1 align-top wrapped-cell">{row.job}</td>
        <td className="border-top-0 p-1 align-top wrapped-cell">{row.email}</td>
        <td className="border-top-0 p-1 text-nowrap align-top">{row.phone}</td>
        <td className="border-top-0 p-1 text-nowrap align-top">{row.city || row.county}</td>
        <td className="border-top-0 p-1 text-nowrap align-top">{row.created_at}</td>
        <td className="border-top-0 p-1 text-nowrap align-top">{row.updated_at}</td>
        {/*<td className="text-nowrap border-top-0 p-1 align-top text-center td-approved">*/}
        {/*  <span className="icon-enable icon-disable icon-pending" />*/}
        {/*</td>*/}
        <td className="border-top-0 p-1 text-nowrap align-top">
          <button className="btn-table icon icon-eye-open mr-2 pl-0 border-0 font-weight-bold bg-transparent" onClick={showModalView(row)}><span>VIEW</span></button>
          <button className="btn-table icon icon-edit mr-2 border-0 font-weight-bold bg-transparent" onClick={showModalEdit(row)}><span>EDIT</span></button>
          <button className="btn-table icon icon-key mr-2 border-0 font-weight-bold bg-transparent" disabled={row.disabled} onClick={resetUserPassword(row)}><span>SEND PASS RESET</span></button>
          <button className="btn-table icon icon-trash-alt btn-del mr-2 border-0 font-weight-bold bg-transparent" onClick={removeIndustry(row)}><span>DELETE</span></button>
        </td>
      </tr>)
    }</DataTable>
  );
};

export default observer(LocationTable);
