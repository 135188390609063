import React from 'react';
import { Row, Col} from 'reactstrap';
import {observer} from 'mobx-react-lite';
import IndustryForm from '../../../pages/IndustryProfessionals/NewIndustry/IndustryForm';
import {useStores} from '../../../hooks/use-stores';
import '../industry.scss';
import IndustryMap from './IndustryMap';

const IndustryView = ({user}) => {
  const {dialogStore} = useStores();
  const isJob = (job) => {
    return job ? `(${job})` : '';
  };

  const edit = () => {
    dialogStore.closeDialog('IndustryView');
    dialogStore.openDialog(<IndustryForm
      user={user}/>, 'IndustryForm', {title: `${user.first_name || ''} ${user.last_name || ''} ${isJob(user.job)}`.trim()});
  };

  return (
    <div className="border-top pt-4">
      <div className="d-flex justify-content-between">
        <div className="contact-holder mb-4">
          <Row className="mb-2">
            <Col md={4}><b>Name:</b></Col>
            <Col md={8}>{`${user.first_name} ${user.last_name}`}</Col>
          </Row>
          <Row className="mb-2">
            <Col md={4}><b>Email:</b></Col> 
            <Col md={8} className="contact-holder__email">{`${user.email}`}</Col>
          </Row>
          <Row className="mb-2">
            <Col md={4}><b>Tel:</b></Col>
            <Col md={8}>{`${user.phone}`}</Col>
          </Row>
          <Row className="mb-2">
            <Col md={4}><b>Job Title:</b></Col>
            <Col md={8}>{`${user.job}`}</Col>
          </Row>
          <Row className="mb-2">
            <Col md={4}><b>Address:</b></Col>
            <Col md={8}><div>{user.address1}</div><div>{user.county}</div><div>{user.city}</div><div>{user.postcode}</div><div>United Kingdom</div></Col>
          </Row>
        </div>
        <div className="mb-4 map-placeholder">
          <IndustryMap user={user}/>
        </div>
      </div>
      <button type="button" className="btn btn-red" onClick={edit}>EDIT</button>
    </div>
  );
};

export default observer(IndustryView);