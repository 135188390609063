import React, {useCallback} from 'react';
import {observer} from 'mobx-react-lite';
import {useQueryParams} from '../../hooks/use-query-params';
import UrlPagination from '../../components/url-filters/UrlPagination';
import {useStores} from '../../hooks/use-stores';

const AdminFooter = () => {
  const [queryParams, setQueryParams] = useQueryParams();
  const {adminStore} = useStores();
  const handlePageClick = useCallback((data) => {
    setQueryParams(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="location-footer">
      <UrlPagination
        change={handlePageClick}
        pageCount={adminStore.adminStaffPageCount}
        page={queryParams.page}
      />
      <div className="pagination-show">Showing {adminStore.adminStaff.from}-{adminStore.adminStaff.to} of {adminStore.adminStaff.total}</div>
    </div>
  );
};

export default observer(AdminFooter);
