import React from 'react';
import {observer} from 'mobx-react-lite';
import {Col, FormGroup, Row} from 'reactstrap';
import FormSelect from '../../../components/common/forms/FormSelect';
import {useStores} from '../../../hooks/use-stores';

const StylesForm = (props) => {
  const {locationsStore} = useStores();
  const formOptions =  locationsStore.locationParams;
  const typesOptions = locationsStore.styleParams;
  const locationParamsExtended = locationsStore.locationParamsExtended;

  // if(!!formOptions.length || !!typesOptions.length) {
  //   return null
  // }
  if(!Object.keys(formOptions).length) return;

  return(
    <div className="location-item bg-white p-3 mb-3">
      <div className="form-title mb-3 font-weight-bold">Styles and features</div>
      <Row className="location-row-holder">
        <Col md={6}>
          <FormGroup>
            <FormSelect
              className="form-select-container--light"
              name="age_style"
              isClearable={false}
              value={props.values.age_style}
              values={props.values}
              error={props.errors.age_style}
              onChange={props.setFieldValue}
              onBlur={props.setFieldTouched}
              placeholder="Age or style of your location"
              touched={props.touched.age_style}
              options={typesOptions.age_style}
              setValues={props.setValues}
              isMulti
            />
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <FormSelect
              className="form-select-container--light"
              isClearable={false}
              name="interior_style"
              value={props.values.interior_style}
              error={props.errors.interior_style}
              values={props.values}
              onChange={props.setFieldValue}
              onBlur={props.setFieldTouched}
              placeholder="Interior style"
              touched={props.touched.interior_style}
              options={typesOptions.interior_style}
              setValues={props.setValues}
              isMulti
            />
          </FormGroup>
        </Col>
      </Row>
      <Row className="location-row-holder">
        <Col md={6}>
          <FormGroup>
            <FormSelect
              className="form-select-container--light"
              name="location_setting"
              value={props.values.location_setting}
              error={props.errors.location_setting}
              onChange={props.setFieldValue}
              onlyValue={true}
              onBlur={props.setFieldTouched}
              placeholder="Location setting"
              touched={props.touched.location_setting}
              options={locationParamsExtended.location_setting.items}
            />
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <FormSelect
              className="form-select-container--light"
              name="bedroomsNo"
              value={props.values.bedroomsNo}
              error={props.errors.bedroomsNo}
              onChange={props.setFieldValue}
              onlyValue={true}
              onBlur={props.setFieldTouched}
              placeholder="No of bedrooms (residential only)"
              touched={props.touched.bedroomsNo}
              options={locationParamsExtended.bedrooms.items}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row className="location-row-holder">
        <Col md={6}>
          <FormGroup>
            <FormSelect
              className="form-select-container--light"
              isClearable={false}
              name="specific_features"
              error={props.errors.specific_features}
              touched={props.touched.specific_features}
              values={props.values}
              onChange={props.setFieldValue}
              placeholder="Special features (select up to 20)"
              options={typesOptions.specific_features}
              setValues={props.setValues}
              maximum={20}
              isMulti
            />
          </FormGroup>
        </Col>
        <Col md={6}>
          <div className="info-detail-holder d-flex align-items-center justify-content-start">
              <span className="icon-holder mr-3">
                <i className="icon-info icon d-block" />
              </span>
            <p className="mb-0">You can select up to 20 special features that best reflect what your location has to offer</p>
          </div>
        </Col>
      </Row>
    </div>
  )
};

export default observer(StylesForm);