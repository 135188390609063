import React, {useState} from 'react';
import Select from 'react-select';
import {Label} from 'reactstrap';
import './form.scss';


const FormInputSelect = ({
  options: preOptions,
  onChange,
  value,
  isLoading,
  className = '',
  name,
  placeholder,
  error,
  disabled,
  onBlur,
  touched,
  label
}) => {
  const options = preOptions.map(o => {
    return {
      value: o.value || o.id,
      label: o.label || o.name
    }
  });
  const [inputValue, handleInputChange] = useState('');
  const val = options.find(o => (typeof o.value === 'string' ? o.value.trim() : o.value) === (typeof value === 'string' ? value.trim() : value)) ;
  const activeValue = val || null;

  const handleInputChanged = (input, reason) => {
    if (["set-value", "input-blur", "menu-close"].includes(reason.action)) return;
    handleInputChange(input);
    const a = [name, input];
    onChange(...a)
  };


  const singleChange = (data) => {
    const a = [name, data.value];
    onChange(...a);
  };

  const handleBlur = () => {
    if (onBlur) {
      onBlur(name, true)
    }
  };

  return (
    <>
      {label &&  <Label htmlFor={name} className={`${label ? 'form-select-label' : ''}`}>{placeholder}</Label>}
      <Select
        className={`form-select-container ${className}`}
        classNamePrefix="form-select"
        value={activeValue}
        placeholder={placeholder}
        options={options}
        isLoading={isLoading}
        onChange={singleChange}
        isDisabled={disabled}
        onBlur={handleBlur}
        onInputChange={handleInputChanged}
        blurInputOnSelect={false}
        inputValue={inputValue}
        onSelectResetsInput={false}
      />
      {error && touched && (
        <div className="error-message">{error}</div>
      )}
    </>
  )
};

export default  FormInputSelect;