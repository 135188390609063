import React, {useCallback, useEffect, useState} from 'react';
import {observer} from 'mobx-react-lite';
import {useStores} from '../../hooks/use-stores';
import {TabContent, TabPane, Nav, NavItem, NavLink, Row, Col, Spinner, Alert} from 'reactstrap';
import classnames from 'classnames';
import IndustryHeader from './IndustryHeader';
import {useQueryParams} from '../../hooks/use-query-params';
import IndustryTable from './IndustryTable';
import IndustryFooter from './IndustryFooter';
import debounce from 'lodash/debounce';
import MapViewIndustry from './MapViewIndustry/MapViewIndustry';

const IndustryProfessionals = () => {
  const {industryStore} = useStores();
  const [qp, setQueryParams] = useQueryParams();

  const [activeTab, setActiveTab] = useState('1');
  const [all, setAll] = useState(false);
  const change = useCallback(setQueryParams, []);

  const handlePageClick = useCallback((data) => {
    setQueryParams(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const listViewToggle = (tab) => () => {
    if(activeTab === tab) return;
    handlePageClick({per_page: ''});
    setAll(false);
    industryStore.setFetching();
    toggle('1');
  };

  const toggle = tab => {
    if(activeTab === tab) return;
    if (activeTab !== tab) {
      setActiveTab(tab);
      change({type: tab === '1' ? 1 : 2, page: 1});
    }
  };

  const fetchDebounce = useCallback(debounce((qp) => {
    industryStore.fetchIndustryProfessionals(qp);
  }, 500), []);

  useEffect(() => {
    industryStore.setFetching();
    fetchDebounce(qp);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [qp.page, qp.search, qp.sortBy, qp.filter, qp.per_page, qp.type]);


  return (
    <div className="main-holder industry-professionals">
      <div className="page-title mb-3 font-weight-bold">Browse all Industry Professionals</div>
      <div className="d-flex justify-space-between">
        <Nav tabs className="flex-nowrap">
          <NavItem>
            <NavLink
              className={classnames({active: activeTab === '1'})}
              onClick={listViewToggle('1')}
            >
              <span className="icon-list-ol">List view</span>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === '2' })}
              onClick={() => { toggle('2'); }}
            >
              <span className="icon-map-marked-alt">Map view</span>
            </NavLink>
          </NavItem>
        </Nav>
        <IndustryHeader/>
      </div>
      {industryStore.fetching ? <Spinner color="gray"/> :
        <TabContent activeTab={activeTab}>
          <TabPane tabId="1">
            <Row>
              <Col sm="12">
                <>
                  {industryStore.industryProfessionals.data.length
                    ?
                    <>
                      <IndustryTable/>
                      <IndustryFooter/>
                    </>
                    : <Alert color="light">
                      No results!
                    </Alert>
                  }
                </>
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="2">
            <Row>
              <Col sm={12}>
                  <MapViewIndustry />
                  <IndustryFooter
                    forMap={true}
                    all={all}
                    setAll={setAll}
                  />
              </Col>
            </Row>
          </TabPane>
        </TabContent>
      }
    </div>
  );
};

export default observer(IndustryProfessionals);
