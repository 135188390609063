import React from 'react';
import {observer} from 'mobx-react-lite';
import {Col, FormGroup, Row} from 'reactstrap';
import FormSelect from '../../../components/common/forms/FormSelect';
import {useStores} from '../../../hooks/use-stores';
import FormInput from '../../../components/common/forms/FormInput';

const AmenitiesForm = (props) => {
  const {locationsStore} = useStores();
  const formOptions =  locationsStore.locationParams;
  const locationParamsExtended = locationsStore.locationParamsExtended;


  if(!Object.keys(formOptions).length) return;

  return(
    <div className="location-item bg-white p-3 mb-3">
      <div className="form-title mb-3 font-weight-bold">Key information</div>
      <Row className="location-row-holder">
        <Col md={6}>
          <FormGroup>
            <FormSelect
              className="form-select-container--light"
              name="power_supply"
              value={props.values.power_supply}
              error={props.errors.power_supply}
              onChange={props.setFieldValue}
              onBlur={props.setFieldTouched}
              onlyValue={true}
              placeholder="Power supply"
              touched={props.touched.power_supply}
              // options={formOptions.power_supply.items}
              options={locationParamsExtended.power_supply.items}

            />
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <FormSelect
              className="form-select-container--light"
              name="water_source"
              value={props.values.water_source}
              error={props.errors.water_source}
              onChange={props.setFieldValue}
              onlyValue={true}
              onBlur={props.setFieldTouched}
              placeholder="Water source"
              touched={props.touched.water_source}
              options={locationParamsExtended.water_source.items}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row className="location-row-holder">
        <Col md={6}>
          <FormGroup>
            <FormSelect
              className="form-select-container--light"
              name="wifi"
              value={props.values.wifi}
              error={props.errors.wifi}
              onChange={props.setFieldValue}
              onlyValue={true}
              onBlur={props.setFieldTouched}
              placeholder="Wi-fi"
              touched={props.touched.wifi}
              options={locationParamsExtended.wifi.items}
            />
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <FormSelect
              className="form-select-container--light"
              name="parking"
              value={props.values.parking}
              error={props.errors.parking}
              onChange={props.setFieldValue}
              onlyValue={true}
              onBlur={props.setFieldTouched}
              placeholder="Parking availability"
              touched={props.touched.parking}
              options={locationParamsExtended.parking.items}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row className="location-row-holder">
        <Col md={12}>
          <FormGroup>
            <div className="form-input-holder w-100 mb-2">
            <FormInput
              type="text"
              name="parking_details"
              id="parking_details"
              className="details-input form-input"
              value={props.values.parking_details}
              // onlyValue={true}

              // onChange={(e) => {
              //   handleChange(e)
              // }}
              onBlur={props.handleBlur}
              placeholder="Give details of parking facilities (eg: size of parking area)"
              touched={props.touched.parking_details}
            />
            </div>
          </FormGroup>
        </Col>
      </Row>

      <Row className="location-row-holder">
        <Col>
          <FormGroup>
            <FormSelect
              className="form-select-container--light"
              name="filming_availability"
              value={props.values.filming_availability}
              error={props.errors.filming_availability}
              onChange={props.setFieldValue}
              onlyValue={true}
              onBlur={props.setFieldTouched}
              placeholder="Filming availability"
              touched={props.touched.filming_availability}
              options={locationParamsExtended.filming_availability.items}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row className="location-row-holder">
        <Col md={12}>
          <FormGroup>
            <div className="form-input-holder w-100 mb-2">
            <FormInput
              type="text"
              name="filming_details"
              id="filming_details"
              className="details-input form-input"
              value={props.values.filming_details}
              // onChange={(e) => {
              //   handleChange(e)
              // }}
              onBlur={props.handleBlur}
              placeholder="Give details of filming availability"
              touched={props.touched.filming_details}
              error={props.errors.filming_details}
            />
            </div>
          </FormGroup>
        </Col>
      </Row>
      <Row className="location-row-holder">
        <Col md={6}>
          <FormGroup>
            <FormSelect
              className="form-select-container--light"
              isClearable={false}
              name="additional_info"
              id="additional_info"
              type="text"
              values={props.values}
              error={props.errors.additional_info}
              onChange={props.setFieldValue}
              onBlur={props.setFieldTouched}
              placeholder="Additional info (tick all that apply)"
              touched={props.touched.additional_info}
              options={formOptions.additional_info.items}
              setValues={props.setValues}
              isMulti
            />
          </FormGroup>
        </Col>
        <Col md={6}>
          <div className="info-detail-holder d-flex align-items-start justify-content-start">
              <span className="icon-holder mr-3">
                <i className="icon-info icon d-block" />
              </span>
            <p className="mb-0">
              You can select as many additional info options that apply. Giving accurate details will asure the
              quality of your listing
            </p>
          </div>
        </Col>
      </Row>
    </div>
  )
};

export default observer(AmenitiesForm);