import React, {useCallback} from 'react';
import {observer} from 'mobx-react-lite';
import {useQueryParams} from '../../hooks/use-query-params';
import UrlFilter from '../../components/url-filters/UrlFilter';
import UrlSort from '../../components/url-filters/UrlSort';
import Search from '../../components/search/Search';

const filtersOptions = [
  { value: '', label: 'All location owners' },
  { value: 'featured', label: 'Owners of featured' },
  { value: 'standard', label: 'Owners of standard' },
  { value: 'residential', label: 'Owners of residential' },
  { value: 'non-residential', label: 'Owners of non-residential' }
];

const sortOptions = [
  { value: 'name', label: 'Name A-Z' },
  { value: 'email', label: 'Email A-Z' },
  { value: 'created', label: 'Date created' },
  { value: 'logged', label: 'Last logged in' }
];

const LocationHeaderTable = () => {
  const [qp, setQueryParams] = useQueryParams();
  const change = useCallback(setQueryParams, []);

  return (
    <div className="location-filters d-flex align-items-center justify-content-end">
      <div className="col-input w-100 px-1 position-relative">
        <Search value={qp.search || ''} change={change}/>
      </div>
      <div className="col-select w-100 px-1">
        <UrlFilter options={filtersOptions} value={qp.filter}  change={change} />
      </div>
      <div className="col-select w-100 pl-1 pr-0">
        <UrlSort options={sortOptions} value={qp.sortBy} change={change} placeholder={'Sort by'}/>
      </div>
    </div>
  );
};

export default observer(LocationHeaderTable);
