import React, {useEffect} from 'react';
import {observer} from 'mobx-react-lite';
import {useStores} from '../../hooks/use-stores';
import { Spinner } from 'reactstrap';
import './location.scss';
import EditLocationForm from './AddNewLocationForm/EditLocationForm';


const EditLocation = (props) => {
  const { usersStore, locationsStore} = useStores();

  useEffect(() => {
    locationsStore.fetchEditedLocation(props.match.params.id);
    locationsStore.fetchLocationParams();
    usersStore.fetchUserEmails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const editedLocation =  locationsStore.editedLocation;

  //
  // useEffect(() => {
  //   locationsStore.fetchEditedLocation(qp.id);
  // }, [qp.id]);

  return (
    <div className="main-holder industry-professionals">
      <div className="page-title mb-3 font-weight-bold">Edit {editedLocation.name} (id {editedLocation.id})</div>
      {locationsStore.fetching ? <Spinner color="gray" /> :
        <EditLocationForm locationId={props.match.params.id} />
      }
    </div>
  );
};

export default observer(EditLocation);
