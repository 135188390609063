import React from 'react';
import {Row, Col} from 'reactstrap';
import {observer} from 'mobx-react-lite';
import {useStores} from '../../hooks/use-stores';
import {useQueryParams} from '../../hooks/use-query-params';
import {go} from '../../utils/history';


const RemoveLocation = ({location, fromEdit}) => {
  const {dialogStore, locationsStore, uiStore} = useStores();
  const [qp] = useQueryParams();

  const remove = async () => {
    try {
      await locationsStore.deleteLocation(location.id);
      uiStore.createToast({status: 'success', title: 'Location removed', body: 'Location was removed' });
      dialogStore.closeDialog('RemoveLocation');
      fromEdit && go('/browse-locations?page=1');
      locationsStore.clearEditingLocation();
      await locationsStore.fetchLocations(qp);
    }catch(e)  {
      uiStore.createToast({status: 'danger', title: 'Location removed', body: `Error, ${uiStore.errorHandler(e)}` || 'Error, please try again' });
    }
  };

  const close = () => {
    dialogStore.closeDialog('RemoveLocation');
  };

  return (
    <div className="border-top pt-4">
      <Row className="mb-3">
        <Col>
          <span className="mr-2">Are you sure to remove location:</span>
          <span>{location.name}</span>
        </Col>
      </Row>
      <Row>
        <Col md={12} className="col-md-12 d-flex justify-content-end">
          <button type="button" className="btn btn-outline mr-4" onClick={remove}>REMOVE</button>
          <button type="button" className="btn btn-red" onClick={close}>CANCEL</button>
        </Col>
      </Row>
    </div>
  )
};

export default observer(RemoveLocation);