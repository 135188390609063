import React from 'react';
import {observer} from 'mobx-react-lite';
import ReactPaginate from 'react-paginate';

const UrlPagination = ({change, page, pageCount}) => {
  const onPageChange = (data) => {
    change({page: data.selected + 1});
  };

  return (
    pageCount !== 1 ? <ReactPaginate
      previousLabel={''}
      nextLabel={''}
      breakLabel={'...'}
      pageCount={pageCount}
      marginPagesDisplayed={0}
      pageRangeDisplayed={5}
      initialPage={page ? page - 1 : 0}
      onPageChange={onPageChange}
      subContainerClassName={'pages pagination'}
      breakClassName={'page-item'}
      breakLinkClassName={'page-link'}
      containerClassName={'pagination'}
      pageClassName={'page-item'}
      pageLinkClassName={'page-link'}
      previousClassName={'page-item'}
      previousLinkClassName={'page-link'}
      nextClassName={'page-item'}
      nextLinkClassName={'page-link'}
      activeClassName={'active'}
    /> : null
  );
};

export default observer(UrlPagination);
