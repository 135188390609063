import React from 'react';
import {observer} from 'mobx-react-lite';
import {Form, Formik} from 'formik';
import {useStores} from '../../../hooks/use-stores';
import NewUserForm from './NewUserForm';
import LocationContactForm from './LocationContactForm';
import StylesForm from './StylesForm';
import AmenitiesForm from './AmenitiesForm';
import ContentLoaderForm from './ContentLoaderForm';
import VideosForm from './VideosForm';
import AdminTools from '../AdminTools';
import {go} from '../../../utils/history';
import {addNewLocationSchema} from './NewLocationSchema';


const AddNewLocationForm = () => {
  const {locationsStore, uiStore} = useStores();

  const onSubmit = async ({type, ...values}) => {

    try {
      const {newEmail, currentEmail, location_setting, bedroomsNo, ...otherValues} = values;
      const resValues = {
        ...otherValues,
        user_email: newEmail || currentEmail,
        published: type,
        active: 1,
        location_setting: location_setting === '' ? 0 : location_setting,
        bedroomsNo: bedroomsNo === '' ? 0 : bedroomsNo
      };
      await locationsStore.addNewLocation(resValues);
      uiStore.createToast({status: 'success', title: 'Add Location', body: 'New location was added' });
      go('/browse-locations?page=1');
    }catch(e) {
      uiStore.createToast({status: 'danger', title: 'Add Location', body: `Error, ${uiStore.errorHandler(e)}` || 'Error, please try again' });
    }
  };


  return (
    <div className="p-0 flex-grow-1 flex-shrink-1">
      <Formik
        initialValues={{
          web_url: '',
          newEmail: '',
          currentEmail: '',
          send_email: 0,
          name: '',
          postCode: '',
          building: '',
          addressLine1: '',
          addressLine2: '',
          city: '',
          county: '',
          latitude: 0,
          longitude: 0,
          contact_name:  '',
          email_address: '',
          phone: '',
          phone_2: '',
          phone_professionals_only: true,
          phone_2_professionals_only: true,
          age_style: [],
          interior_style: '',
          location_setting: '',
          bedroomsNo: '',
          specific_features: [],
          power_supply: '',
          water_source: '',
          wifi: '',
          parking: '',
          parking_details: '',
          filming_availability: '',
          filming_details: '',
          additional_info: [],
          image_featured_urls: [],
          image_urls: [],
          pdf_urls: [],
          description: '',
          video_type: 0,
          video_url: '',
          location_type: '',
          location_category: [],
          featured_location: '',
          type: null,
          // approved_location: '',


          id: null,
        }}
        enableReinitialize
        validationSchema={addNewLocationSchema}
        onSubmit={onSubmit}
        >{({errors, submitForm, values, setFieldValue, setValues, setFieldTouched, touched, handleBlur}) => (
          <Form>
            <div className="d-flex justify-content-start justify-content-xxl-between">
              <div className="location-items-wrapper mr-4 flex-grow-1">
                <NewUserForm
                  errors={errors}
                  setFieldValue={setFieldValue}
                  values={values}
                  touched={touched}
                  setFieldTouched={setFieldTouched}
                  handleBlur={handleBlur}
                />
                <LocationContactForm
                  errors={errors}
                  setFieldValue={setFieldValue}
                  values={values}
                  setValues={setValues}
                  handleBlur={handleBlur}
                  setFieldTouched={setFieldTouched}
                  touched={touched}
                />
                <StylesForm
                  errors={errors}
                  setFieldValue={setFieldValue}
                  values={values}
                  setValues={setValues}
                  setFieldTouched={setFieldTouched}
                  touched={touched}
                />
                <AmenitiesForm
                  errors={errors}
                  setFieldValue={setFieldValue}
                  values={values}
                  setValues={setValues}
                  setFieldTouched={setFieldTouched}
                  touched={touched}
                />
                <ContentLoaderForm
                  setFieldValue={setFieldValue}
                  values={values}
                  errors={errors}
                  touched={touched}

                />
                <VideosForm
                  errors={errors}
                  setFieldValue={setFieldValue}
                  values={values}
                  setValues={setValues}
                  setFieldTouched={setFieldTouched}
                  touched={touched}
                />
              </div>
              <AdminTools
                errors={errors}
                setFieldValue={setFieldValue}
                values={values}
                submitForm={submitForm}
                setFieldTouched={setFieldTouched}
                touched={touched}
                setValues={setValues}
                // checkImg={checkImg}
              />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default observer(AddNewLocationForm);