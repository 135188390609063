import React, {useCallback, useEffect} from 'react';
import {observer} from 'mobx-react-lite';
import {useStores} from '../../hooks/use-stores';
import Filters from './LocationHeaderTable';
import LocationFooter from './LocationFooter';
import LocationTable from './LocationTable';
import {useQueryParams} from '../../hooks/use-query-params';
import {Spinner, Alert} from 'reactstrap';
import ShowPopover from './NewOwner/PopoverLocation';
import debounce from 'lodash/debounce';

const LocationOwners = () => {
  const {usersStore} = useStores();
  const [qp] = useQueryParams();

  const fetchDebounce = useCallback(debounce((qp) => {
    usersStore.fetchLocationOwners(qp);
  }, 500), []);

  useEffect(() => {
    fetchDebounce(qp);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [qp.page, qp.search, qp.sortBy, qp.filter]);

  return (
    <div className="main-holder location-owner">
      <h1 className="page-title mb-3 font-weight-bold">Browse all Location Owners</h1>
      <Filters/>
      {usersStore.fetching ? <Spinner color="gray"/> :
        <>
          {usersStore.locationOwners.data.length
            ?
            <>
              <LocationTable/>
              <LocationFooter/>
            </>
            : <Alert color="light">
              No results!
            </Alert>
          }
        </>
      }
      <ShowPopover/>
    </div>
  );
};

export default observer(LocationOwners);
