import React from 'react';
import {observer} from 'mobx-react-lite';
import {
  Col,
  Row,
  FormGroup,
} from 'reactstrap';
import FormInput from '../../../components/common/forms/FormInput';
import FormSelect from '../../../components/common/forms/FormSelect';
import FormGetAddress from '../../../components/common/forms/FormGetAddress';


const IndustryDetails = (props) => {
  return (
    <div>
      <Row>
        <Col sm="12">
          <Row>
            <Col md={6}>
              <FormGroup>
                <FormInput
                  type="text"
                  name="first_name"
                  placeholder="First Name"
                  error={props.errors.first_name}
                  touched={props.touched.first_name}
                  onBlur={props.handleBlur}
                  value={props.values.first_name}
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <FormInput
                  type="text"
                  name="last_name"
                  placeholder="Surname"
                  error={props.errors.last_name}
                  onBlur={props.handleBlur}
                  touched={props.touched.last_name}
                  value={props.values.last_name}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormGroup>
                <FormInput
                  type="email"
                  name="email"
                  placeholder="Email Address"
                  error={props.errors.email}
                  onBlur={props.handleBlur}
                  touched={props.touched.email}
                  value={props.values.email}
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <FormInput
                  type="text"
                  name="phone"
                  placeholder="Telephone number"
                  error={props.errors.phone}
                  onBlur={props.handleBlur}
                  touched={props.touched.phone}
                  value={props.values.phone}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <FormSelect
                  name="job"
                  value={props.values.job}
                  onlyValue={true}
                  onChange={props.setFieldValue}
                  placeholder="Select job title"
                  touched={props.touched.job}
                  options={props.jobOptions}
                  error={props.errors.job}
                  onBlur={props.setFieldTouched}
                />
              </FormGroup>
            </Col>
          </Row>
          <FormGetAddress
            setValues={props.setValues}
            setFieldValue={props.setFieldValue}
            values={props.values}
            errors={props.errors}
            touched={props.touched}
            onBlur={props.setFieldTouched}
          />
        </Col>
      </Row>
    </div>
  );
};

export default observer(IndustryDetails);