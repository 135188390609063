import React, {useEffect} from 'react';
import {observer} from 'mobx-react-lite';
import Widgets from './Widgets/Widgets';
import Tables from './Tables/Tables';
import {useStores} from '../../hooks/use-stores';
import {useQueryParams} from '../../hooks/use-query-params';
import ShowPopover from '../LocationOwners/NewOwner/PopoverLocation';
import './dashboard.scss';

const Dashboard = () => {
  const {usersStore, industryStore, dashboardStore} = useStores();
  const [qp] = useQueryParams();
  let forDash = true;

  useEffect(() => {
    usersStore.fetchLocationOwners(qp, forDash);
    industryStore.fetchIndustryProfessionals(qp, forDash);
    dashboardStore.fetchDashboardStat()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  return (
    <div className="main-holder dashboard-page">
      <div className="dashboard-inner">
        <h1 className="page-title mb-3 font-weight-bold">Kays Locations Dashboard</h1>
        <Widgets/>
        <Tables/>
        <ShowPopover/>
      </div>
    </div>
  );
};

export default observer(Dashboard);
