import React, {useEffect} from 'react';
import {observer} from 'mobx-react-lite';
import {useStores} from '../../../hooks/use-stores';
import {useTableParams} from '../../../hooks/useTableParams';
import ViewOwner from '../../LocationOwners/NewOwner/ViewOwner';
import PopoverTable from '../../LocationOwners/NewOwner/PopoverTable';
import DataTable from '../../../components/common/table/Table';


const head = [
  {disableOrder: true, id: 'name', label: 'Name'},
  {disableOrder: true, id: 'email', label: 'Email'},
  {disableOrder: true, id: 'lastLoggedIn', label: 'Last Logged in'},
  {disableOrder: true, id: 'actions', label: 'Actions'}
];

const WidgetLocationTable = () => {
  const {usersStore, uiStore, dialogStore} = useStores();
  const tableParams = useTableParams();

  useEffect(() => {
    return () => {
      uiStore.clearPopovers();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const viewOwner = (row) => () => {
    dialogStore.openDialog(<ViewOwner owner={row}/>, 'ViewOwner', {title: `View ${row.first_name || ''} ${row.last_name || ''}`.trim(), className: "modal-confirm"})
  };

  const showLocation = (row) => (e) => {
    uiStore.createPopover({user: row, title: 'Linked locations', target: e.target, content: <PopoverTable id={row.id}/> });
  };

  return (
    <DataTable
      params={tableParams}
      head={head}
      data={usersStore.locationOwners.data}
      padding={false}
    >{
      (row, i) => (<tr key={i}>
        <td className="text-nowrap border-top-0 p-1 align-middle">{`${row.first_name || ''} ${row.last_name || ''}`}</td>
        <td className="text-nowrap border-top-0 p-1 align-middle">{row.email}</td>
        <td className="text-nowrap border-top-0 p-1 align-middle">{row.updated_at}</td>
        <td className="text-nowrap border-top-0 p-1 align-middle">
          <button className="btn-table icon icon-link-symbol mr-2 pl-0 border-0 font-weight-bold bg-transparent" disabled={!row.locations_count} onClick={showLocation(row)}><span>LINKED LOCATIONS</span></button>
          <button className="btn-table icon icon-eye-open mr-2 border-0 font-weight-bold bg-transparent" onClick={viewOwner(row)}><span>VIEW</span></button>
        </td>
      </tr>)
    }</DataTable>
  );
};

export default observer(WidgetLocationTable);
