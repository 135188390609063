import {action, observable} from 'mobx';
import * as uuid from 'uuid/v4';
import { create, persist } from 'mobx-persist'


class UIStore {
  @observable counter = 0;

  @persist @observable showSidebar = localStorage.uiStore ? JSON.parse(localStorage.uiStore || '{}').showSidebar : true;

  @observable toastsList = [];

  @observable popoversList = [];

  @observable showUserModal = false;

  @observable currentUserModal = null;

  @action createToast = (toast, timer = 5000) => {
    toast.id = uuid();
    this.toastsList.push(toast);
    setTimeout( () => this.deleteToast(toast.id), timer)
  };

  @action deleteToast = (id) => {
    this.toastsList = this.toastsList.filter(i => i.id !== id);
  };

  @action clearPopovers = () => {
    this.popoversList = []
  };

  @action createPopover = (popover) => {
    popover.id = uuid();
    this.popoversList.push(popover);
  };

  @action deletePopover = (id) => {
    this.popoversList = this.popoversList.filter(i => i.id !== id);
  };


  @action setShowSidebar = () => {
    this.showSidebar = !this.showSidebar;
  };

  @action increment = () => {
    this.counter++;
  };

  @action decrement = () => {
    this.counter--;
  };

  @action setUserModal = (id) => {
    this.currentUserModal = id;
    this.showUserModal = !this.showUserModal;
  };

  @action errorHandler = (error) => {
    return error.response.data && error.response.data.errors ? Object.values(error.response.data.errors) : error.response.data.message;
  };
}

const hydrate = create({
  storage: localStorage,
  jsonify: true
});

const uiStore = new UIStore();
hydrate('uiStore', uiStore).then(() => {});
export default uiStore;
