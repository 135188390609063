import React from 'react';
import {observer} from 'mobx-react-lite';
import {Form, Formik} from 'formik';
import {useStores} from '../../../hooks/use-stores';
import LocationContactForm from './LocationContactForm';
import StylesForm from './StylesForm';
import AmenitiesForm from './AmenitiesForm';
import ContentLoaderForm from './ContentLoaderForm';
import VideosForm from './VideosForm';
import AdminTools from '../AdminTools';
import {go} from '../../../utils/history';
import {editLocationSchema} from './NewLocationSchema';


const EditLocationForm = (props) => {
  const {locationsStore, uiStore} = useStores();

  const editedLocation = locationsStore.editedLocation;
  const getKeywords = (name) => {
    return editedLocation && editedLocation.keywords.filter(i => (i.type === name)).map(i => i.id);
  };




  const onSubmit = async ({type, ...values}) => {
    try {
      const resValues = {
        ...values,
        published: type,
        active: 1
      };
      await locationsStore.updateLocation(resValues);
      uiStore.createToast({status: 'success', title: 'Edit Location', body: 'Location details were updated' });
      go('/browse-locations?page=1');
    }catch(e)  {
      uiStore.createToast({status: 'danger', title: 'Location editing', body: `Error, ${uiStore.errorHandler(e)}` || 'Error, please try again' });
    }
  };
  if (!Object.keys(editedLocation).length) return;

  return (
    <div className="p-0 flex-grow-1 flex-shrink-1">
      <Formik
        initialValues={{
          web_url: editedLocation.web_url || '',
          name: editedLocation.name || '',
          postCode: editedLocation.postcode || '',
          building: editedLocation.building || '',
          addressLine1: editedLocation.address_line1 || '',
          addressLine2: editedLocation.address_line2 || '',
          city: editedLocation.city || '',
          county: editedLocation.county || '',
          latitude: editedLocation.latitude || 0,
          longitude: editedLocation.longitude || 0,
          contact_name: editedLocation.contact_name || '',
          email_address: editedLocation.email_address || '',
          user_email: [editedLocation.email_address] || '',
          phone: editedLocation.phone || '',
          phone_2: editedLocation.phone_2 || '',
          phone_professionals_only: !!editedLocation.phone_professionals_only || true,
          phone_2_professionals_only: true,
          age_style: (editedLocation.keywords && getKeywords('age_style')) || [],
          interior_style: (editedLocation.keywords && getKeywords('interior_style')) || [],
          location_setting: typeof editedLocation.location_setting.id === 'number' ? editedLocation.location_setting.id : '',
          bedroomsNo: typeof editedLocation.bedrooms.id === 'number' ? editedLocation.bedrooms.id : '',
          specific_features: (editedLocation.keywords && getKeywords('specific_features')) || [],
          power_supply: typeof editedLocation.power_supply.id === 'number' ? editedLocation.power_supply.id : '',
          water_source: typeof editedLocation.water_source.id === 'number' ? editedLocation.water_source.id : '',
          wifi: typeof editedLocation.wifi.id === 'number' ?  editedLocation.wifi.id : '',
          parking: typeof editedLocation.parking.id === 'number' ? editedLocation.parking.id : '',
          parking_details: editedLocation.parking_details || '',
          filming_availability: typeof editedLocation.filming_availability.id === 'number' ? editedLocation.filming_availability.id : '',
          filming_details: editedLocation.filming_details || '',
          additional_info: (editedLocation.additional_info || []).map(i => i.id) || '',
          image_featured_urls: editedLocation.image_featured_urls || [],
          image_urls: editedLocation.image_urls || [],
          pdf_urls: editedLocation.pdf_urls || [],
          description: editedLocation.description || '',
          video_type: (editedLocation.video_type && ['youtube', 'vimeo', 'matterport'].indexOf(editedLocation.video_type) + 1) || 0,
          video_url: editedLocation.video_url || '',
          location_type: editedLocation.location_type_id.id || '',
          location_category: (editedLocation.categories || []).map(i => i.id) || '',
          featured_location: editedLocation.featured || 0,
          send_email: 1,
          user_id: editedLocation.user_id || null,
          user: editedLocation.user.email,
          id: editedLocation.id,
        }}
        enableReinitialize
        validationSchema={editLocationSchema}
        onSubmit={onSubmit}

      >{({errors, submitForm, values, setFieldValue, setValues, setFieldTouched, touched, handleBlur}) => (
        <Form>
          <div className="d-flex justify-content-between">
            <div className="location-items-wrapper flex-grow-1 mr-4">
              <LocationContactForm
                errors={errors}
                setFieldValue={setFieldValue}
                values={values}
                setValues={setValues}
                handleBlur={handleBlur}
                setFieldTouched={setFieldTouched}
                touched={touched}
              />
              <StylesForm
                errors={errors}
                setFieldValue={setFieldValue}
                values={values}
                setValues={setValues}
                setFieldTouched={setFieldTouched}
                touched={touched}
              />
              <AmenitiesForm
                errors={errors}
                setFieldValue={setFieldValue}
                values={values}
                setValues={setValues}
                setFieldTouched={setFieldTouched}
                touched={touched}
              />
              <ContentLoaderForm
                setFieldValue={setFieldValue}
                values={values}
                errors={errors}
                touched={touched}
              />
              <VideosForm
                errors={errors}
                setFieldValue={setFieldValue}
                values={values}
                setValues={setValues}
                setFieldTouched={setFieldTouched}
                touched={touched}
              />
            </div>
            <AdminTools
              errors={errors}
              setFieldValue={setFieldValue}
              values={values}
              submitForm={submitForm}
              locationId={props.locationId}
              setFieldTouched={setFieldTouched}
              touched={touched}
              setValues={setValues}
            />
          </div>
        </Form>
      )}
      </Formik>
    </div>
  );
};

export default observer(EditLocationForm);