import React, {useState} from 'react';
import {observer} from 'mobx-react-lite';
import {
  Button, Nav, NavItem, NavLink, TabContent, TabPane
} from 'reactstrap';
import {Form, Formik} from 'formik';

import * as Yup from 'yup';
import classnames from 'classnames';
import IndustryDetails from './IndustryDetails';
import IndustryAccess from './IndustryAccess';
import {useStores} from '../../../hooks/use-stores';
import {useQueryParams} from '../../../hooks/use-query-params';

const phoneHelper = Yup.lazy((val = '') => {
  if (!val.startsWith('0')) {
    return Yup.string().length(0, 'Should start with zero').required('Phone number is required')
  } else if (val.startsWith('020')) {
    return Yup.string().matches(/^020-\d{4}\s?\d{4}$/, 'Enter 020-XXXX XXXX || 0XXXX-XXX XXX')
  } else if (val.match(/^\d{4}-/)) {
    return Yup.string().matches(/^\d{4}-\d{3}\s?\d{4}$/, 'Enter 0XXX-XXX XXXX')
  }
  return Yup.string().matches(/^\d{5}-\d{3}\s?\d{3}$/, 'Enter 0XXXX-XXX XXX')
});

const GBPostCode = /([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\s?[0-9][A-Za-z]{2})/;


const industrySchema = (user) => {
  return Yup.object().shape({
    first_name: Yup.string().required('First name is required'),
    last_name: Yup.string().required('Surname is required'),
    job: Yup.string().required('Job is required'),
    email: Yup.string().email('Email is invalid').required('Email is required'),
    phone: phoneHelper,
    postCode: Yup.string()
      .matches(GBPostCode, 'You should input correct UK postal code')
      .max(8, 'UK postal code length: ranging from six to eight characters (including a space)'),
    ...(user ? {} : {
      send_email: Yup.number()
        .required('Password params is required'),
    }),
    building: Yup.string()
  });
};

const jobOptions = [
  {label: 'Supervising Location Manager', value: 'Supervising Location Manager'},
  {label: 'Location Manager', value: 'Location Manager'},
  {label: 'Location Scout', value: 'Location Scout'},
  {label: 'Assistant Location Manager', value: 'Assistant Location Manager'},
  {label: 'Unit Manager', value: 'Unit Manager'},
  {label: 'Art Director', value: 'Art Director'},
  {label: 'Producer', value: 'Producer'},
  {label: 'Director', value: 'Director'},
  {label: 'Designer', value: 'Designer'},
  {label: 'Line Producer', value: 'Line Producer'},
  {label: 'Production Manager', value: 'Production Manager'},
  {label: 'Director of Photography', value: 'Director of Photography'},
  {label: 'Stills Photographer', value: 'Stills Photographer'},
  {label: 'Other', value: 'Other'}
];

const IndustryForm = ({user}) => {
  const [activeTab, setActiveTab] = useState('1');
  const [qp] = useQueryParams();
  const {industryStore, uiStore, dialogStore} = useStores();

  const toggle = tab => {
    if(activeTab !== tab) setActiveTab(tab);
  };

const onSubmit = async(values) => {
  try {
    await industryStore.addIndustryUser(values);
    uiStore.createToast({status: 'success', title: 'Add industry professional', body: 'Industry professional account was created' });
    dialogStore.closeDialog('IndustryForm');
    await industryStore.fetchIndustryProfessionals(qp);
  }catch(e)  {
    uiStore.createToast({status: 'danger', title: 'Add industry professional', body: `Error, ${uiStore.errorHandler(e)}` || 'Error, please try again' });
  }
};

const job = (user && jobOptions.find(item => item.label === user.job)) || '';

const onUpdate = async (values) => {
  try {
    await industryStore.updateIndustryUser(values, user.id);
    uiStore.createToast({status: 'success', title: 'Update industry professional', body: `Industry professional's account was updated` });
    dialogStore.closeDialog('IndustryForm');
    dialogStore.closeDialog('ViewIndustry');
    await industryStore.fetchIndustryProfessionals(qp);
  }catch (e) {
    uiStore.createToast({status: 'danger', title: `Update industry professional`, body: `Error, ${uiStore.errorHandler(e)}` || 'Error, please try again' });
  }

};

const validateCustom = (setFieldTouched, validateForm) => async () => {
  const r = await validateForm();
  if ((Object.keys(r).length === 1 && r.send_email) || Object.keys(r).length === 0) toggle('2');
  ['first_name', 'last_name', 'email', 'phone', 'job'].forEach(e => setFieldTouched(e, true));
};

  return (
    <div>
      <Formik
        initialValues={{
          first_name: (user && user.first_name) || '',
          last_name: (user && user.last_name) || '',
          email: (user && user.email) || '',
          phone: (user && user.phone) || '',
          postCode: (user && user.postcode) || '',
          addressLine1: (user && user.address1) || '',
          addressLine2: (user && user.address2) || '',
          city: (user && user.city) || '',
          county: (user && user.county) || '',
          building: (user && user.building) || '',
          job: (user && job.label) || '',
          send_email: '',
          latitude: (user && user.latitude) || '',
          longitude: (user && user.longitude) || '',
        }}
        enableReinitialize
        validationSchema={industrySchema(user)}
        onSubmit={user ? onUpdate : onSubmit}

        >{({errors, submitForm, values, setFieldValue, setValues, touched, setFieldTouched, handleBlur, validateForm }) => (
          <Form>
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === '1' })}
                  onClick={() => { toggle('1'); }}
                >
                  Contact details
                </NavLink>
              </NavItem>
              {!user &&<NavItem>
                <NavLink
                  className={classnames({ active: activeTab === '2' })}
                  onClick={validateCustom(setFieldTouched, validateForm)}
                >
                  Password & access
                </NavLink>
              </NavItem>}
            </Nav>
            <TabContent activeTab={activeTab}>
              <TabPane tabId="1">
                <IndustryDetails
                  setValues={setValues}
                  errors={errors}
                  jobOptions={jobOptions}
                  setFieldValue={setFieldValue}
                  values={values}
                  setFieldTouched={setFieldTouched}
                  touched={touched}
                  handleBlur={handleBlur}
                />
              </TabPane>
              {!user && <TabPane tabId="2">
                <IndustryAccess
                  setFieldValue={setFieldValue}
                  values={values}
                  errors={errors}
                  touched={touched}

                />
              </TabPane>}
            </TabContent>
            {user ?
              <Button className="btn-red" onClick={submitForm }>{'Update'}</Button>
              :
              <Button className="btn-red" onClick={activeTab === '1' ? validateCustom(setFieldTouched, validateForm) : submitForm }>{activeTab === '1' ? 'Next' : 'Add'}</Button>

              }
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default observer(IndustryForm);