import {action, computed, observable, runInAction} from 'mobx';
import api from '../../utils/api';


class AdminStore {
  @observable fetching = true;

  @observable adminStaff = {
    data: [],
    total: 0,
    per_page: 1
  };

  @computed  get adminStaffPageCount() {
    return Math.ceil(this.adminStaff.total / this.adminStaff.per_page)
  };

  @action setFetching = () => {
    this.fetching = true;
  };

  @action fetchAdminStaff = async (values) => {
    this.fetching = true;
    const data = {
      params: {
        search: values.search || '',
        filter: values.filter || '',
        sort_by: values.sortBy || '',
        page: values.page || ''
      }
    };
    try {
      const resp = await api.get('/dashboard/users/admins', data);
      runInAction(() => this.adminStaff = resp.data.data);
    } catch(e)  {
      throw e;
    } finally {
      runInAction(() => {
        this.fetching = false;
      });
    }
  };

  @action uploadAdminPhoto = async (file, name) => {
    this.fetching = true;
    const formData = new FormData();
      formData.append('file', file[0]);
      try {
        const resp = await api.post('/dashboard/users/upload', formData,
          {headers: {'content-type': `multipart/form-data`}}
        );
        runInAction(() => this.adminStaff.data[name] = resp.data.name);
      } catch (e) {
        throw e;
      } finally {
        runInAction(() => {
          this.fetching = false;
        });
      }
  };

  @action deleteAdminPhoto = async (name, type, id = null, idx) => {
    this.fetching = true;
    try {
      if (id) {
        //eslint-disable-next-line
        const fileUrl = name && name.match(/[^\/]+$/)[0];
        await api.delete(`/dashboard/users/${id}/photo/${fileUrl}`);
        await api.delete(`/dashboard/locations/file/${fileUrl}`);
        runInAction(() => this.adminStaff.data[name] = []);
      } else {
        runInAction(() => this.adminStaff.data[name] = []);
      }
    } catch (e) {
      throw e;
    } finally {
      runInAction(() => {
        this.fetching = false;
      });
    }
  };

  @action addNewAdmin = async (values) => {
    this.fetching = true;
    const data = {
      first_name: values.first_name,
      last_name: values.last_name,
      email: values.email,
      is_admin: values.is_admin,
      send_email: values.send_email,
      photo: this.adminStaff.data.photo,
      role: values.role,
    };
    try {
      await api.post('/dashboard/users/add', data);
    } catch (err) {
      throw err;
    } finally {
      runInAction(() => {
        this.fetching = false;
      });
    }
  };

  @action editAdmin = async (id, values) => {
    this.fetching = true;
    const data = {
      first_name: values.first_name,
      last_name: values.last_name,
      email: values.email,
      is_admin: values.is_admin,
      send_email: values.send_email,
      photo: this.adminStaff.data.photo
    };
    try {
      await api.patch(`/dashboard/users/${id}`, data);
    } catch (err) {
      throw err;
    } finally {
      runInAction(() => {
        this.fetching = false;
      });
    }
  };

  @action removeAdmin = async (id) => {
    this.fetching = true;
    try {
      await api.delete(`/dashboard/users/${id}`);
    } catch (e) {
      throw e;
    } finally {
      runInAction(() => {
        this.fetching = false;
      });
    }
  };

}

const adminStore = new AdminStore();
export default adminStore;
