import React, {useCallback, useEffect, useState} from 'react';
import {observer} from 'mobx-react-lite';
import {useStores} from '../../hooks/use-stores';
import {TabContent, TabPane, Nav, NavItem, NavLink, Row, Col, Spinner, Alert} from 'reactstrap';
import classnames from 'classnames';
import {useQueryParams} from '../../hooks/use-query-params';
import BrowseAllLocationsHeader from './BrowseAllLocationsHeader';
import BrowseAllLocationsTable from './BrowseAllLocationsTable';
import BrowseAllLocationsFooter from './BrowseAllLocationsFooter';
import debounce from 'lodash/debounce';
import BrowseAllLocationsMap from './BrowseAllLocationsMap';

const BrowseAllLocations = () => {
  const {locationsStore} = useStores();
  const [qp, setQueryParams] = useQueryParams();
  const [activeTab, setActiveTab] = useState(qp.type === 2 ?  'residential' : 'non-residential');
  const [mapViewNon, setMapViewNon] = useState(false);
  const [mapViewRes, setMapViewRes] = useState(false);
  const [all, setAll] = useState(false);
  const handlePageClick = useCallback((data) => {
    setQueryParams(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const locations = locationsStore.locations.data;

  const change = useCallback(setQueryParams, []);

  const toggleMapView = (tab) => () => {
    setAll(false);
    handlePageClick({per_page: '', page: 1});
    setMapViewNon(tab === 'non-residential' ? !mapViewNon : false);
    setMapViewRes(tab === 'non-residential' ? false : !mapViewRes);
  };


  const toggle = tab => () => {
    if(activeTab === tab) return;
    locationsStore.setFetching();
    if (activeTab !== tab) {
      setActiveTab(tab);
      change({type: tab === 'non-residential' ? 1 : 2, page: 1});
    }
    setMapViewRes(mapViewNon);
    setMapViewNon(mapViewRes);
  };

  const fetchDebounce = useCallback(debounce((qp) => {
    locationsStore.fetchLocations(qp);
  }, 500), []);

  useEffect(() => {
    locationsStore.setFetching();
    qp.type === '2' ? setActiveTab('residential') : setActiveTab('non-residential');
    fetchDebounce(qp);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [qp.page, qp.search, qp.sortBy, qp.filter, qp.type, qp.per_page]);


  return (
    <div className="main-holder browse-all-locations">
      <div className="page-title mb-3 font-weight-bold">Browse all locations</div>
      <div className="d-flex justify-space-between table-nav-holder">
        <Nav tabs className="flex-nowrap">
          <NavItem>
            <NavLink
              className={classnames({active: activeTab === 'non-residential'})}
              onClick={toggle('non-residential')}
            >
              <span className="icon-non-residental">Non-Residential</span>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({active: activeTab === 'residential'})}
              onClick={toggle('residential')}
            >
              <span className="icon-residental">Residential</span>
            </NavLink>
          </NavItem>
          {/*<NavItem>*/}
            <button onClick={toggleMapView(activeTab)} className="btn btn-transparent btn-tab"><span className={`icon icon-${mapViewNon || mapViewRes ?  'list-ol' : 'map-marked-alt'}`}></span><span>{mapViewNon || mapViewRes ?  'List view' : 'Map view'}</span></button>
            {/*<NavLink*/}
            {/*  className={classnames({ active: activeTab === 'map-view' })}*/}
            {/*  onClick={toggle('map-view')}*/}
            {/*>*/}
            {/*  <span className="icon-map-marked-alt">Map view</span>*/}
            {/*</NavLink>*/}
          {/*</NavItem>*/}
        </Nav>
        <BrowseAllLocationsHeader/>
      </div>
      {locationsStore.fetching ? <Spinner color="gray"/> :
        <TabContent activeTab={activeTab}>
          <TabPane tabId="non-residential">
            <Row>
              <Col sm="12">
                {locationsStore.locations.data.length
                  ?
                  <>
                  {mapViewNon ? <BrowseAllLocationsMap locations={locations}/> : <BrowseAllLocationsTable/>}
                    <BrowseAllLocationsFooter
                      forMap={mapViewNon}
                      all={all}
                      setAll={setAll}
                    />
                  </>
                  : <Alert color="light">
                    No results!
                  </Alert>
                }
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="residential">
            <Row>
              <Col sm="12">
                {locationsStore.locations.data.length
                  ?
                  <>
                    {mapViewRes ? <BrowseAllLocationsMap locations={locations}/> : <BrowseAllLocationsTable/>}

                    <BrowseAllLocationsFooter
                      forMap={mapViewRes}
                      all={all}
                      setAll={setAll}
                    />
                  </>
                  : <Alert color="light">
                    No results!
                  </Alert>
                }
              </Col>
            </Row>
          </TabPane>
          {/*<TabPane tabId="map-view">*/}
          {/*  Map view*/}
          {/*</TabPane>*/}
        </TabContent>
      }
    </div>
  );
};

export default observer(BrowseAllLocations);
