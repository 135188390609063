import React from 'react';
import {observer} from 'mobx-react-lite';
import {Modal, ModalBody, ModalHeader} from 'reactstrap';
import {useStores} from '../../../hooks/use-stores';



const DialogContainer = () => {
  const { dialogStore} = useStores();
    const {dialogsList, closeDialog} = dialogStore;

    // const close = (name) => () => {
    //   return closeDialog(name);
    // };

    return dialogsList.map((dialog) => (
      <Modal 
        isOpen={true} 
        modalClassName={dialog.data.className} 
        toggle={closeDialog.bind(dialogStore, dialog.key)} 
        key={dialog.key} 
        centered
      >
        <ModalHeader toggle={closeDialog.bind(dialogStore, dialog.key)}>{dialog.data.title}</ModalHeader>
        <ModalBody>
          {dialog.component}
        </ModalBody>
      </Modal>
    ));
}

export default observer(DialogContainer);
