import React from 'react';
import {Field} from 'formik';
import classnames from 'classnames';
import './formInput.scss';


const FormInput = ({
    type = 'text',
    name,
    placeholder,
    error,
    disabled,
    className,
    touched,
    onBlur,
    maxLength,
    value,
    floating = true
  }) => {

  const fieldClasses = classnames({
    'floating-label': true,
    'error-input': error && touched,
    [className]: className,
    [`form-${type === 'textarea' ? 'textarea' : 'input'}`]: true,
    'floating': !!value
  });

  return (
    <label className={fieldClasses}>
      <Field
        render={type === 'textarea' ? ({field}) => (
          <textarea
            {...field}
            maxLength={maxLength}
            disabled={disabled}
          />
        ) : null }
        name={name}
        touched={touched ? touched.toString() : ''}
        onBlur={onBlur}
        type={type}
        value={value}
        disabled={disabled}
      />
      {floating && <span>{placeholder}</span>}
      {error && touched && (
        <div className="error-message">{error}</div>
      )}
    </label>
  )
};

export default  FormInput;