import React, {useEffect, useState} from 'react';
import {useDropzone} from 'react-dropzone';
import {Button} from 'reactstrap';
import {useStores} from '../../../hooks/use-stores';

const DropZoneWrapper = (props) => {
  const {uiStore, locationsStore, adminStore} = useStores();
  const [files, setFiles] = useState([]);
  const handleDropRejected = (args) => {
    uiStore.createToast({status: 'danger', title: 'File error', body: args === 'quantity' ? `You can't upload more than ${props.quantity} files` : 'Each file must be less than 3Mb'});
  };

  const {getRootProps, getInputProps} = useDropzone({
    isFocused: true,
    accept: props.acceptedFiles,
    maxSize: 3145728,
    multiple: props.multi,
    onDrop: async acceptedFiles => {
      if ([...files, ...acceptedFiles].length === 0) return;
      if ([...files, ...acceptedFiles].length > props.quantity) return handleDropRejected('quantity');

      setFiles([...files, ...acceptedFiles.map(file => ({
        preview: URL.createObjectURL(file),
        name: URL.createObjectURL(file),
        error: false
      }))]);

      switch (props.name) {
        case 'photo':
          adminStore.uploadAdminPhoto(acceptedFiles, props.name);
          break;
        case 'csv':
          console.log('csv');
          break;
        case 'image_featured_urls':
          const [status] = await locationsStore.uploadFile(acceptedFiles, props.name);
          if (!status) {
            setFiles([]);
          }
          break;
        case 'image_urls':
          locationsStore.uploadFile(acceptedFiles, props.name);
          break;
        default:
          locationsStore.uploadFile(acceptedFiles, props.name);
      }
      props.setFieldValue(props.name, acceptedFiles);
    },
    onDropRejected: () => handleDropRejected('size')
  });


  useEffect(() => {
    if(props.name === 'photo' && !props.values[0]) return;
    setFiles(props.values.map((i) => ({preview: i, name: i})));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const remove = (file, idx) => (e) => {
    if (e) e.stopPropagation();
    switch (props.name) {
      case 'photo':
        adminStore.deleteAdminPhoto(file.name, props.name, props.id);
        break;
      case 'csv':
        console.log('csv');
        break;
      case 'image_featured_urls':
        locationsStore.deletePhoto(file.name, props.name, props.id, idx);
        break;
      default:
        locationsStore.deletePhoto(file.name, props.name, props.id, idx);
    }
    setFiles(files.filter(f => file.name !== f.name));


  };

  const thumbs = files.map((file, idx) => (
    <div className="d-flex thumb-wrapper" key={file.name}>
      {props.name === 'pdf_urls' || props.name === 'csv'
        ? <div className="pdf-holder">{file.name}</div>
        : <div className="img-holder">
          <img
            alt={file.name}
            src={file.preview}
            className=''
          />
        </div>
      }
      <Button onClick={remove(file, idx)}><span className="icon icon-times"></span></Button>
    </div>
  ));



  return (
    <div className={`dropzone ${props.className || ''}`}>
      <div className="dropzone-label">
        <span>{props.description}</span>
        <span className="note d-block">{props.note}</span>
      </div>
      <div {...getRootProps({className: 'dropzone-holder'})}>
        <input {...getInputProps()}/>
        <div className={`dropzone-area ${files.length ? 'dropzone-grid' : ''}`}>
          {files.length ? thumbs : props.placeholder || null}
        </div>
      </div>
      {props.error && props.touched && (
        <div className="error-message">{props.error}</div>
      )}
    </div>
  );

};

export default DropZoneWrapper;
