import React from 'react';
import {observer} from 'mobx-react-lite';
import {useStores} from '../../../hooks/use-stores';
import {useTableParams} from '../../../hooks/useTableParams';
import IndustryForm from '../../IndustryProfessionals/NewIndustry/IndustryForm';
import ViewIndustry from '../../IndustryProfessionals/NewIndustry/ViewIndustry';
import DataTable from '../../../components/common/table/Table';


const head = [
  {disableOrder: true, id: 'name', label: 'Name'},
  {disableOrder: true, id: 'jobTitle', label: 'Job Title'},
  {disableOrder: true, id: 'lastModified', label: 'Last modified'},
  {disableOrder: true, id: 'actions', label: 'Actions'}
];

const WidgetLocationTable = () => {
  const {industryStore, dialogStore} = useStores();
  const tableParams = useTableParams();
  const isJob = (job) => {
    return job  ? `(${job})` : ''
  };
  const showModalEdit = (row) => () => {
    dialogStore.openDialog(<IndustryForm user={row}/>, 'IndustryForm', {title: `${row.first_name || ''} ${row.last_name || ''} ${isJob(row.job)}`.trim()})
  };

  const showModalView = (row) => () => {
    dialogStore.openDialog(<ViewIndustry user={row}/>, 'ViewIndustry',{title: `${row.first_name} ${row.last_name || ''} ${isJob(row.job)}`.trim()})
  };

  return (
    <DataTable
      params={tableParams}
      head={head}
      data={industryStore.industryProfessionals.data}
      padding={false}
    >{
      (row, i) => (<tr key={i}>
        <td className="text-nowrap border-top-0 p-1 align-middle">{`${row.first_name} ${row.last_name}`}</td>
        <td className="text-nowrap border-top-0 p-1 align-middle">{row.job}</td>
        <td className="text-nowrap border-top-0 p-1 align-middle">{row.updated_at}</td>
        <td className="text-nowrap border-top-0 p-1 align-middle">
          <button className="btn-table icon icon-eye-open mr-2 pl-0 border-0 font-weight-bold bg-transparent" onClick={showModalView(row)}><span>VIEW</span></button>
          <button className="btn-table icon icon-edit mr-2 border-0 font-weight-bold bg-transparent" onClick={showModalEdit(row)}><span>EDIT</span></button>
        </td>
      </tr>)
    }</DataTable>
  );
};

export default observer(WidgetLocationTable);
