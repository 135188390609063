import React, {useCallback} from 'react';
import {observer} from 'mobx-react-lite';
import {useQueryParams} from '../../hooks/use-query-params';
import UrlFilter from '../../components/url-filters/UrlFilter';
import UrlSort from '../../components/url-filters/UrlSort';
import Search from '../../components/search/Search';
import {Button} from 'reactstrap';
import {useStores} from '../../hooks/use-stores';
import {Link} from 'react-router-dom';

const BrowseAllLocationsHeader = () => {
  const [qp, setQueryParams] = useQueryParams();
  const change = useCallback(setQueryParams, []);
  const {locationsStore} = useStores();

  const filters = [{label: 'All', value: ''}, ...locationsStore.locationsFilters];


  return (
    <div className="location-filters d-flex align-items-center justify-content-end flex-grow-1">
      <div className="col-input col-input w-100 px-1 position-relative">
        <Search value={qp.search || ''} change={change}/>
      </div>
      <div className="col-select col-select w-100 px-1">
        <UrlFilter options={filters} value={qp.filter} change={change} />
      </div>
      <div className="col-select col-select w-100 px-1">
        <UrlSort options={locationsStore.locationsSortBy} value={qp.sortBy} change={change} placeholder={'Sort by'}/>
      </div>
      <div className="col-btn pl-1">
        <Button className="btn btn-red" onClick={() => {}}><Link to="/add-new-location">Add new</Link></Button>
      </div>
    </div>
  );
};

export default observer(BrowseAllLocationsHeader);
