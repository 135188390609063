import React from 'react';
import {useStores} from './hooks/use-stores';
import {observer} from 'mobx-react-lite';
import {Switch, Redirect} from 'react-router-dom';
import AuthorizedLayout from './layouts/AuthorizedLayout';
import UnAuthorizedLayout from './layouts/UnAuthorizedLayout';
import './assets/scss/main.scss'
import DialogContainer from './components/containers/dialog/DialogContainer';
import ShowToast from './components/common/toast/Toast';


const App = () => {
  const {authStore} = useStores();

  return (
    <>
      <Switch>
        {authStore.isAuth ? <AuthorizedLayout/> : <UnAuthorizedLayout/>}
        <Redirect to="/"/>
      </Switch>
      <ShowToast />
      <DialogContainer />
    </>
  );
};

export default observer(App);
