import {action, computed, observable, runInAction} from 'mobx';
import api from '../../utils/api';


class UsersStore {
  @observable fetching = true;

  @observable locationOwners = {
    data: [],
    total: 0,
    per_page: 1
  };

  @observable userEmails = [];

  @observable userLocation = [];

  @computed get ownersPageCount() {
    return Math.ceil(this.locationOwners.total / this.locationOwners.per_page);
  };

  @computed get locationOwnersEmails() {
    return this.userEmails.map(i => ({value: i.email, label: i.email}));
  };

  @action setFetching = () => {
    this.fetching = true;
  };

  @action fetchUserEmails = async () => {
    this.fetching = true;
    try {
      const resp = await api.get('/dashboard/users/all');
      runInAction(() => this.userEmails = resp.data.data);
    } catch(e)  {
      throw e;
    } finally {
      runInAction(() => {
        this.fetching = false;
      });
    }
  };

  @action fetchLocationOwners = async (values = {}, forDash) => {
    // this.fetching = true;
    const data = {
      params: {
        search: values.search || '',
        filter: values.filter || '',
        sort_by: values.sortBy || '',
        per_page: forDash ? '15' :  values.per_page || '',
        page: values.page || ''
      }
    };
    try {
      const resp = await api.get('/dashboard/users', data);
      runInAction(() => this.locationOwners = resp.data.data);
    } catch(e)  {
      throw e;
    } finally {
      runInAction(() => {
        this.fetching = false;
      });
    }
  };

  @action removeLocationOwner = async (id) => {
    this.fetching = true;
    try {
      await api.delete(`/dashboard/users/${id}`);
    } catch (e) {
      throw e;
    } finally {
      runInAction(() => {
        this.fetching = false;
      });
    }
  };

  @action fetchUserLocations = async (id) => {
    // this.fetching = true;
    try {
      const resp = await api.get(`/dashboard/users/${id}/locations`);
      runInAction(() => this.userLocation = resp.data.data);
    } catch(e)  {
      throw e;
    } finally {
      runInAction(() => {
        this.fetching = false;
      });
    }
  };

  @action updateLocationOwner = async (values, id) => {
    this.fetching = true;
    const data = {
      first_name: values.first_name,
      last_name: values.last_name,
      email: values.email,
      phone: values.phone,
    };
    try {
      await api.patch(`/dashboard/users/${id}`, data);
    } catch (err) {
      throw err;
    }finally {
      runInAction(() => {
        this.fetching = false;
      });
    }
  };


  @action resetPassword = async (obj) => {
    this.fetching = true;
    obj.disabled = true;
    try {
      await api.post(`/dashboard/users/${obj.user_id ? obj.user_id : obj.id}/reset_password`);
    } catch (err) {
      throw err;
    } finally {
      runInAction(() => {
        obj.disabled = false;
        this.fetching = false;
      });
    }
  };
}

const usersStore = new UsersStore();
export default usersStore;
