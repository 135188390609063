import {useLocation} from 'react-router-dom';
import {replace} from '../utils/history';

export const useQueryParams = () => {
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  let queryParams = {};
  urlParams.forEach((val, key) => queryParams[key] = val);
  const setQueryParams = (data) => {
    const newParams = new URLSearchParams(window.location.search);
    Object.keys(data).forEach(key => newParams.set(key, data[key]));
    replace(`${location.pathname}?${newParams.toString()}`);
  };
  return [queryParams, setQueryParams];
};
