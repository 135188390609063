import React from 'react';
import {observer} from 'mobx-react-lite';
import {Col, FormGroup, Row} from 'reactstrap';
import FormInput from '../../../components/common/forms/FormInput';
import FormSelect from '../../../components/common/forms/FormSelect';

const VideosForm = (props) => {
  const video_type =  [
    {
      id: 1,
      label: 'YouTube',
      value: 1
    },
    {
      id: 2,
      label: 'Vimeo',
      value: 2
    },
    {
      id: 3,
      label: 'Matterport Virtual tour',
      value: 3
    }
  ];

  return(
    <div>
      <div className="location-item description-holder bg-white p-3 mb-3">
        <div className="form-title mb-3 font-weight-bold">Description</div>
        <Row>
          <Col xs={12}>
            {/* <div className="form-input-holder w-100 mb-2"> */}
            <FormGroup className="mb-1">
              <FormInput
                type="textarea"
                name="description"
                id="description"
                value={props.values.description}
                // onChange={(e) => {
                //   handleChange(e)
                // }}
                onBlur={props.handleBlur}
                placeholder="Tell us about your location and its special features..."
                touched={props.touched.description}
                error={props.errors.description}
                maxLength={1200}
              />
            </FormGroup>
            {/* </div> */}
            <span className="description-note d-block text-right">{1200 - props.values.description.length} characters remaining</span>
          </Col>
        </Row>
      </div>

      <div className="location-item p-3 mb-3 bg-white">
        <div className="form-title mb-3 font-weight-bold">Videos & virtual tours</div>
        <span className="location-item-text d-block mb-3">
          Include a Youtube/Vimeo video show reel, video walk around or a Matterport 3D virtual tour of the inside of
          your property.
        </span>
        <Row>
          <Col md={6}>
            <FormGroup>
              <FormSelect
                className="form-select-container--light"
                name="video_type"
                id="video_type"
                type="text"
                value={props.values.video_type}
                error={props.errors.video_type}
                onChange={props.setFieldValue}
                onlyValue={true}
                onBlur={props.setFieldTouched}
                placeholder="Please select media type"
                touched={props.touched.video_type}
                options={video_type}
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <div className="form-input-holder w-100 mb-2">
              <FormInput
                type="text"
                name="video_url"
                id="video_url"
                value={props.values.video_url}
                onBlur={props.handleBlur}
                touched={props.touched.video_url}
                // onChange={(e) => {
                //   handleChange(e)
                // }}
                placeholder="URL"
                errMessage={props.errors.video_url}
              />
              </div>
            </FormGroup>
          </Col>
        </Row>
      </div>
    </div>
  )
};

export default observer(VideosForm);