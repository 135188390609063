import React, {useEffect} from 'react';
import {observer} from 'mobx-react-lite';
import {useStores} from '../../hooks/use-stores';
import { Spinner, Alert } from 'reactstrap';
import {useQueryParams} from '../../hooks/use-query-params';
import AdminFooter from './AdminFooter';
import AdminTable from './AdminTable';
import AdminHeader from './AdminHeader';
import './admin.scss';


const AdminStaff = () => {
  const {adminStore} = useStores();
  const [qp] = useQueryParams();

  useEffect(() => {
    adminStore.fetchAdminStaff(qp);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [qp.page, qp.search, qp.sortBy, qp.filter]);

  return (
    <div className="main-holder admin-staff">
      <div className="page-title mb-3 font-weight-bold">Browse all Admin Staff</div>
      {adminStore.fetching ? <Spinner color="gray" /> :
        <>
        <AdminHeader/>
        {adminStore.adminStaff.data.length 
        ? 
          <>
            <AdminTable/>
            <AdminFooter/>
          </>
        : <Alert color="light">
            No results!
          </Alert>
        }
        </>
      }
    </div>
  );
};

export default observer(AdminStaff);
