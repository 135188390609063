import React from 'react';
import {Toast, ToastBody, ToastHeader} from 'reactstrap';
import {useStores} from '../../../hooks/use-stores';
import {observer} from 'mobx-react-lite';

const ShowToast = () => {
  const {uiStore} = useStores();

  const toggle = (id) => () => {
    uiStore.deleteToast(id);
  };

  return (
    <div className={`toast-container`}>
      {uiStore.toastsList.map((toast) => (
        <Toast className={`toast-status ${toast.status}`}  key={toast.id}>
          <ToastHeader toggle={toggle(toast.id)} icon={toast.status}>
            {toast.title}
          </ToastHeader>
          <ToastBody>
            {toast.body}
          </ToastBody>
        </Toast>
      ))}
    </div>
  );
};

export default observer(ShowToast);


