import React from 'react';
import {useStores} from '../hooks/use-stores';
import {observer} from 'mobx-react-lite';
import { Formik, Form } from 'formik';
import {Spinner} from 'reactstrap';
import FormInput from '../components/common/forms/FormInput';
import * as Yup from 'yup';

const loginSchema = () =>
  Yup.object().shape({
    email: Yup.string().email('Email is invalid').required('Email is required'),
    password: Yup.string()
      .min(6, 'Password must be at least 6 characters')
      .required('Password is required')
  });

const Login = () => {
  const {authStore, uiStore} = useStores();

  const login = async (values) => {
    try {
      await authStore.login(values);
      uiStore.createToast({status: 'success', title: 'Login', body: 'Login was successful' });
    }catch(e) {
      uiStore.createToast({status: 'danger', title: 'Login', body: 'Email or/and password is incorrect' });
    }
  };

  return (
    <div className="Login m-auto w-100">
      <header className="App-header">
        <div className="logo bg-transparent mb-4 p-2">
            <span className="navbar-brand">kays location logo</span>
        </div>

        <Formik
          initialValues={{ email: '', password: ''}}
          validationSchema={loginSchema}
          onSubmit={login}

        >{({errors, touched, isSubmitting, submitForm, handleBlur, ...props}) => (
            <Form className="form-login d-flex flex-column">
              <FormInput
                type="text"
                name="email"
                touched={touched.email}
                onBlur={handleBlur}
                placeholder='Login'
                error={errors.email}
                value={props.values.email}
              />
              <FormInput
                type="password"
                name="password"
                placeholder='Password'
                onBlur={handleBlur}
                touched={touched.password}
                error={errors.password}
                value={props.values.password}
              />
              <button className="btn-red btn position-relative" onClick={submitForm} type="button">
                {isSubmitting ? <Spinner color="white"/> : 'Login'}
              </button>
            </Form>
          )}
        </Formik>
      </header>
    </div>
  );
};

export default observer(Login);
