import {action, computed, observable, runInAction} from 'mobx';
import api from '../../utils/api';


class IndustryStore {
  @observable fetching = true;

  @observable industryFilters = [];

  @observable industryProfessionals = {
    data: [],
    total: 0,
    per_page: 1
  };

  @computed get industryPageCount() {
    return Math.ceil(this.industryProfessionals.total / this.industryProfessionals.per_page)
  };

  @action setFetching = () => {
    this.fetching = true;
  };

  @action fetchIndustryProfessionals = async (values, forDash) => {
    this.fetching = true;
    const data = {
      params: {
        search: values.search || '',
        filter: values.filter || '',
        sort_by: values.sortBy || '',
        page: values.page || '',
        per_page: forDash ? '15' : values.per_page || ''
      }
    };
    try {
      const resp = await api.get('/dashboard/users/industrial', data);
      runInAction(() => this.industryProfessionals = resp.data.data);
      runInAction(() => this.industryFilters = resp.data.filters);
    } catch(err)  {
      throw err;
    } finally {
      runInAction(() => {
        this.fetching = false;
      });
    }
  };

  @action addIndustryUser = async (values) => {
    this.fetching = true;
    const data = {
      first_name: values.first_name,
      last_name: values.last_name,
      email: values.email,
      phone: values.phone,
      postcode: values.postCode,
      address1: values.addressLine1,
      address2: values.addressLine2,
      city: values.city,
      county: values.county,
      building: values.building,
      job: values.job,
      send_email: values.send_email,
      longitude: values.longitude,
      latitude: values.latitude
    };
    try {
      await api.post('/dashboard/users/industrial/add', data);
    } catch (e) {
      throw e;
    }finally {
      runInAction(() => {
        this.fetching = false;
      });
    }
  };

  @action updateIndustryUser = async (values, id) => {
    this.fetching = true;
    const data = {
      first_name: values.first_name,
      last_name: values.last_name,
      email: values.email,
      phone: values.phone,
      postcode: values.postCode,
      address1: values.addressLine1,
      address2: values.addressLine2,
      city: values.city,
      county: values.county,
      building: values.building,
      job: values.job,
      longitude: values.longitude,
      latitude: values.latitude
      // send_email: values.send_email
    };
    try {
      await api.patch(`/dashboard/users/${id}`, data);
    } catch (err) {
      throw err;
    }finally {
      runInAction(() => {
        this.fetching = false;
      });
    }
  };

  @action removeIndustryUser = async (id) => {
    this.fetching = true;
    try {
      await api.delete(`/dashboard/users/${id}`);
    } catch (e) {
      throw e;
    } finally {
      runInAction(() => {
        this.fetching = false;
      });
    }
  };
}

const industryStore = new IndustryStore();
export default industryStore;
