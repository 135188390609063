import React from 'react';
import {observer} from 'mobx-react-lite';
import UserImage from '../../../assets/images/user-image.jpg';
import './header.scss';
import {useStores} from '../../../hooks/use-stores';
import ProfileDropDown from './ProfileDropDown';
// import HeaderBadge from './HeaderBadge';

const Header = () => {
  const {uiStore, authStore} = useStores();

  const user = authStore.currentUser;

  const open = () => {
    uiStore.setShowSidebar();
  };

  return (
    <div className="header bg-white d-flex justify-content-between align-items-center">
      <button className="open-close border-0 bg-transparent" onClick={open}>{uiStore.showSidebar ? '' : ''}</button>
      <div className="d-flex align-items-center">
        {/*<HeaderBadge/>*/}
        <ProfileDropDown/>
        <div className="user-photo ml-4 rounded-circle">
          <img src={user.photo || UserImage} alt="" />
        </div>
      </div>
    </div>
  );
};

export default observer(Header);
