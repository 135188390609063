import React, {useEffect} from 'react';
import {observer} from 'mobx-react-lite';
import {Button, Col, FormGroup, Row} from 'reactstrap';
import {Form, Formik} from 'formik';

import * as Yup from 'yup';
import FormSelect from '../../../components/common/forms/FormSelect';
import FormFileInput from '../../../components/common/forms/FormFileInput';
import {useStores} from '../../../hooks/use-stores';
import {useQueryParams} from '../../../hooks/use-query-params';
import FormInputSelect from '../../../components/common/forms/FormInputSelect';

const documentSchema = () =>
  Yup.object().shape({
    location: Yup.string().required('Location is required'),
    category: Yup.string().required('Category is required'),
    file: Yup.mixed()
      .required('File is required')
      .test('fileSize', 'File Size is too large', (value = {}) => value.size <= 3000000)
      // .test('fileType', 'File Type is invalid', (value = {}) => {
      //   console.log(value.type);
      //   return /\/(pdf|docx?)$/.test(value.type)
      // })
  });


const DocumentForm = () => {
  const {documentsStore, uiStore, dialogStore} = useStores();
  const [qp] = useQueryParams();

  useEffect(() => {
    documentsStore.fetchDocumentsCategory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = async (values) => {
    try {
      await documentsStore.addNewDocument(values);
      uiStore.createToast({status: 'success', title: 'Add new document', body: 'New document was added' });
      dialogStore.closeDialog('DocumentForm');
      await documentsStore.fetchDocuments(qp);
    }catch(e)  {
      uiStore.createToast({status: 'danger', title: 'Add new document', body: `Error, ${uiStore.errorHandler(e)}` || 'Error, please try again' });
    }
  };

  return (
    <div className="border-top pt-4">
      <Formik
        initialValues={{
          location: '',
          category: '',
          file: ''
        }}
        enableReinitialize
        validationSchema={documentSchema}
        onSubmit={onSubmit}
        >{({errors, submitForm, values, setFieldValue, touched}) => (
          <Form>
            <Row>
              <Col sm="12">
                <FormGroup>
                  <FormSelect
                    className="form-select-container--light"
                    name="location"
                    value={values.location}
                    onlyValue={true}
                    onChange={setFieldValue}
                    placeholder="Select document location"
                    touched={touched.location}
                    options={documentsStore.typeLocations}
                    error={errors.location}
                  />
                </FormGroup>
                <FormGroup>
                  <FormInputSelect
                    className="form-select-container--light"
                    name="category"
                    value={values.category}
                    onChange={setFieldValue}
                    placeholder="Select or create a new category"
                    touched={touched.category}
                    options={documentsStore.documentsCategory}
                    error={errors.category}
                    label={true}
                  />
                </FormGroup>
                <FormGroup>
                  <FormFileInput
                    name="file"
                    value={values.file}
                    onChange={setFieldValue}
                    error={errors.file}
                    buttonText="Choose File"
                    labelText="PDF or Word Document Max file size 3mb"
                    accept=".doc, .docx, .pdf, application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, text/plain, application/pdf"
                  />
                </FormGroup>
              </Col>
            </Row>
            <Button className="btn-red" onClick={submitForm}>Add</Button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default observer(DocumentForm);