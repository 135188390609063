import React, {useEffect} from 'react';
import {observer} from 'mobx-react-lite';
import {Col, FormGroup, Row} from 'reactstrap';
import FormInput from '../../../components/common/forms/FormInput';
import FormRadio from '../../../components/common/forms/FormRadio';
import FormSelect from '../../../components/common/forms/FormSelect';
import {useStores} from '../../../hooks/use-stores';


const NewUserForm = (props) => {
  const {usersStore} = useStores();

  const handleChangeRadioButton = (value) => {
    props.setFieldValue('send_email', +value.target.value);
  };

  useEffect(() => {
    if (!props.values.newEmail.length) {
      props.setFieldValue('send_email', '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.values.newEmail]);

  return (
    <div className="location-item bg-white p-3 mb-3">
      <div className="form-title mb-3 font-weight-bold">User account</div>
      <Row>
        <Col sm="6" className="position-sm-relative pr-sm-4">
          <FormGroup className="mb-0">
            <div className="form-title form-title-sub mb-3 font-weight-bold">Create a new user account</div>
            <div className="form-input-holder w-100 mb-2">
              <FormInput
                type="email"
                name="newEmail"
                placeholder="New account email address"
                disabled={props.values.currentEmail.length}
                error={props.errors.newEmail}
                touched={props.touched.newEmail}
                onBlur={props.handleBlur}
                value={props.values.newEmail}
                floating={false}
              />
            </div>
            <FormRadio
              onChange={handleChangeRadioButton}
              name="send_email"
              value={1}
              checked={props.values.send_email === 1}
              label={<span>User <b>will</b> be sent an email to create a new password</span>}
            />
            <FormRadio
              onChange={handleChangeRadioButton}
              name="send_email"
              value={0}
              checked={props.values.send_email === 0}
              label={<span>Auto generate a password and <b>do not</b> send user an email</span>}
            />
          </FormGroup>
          <div className="divider position-absolute"></div>
        </Col>
        <div></div>
        <Col sm="6" className="pl-sm-4">
          <div className="form-title form-title-sub mb-3 font-weight-bold">Or link to existing user account</div>
          <div className="form-input-holder w-100 position-relative">
            <FormSelect
              className="form-select-container--light"
              type="text"
              name="currentEmail"
              label={false}
              placeholder="Search existing user account"
              error={props.errors.currentEmail}
              disabled={props.values.newEmail.length}
              value={props.values.currentEmail}
              onChange={props.setFieldValue}
              onBlur={props.setFieldTouched}
              touched={props.touched.currentEmail}
              onlyValue={true}
              options={usersStore.locationOwnersEmails}
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default observer(NewUserForm);