import React from 'react';
import {Row, Col} from 'reactstrap';
import {observer} from 'mobx-react-lite';
import {useStores} from '../../../hooks/use-stores';
import {useQueryParams} from '../../../hooks/use-query-params';

const DocumentRemove = ({document}) => {
  const {dialogStore, documentsStore, uiStore} = useStores();
  const [qp] = useQueryParams();

  const remove = async () => {
    try {
      await documentsStore.deleteDocument(document.id);
      uiStore.createToast({status: 'success', title: 'Document removed', body: 'Document was removed' });
      dialogStore.closeDialog('DocumentRemove');
      await documentsStore.fetchDocuments(qp);
    }catch(e)  {
      uiStore.createToast({status: 'danger', title: 'Document removed', body: `Error, ${uiStore.errorHandler(e)}` || 'Error, please try again' });
    }
  };

  const close = () => {
    dialogStore.closeDialog('DocumentRemove');
  };

  return (
    <div className="border-top pt-4">
      <Row className="mb-4">
        <Col>
          <span className="mr-2">Are you sure to remove document:</span>
          <span>{document.name}</span>
        </Col>
      </Row>
      <Row>
        <Col md={12} className="col-md-12 d-flex justify-content-end">
          <button className="btn btn-outline mr-4" onClick={remove}>REMOVE</button>
          <button className="btn btn-red" onClick={close}>CANCEL</button>
        </Col>
      </Row>
    </div>
  )
};

export default observer(DocumentRemove);