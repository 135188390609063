import React from 'react';
import {observer} from 'mobx-react-lite';
import StatsCard from '../../../../components/common/cards/StatsCard';
import moment from 'moment';
import {useStores} from '../../../../hooks/use-stores';

const StatsInfoRow = () => {
  const {dashboardStore} = useStores();

  const date = moment().format('MMM Do YYYY');
  const stats = dashboardStore.stats;

  return (
      <div className="stats-cards-row d-flex justify-content-between">
        {/*<div className="stats-cards-col d-flex flex-wrap justify-content-between">*/}
        {/*  <StatsCard */}
        {/*    color="red" */}
        {/*    title="Industry Professionals" */}
        {/*    description="To be approved"*/}
        {/*    link="/industry-professionals/"*/}
        {/*    icon="icon-add-user"*/}
        {/*  />*/}
        {/*  <StatsCard*/}
        {/*    color="red" */}
        {/*    title="New Locations" */}
        {/*    description="To be approved"*/}
        {/*    link="/browse-locations/"*/}
        {/*    icon="icon-maps-and-location"*/}
        {/*  />*/}
        {/*</div>*/}
        <div className="stats-cards-col d-flex flex-wrap justify-content-between">
          <StatsCard 
            color="blue" 
            title="Locations" 
            description={date}
            link="/browse-locations/"
            icon="icon-line-chart"
            count={stats && stats.total_locations}
          />
          <StatsCard 
            color="blue" 
            title="Ind Professionals" 
            description={date}
            link="/industry-professionals/"
            icon="icon-line-chart"
            count={stats && stats.total_industrial}
          />
        </div>
      </div>
  );
};

export default observer(StatsInfoRow);
