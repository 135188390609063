import React, {useEffect} from 'react';
import {observer} from 'mobx-react-lite';
import {useStores} from '../../hooks/use-stores';
import { Spinner } from 'reactstrap';
import AddNewLocationForm from './AddNewLocationForm/AddNewLocationForm';
import './location.scss';


const AddNewLocation = () => {
  const {locationsStore, usersStore} = useStores();

  useEffect(() => {
    locationsStore.clearEditingLocation();
    locationsStore.fetchLocationParams();
    usersStore.fetchUserEmails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  return (
    <div className="main-holder add-new-location">
      <div className="page-title mb-3 font-weight-bold">Add a new location</div>
      {locationsStore.fetching ? <Spinner color="gray" /> :
        <AddNewLocationForm />
      }
    </div>
  );
};

export default observer(AddNewLocation);
