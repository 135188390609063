import generalConstants from './constants';

let isGoogleInit = false;

export const initMap = () => {
  return new Promise((resolve, reject) => {
    if(window.google ) return resolve();
    if(isGoogleInit) return;
    isGoogleInit = true;
    const scriptTag = document.createElement('script');
    scriptTag.type = 'text/javascript';
    scriptTag.id = 'location-autocomplete-library';
    scriptTag.src = `https://maps.googleapis.com/maps/api/js?key=${generalConstants.getGoogleApiKey}&libraries=places&region=uk`;
    (document.head || document.body).appendChild(scriptTag);
    scriptTag.addEventListener('load', resolve);
    scriptTag.addEventListener('error', reject)
  });
};