import React from 'react';
import {Row, Col} from 'reactstrap';
import {observer} from 'mobx-react-lite';
import {useStores} from '../../../hooks/use-stores';
import {useQueryParams} from '../../../hooks/use-query-params';

const AdminRemove = ({admin}) => {
  const {dialogStore, adminStore, uiStore} = useStores();
  const [qp] = useQueryParams();

  const remove = async () => {
    try {
      await adminStore.removeAdmin(admin.id);
      uiStore.createToast({status: 'success', title: 'Admin removed', body: 'Admin account was removed' });
      dialogStore.closeDialog('AdminRemove');
      await adminStore.fetchAdminStaff(qp);
    }catch(e)  {
      uiStore.createToast({status: 'danger', title: 'Admin removed', body: `Error, ${uiStore.errorHandler(e)}` || 'Error, please try again' });
    }
  };

  const close = () => {
    dialogStore.closeDialog('AdminRemove');
  };

  return (
    <div className="border-top pt-4">
      <Row className="mb-4">
        <Col>
          <span className="mr-2">Are you sure to remove admin:</span>
          <span>{admin.first_name} {admin.last_name}</span>
        </Col>
      </Row>
      <Row>
        <Col md={12} className="col-md-12 d-flex justify-content-end"> 
          <button className="btn btn-outline mr-4" onClick={remove}>REMOVE</button>
          <button className="btn btn-red" onClick={close}>CANCEL</button>
        </Col>
      </Row>
    </div>
  )
};

export default observer(AdminRemove);