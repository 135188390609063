import React from 'react';
import {observer} from 'mobx-react-lite';
import {Col, FormGroup, Row} from 'reactstrap';
import FormInput from '../../../components/common/forms/FormInput';
import FormGetAddress from '../../../components/common/forms/FormGetAddress';
import FormCheckbox from '../../../components/common/forms/FormCheckbox';


const LocationContactForm = (props) => {
  const handleChangeCheckbox = (value) => {
    props.setFieldValue(value.target.name, value.target.checked);
  };

  return (
    <div className="location-item bg-white p-3 mb-3">
      <div className="form-title mb-3 font-weight-bold">Location contact details</div>
      <Row className="location-row-holder">
        <Col xs={12}>
          <FormGroup>
            <div className="form-input-holder form-input-holder--lg w-100 mb-2">
              <FormInput
                type="text"
                name="name"
                error={props.errors.name}
                value={props.values.name}
                onBlur={props.handleBlur}
                placeholder={'Name of location or descriptive strap-line'}
                touched={props.touched.name}
              />
            </div>
          </FormGroup>
        </Col>
      </Row>
      {/* <div> */}
      <FormGetAddress
        setValues={props.setValues}
        setFieldValue={props.setFieldValue}
        values={props.values}
        errors={props.errors}
        touched={props.touched}
      />
      {/* </div> */}
      <Row className="location-row-holder">
        <Col md={6}>
          <FormGroup>
            <div className="form-input-holder w-100 mb-2">
              <FormInput
                type="text"
                name="contact_name"
                value={props.values.contact_name}
                // onChange={handleChange}
                onBlur={props.handleBlur}
                touched={props.touched.contact_name}
                placeholder="Location contact name"
                error={props.errors.contact_name}
              />
            </div>
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <div className="form-input-holder w-100 mb-2">
              <FormInput
                type="text"
                name="email_address"
                value={props.values.email_address}
                // onChange={handleChange}
                onBlur={props.handleBlur}
                touched={props.touched.email_address}
                placeholder="Email address for enquires"
                error={props.errors.email_address}
              />
            </div>
          </FormGroup>
        </Col>
      </Row>
      <Row className="location-row-holder">
        <Col md={6}>
          <FormGroup>
            <div className="form-input-holder w-100 mb-2">
              <FormInput
                type="text"
                name="phone"
                value={props.values.phone}
                touched={props.touched.phone}
                onBlur={props.handleBlur}
                // onChange={props.handleChange}
                placeholder="Primary phone"
                error={props.errors.phone}
              />
            </div>
          </FormGroup>
        </Col>
        <Col md={6}>
          <div
            className={`custom-checkbox custom-control detail-checkbox pl-0 ${props.values.phone_professionals_only ? 'checked' : ''}`}>
            <FormGroup className="mb-0">
              <FormCheckbox
                label={<span>Telephone visible to industry professionals only</span>}
                type="checkbox"
                id="phone_professionals_only"
                name="phone_professionals_only"
                onChange={handleChangeCheckbox}
                checked={props.values.phone_professionals_only}
                className={'custom-control-input'}
              />
            </FormGroup>
          </div>
        </Col>
      </Row>

      <Row className="location-row-holder">
        <Col md={6}>
          <FormGroup>
            <div className="form-input-holder w-100 mb-2">
              <FormInput
                type="text"
                name="phone_2"
                value={props.values.phone_2}
                // onChange={props.handleChange}
                touched={props.touched.phone}
                onBlur={props.handleBlur}
                placeholder="Secondary phone"
                error={props.errors.phone_2}
              />
            </div>
          </FormGroup>
        </Col>
        <Col md={6}>
          <div
            className={`custom-checkbox custom-control detail-checkbox pl-0 ${props.values.phone_2_professionals_only ? 'checked' : ''}`}>
            <FormGroup className="mb-0">
              <FormCheckbox
                label={<span>Telephone visible to industry professionals only</span>}
                type="checkbox"
                id="phone_2_professionals_only"
                name="phone_2_professionals_only"
                onChange={handleChangeCheckbox}
                checked={props.values.phone_2_professionals_only}
                className={'custom-control-input'}
              />
            </FormGroup>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default observer(LocationContactForm);