import React from 'react';
import {observer} from 'mobx-react-lite';
import {useStores} from '../../hooks/use-stores';
import DataTable from '../../components/common/table/Table';
import {useTableParams} from '../../hooks/useTableParams';
import DocumentRemove from './NewDocument/DocumentRemove';

const head = [
  {disableOrder: true, id: 'name', label: 'Document Name'},
  {disableOrder: true, id: 'jobTitle', label: 'Type'},
  {disableOrder: true, id: 'email', label: 'Document Category'},
  {disableOrder: true, id: 'telephone', label: 'Location'},
  {disableOrder: true, id: 'actions', label: 'Actions'}
];

const DocumentsTable = () => {
  const {documentsStore, dialogStore} = useStores();
  const tableParams = useTableParams();

  const getLocation = (location) => {
    return documentsStore.typeLocations.find(i => i.value === location).label || ''
  };

  const download = (name) => () => {
    documentsStore.downloadDocument(name);
  };

  const removeDocument = (document) => () => {
    dialogStore.openDialog(<DocumentRemove document={document} />, 'DocumentRemove', {title: `Remove ${document.name || ''}`.trim(), className: 'modal-confirm'})
  };

  return (
    <DataTable
      params={tableParams}
      head={head}
      data={documentsStore.documents.data}
    >{
      (row, i) => (<tr key={i}>
        <td className="text-nowrap border-top-0 p-1 align-middle">{row.name}</td>
        <td className="text-nowrap border-top-0 p-1 align-middle">{row.ext}</td>
        <td className="text-nowrap border-top-0 p-1 align-middle">{row.category}</td>
        <td className="text-nowrap border-top-0 p-1 align-middle">{getLocation(row.type_location)}</td>
        <td className="text-nowrap border-top-0 p-1 align-middle">
          <button
            className="btn-table icon icon-download mr-2 pl-0 border-0 font-weight-bold bg-transparent text-uppercase"
            type="button" onClick={download(row.name)}>
            <span>Download</span>
          </button>
          <button
            className="btn-table icon icon-trash-alt btn-del mr-2 border-0 font-weight-bold bg-transparent text-uppercase"
            type="button"
            onClick={removeDocument(row)}>
            <span>Delete</span>
          </button>
        </td>
      </tr>)
    }</DataTable>
  );
};

export default observer(DocumentsTable);
