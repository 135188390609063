import {action, computed, observable, runInAction} from 'mobx';
import api from '../../utils/api';
import uiStore from '../uiStore';


class LocationsStore {
  @observable fetching = true;
  @observable fetchingImg = false;
  @observable locationParams = [];
  @observable styleParams = [];
  @observable categoriesParams = [];
  @observable typesParams = [];
  @observable locationsFilters = [];
  @observable locationsSortBy = [];
  @observable locations = {
    data: [],
    total: 0,
    per_page: 1
  };
  @observable editedLocation = {};
  @observable newLocation = {
    image_featured_urls: [],
    image_urls: [],
    pdf_urls: []
  };

  @computed get locationParamsExtended() {
    return Object.keys(this.locationParams).reduce((result, key)  => {
      if (key === 'additional_info') return result;
      return {
        ...result,
        [key]: {
          ...this.locationParams[key],
          items: [
            {id: 0, name: 'Unknown' },
            ...this.locationParams[key].items
          ]
        }
      }
    }, {})
  }

  @computed get locationsPageCount() {
    return Math.ceil(this.locations.total / this.locations.per_page);
  };

  @action setFetching = () => {
    this.fetching = true;
  };

  @action clearEditingLocation = () => {
    this.editedLocation = {};
  };

  // @action clearContent = () => {
  //   this.newLocation = {
  //     image_featured_urls: [],
  //     image_urls: [],
  //     pdf_urls: []
  //   }
  // };

  @action addNewLocation = async (values) => {
    // this.fetching = true;
    const data = {
      web_url: values.web_url,
      user_email: values.user_email,
      published: +values.published,
      active: +values.active,
      send_email: values.send_email,
      name: values.name,
      postcode: values.postCode,
      address_line1: values.addressLine1,
      address_line2: values.addressLine2,
      city: values.city,
      county: values.county,
      building: values.building,
      job: values.job,
      latitude: values.latitude,
      longitude: values.longitude,
      contact_name: values.contact_name,
      email_address: values.email_address,
      phone: values.phone,
      phone_2: values.phone_2,
      phone_professionals_only: values.phone_professionals_only,
      phone_2_professionals_only: values.phone_2_professionals_only,
      keyword_id: [...values.age_style, ...values.interior_style, ...values.specific_features],
      location_setting: values.location_setting,
      bedrooms: values.bedroomsNo,
      power_supply: values.power_supply,
      water_source: values.water_source,
      wifi: values.wifi,
      parking: values.parking,
      parking_details: values.parking_details,
      filming_availability: values.filming_availability,
      filming_details: values.filming_details,
      additional_info: values.additional_info,
      description: values.description,
      // video_type: values.video_type,
      video_url: values.video_url,
      location_type_id: values.location_type,
      category_id: values.location_category,
      featured: values.featured_location,
      image_featured_urls: this.newLocation.image_featured_urls.slice(0, 1),
      image_urls: this.newLocation.image_urls,
      pdf_urls: this.newLocation.pdf_urls,
    };

    try {
      await api.post('/dashboard/locations', data);
      this.newLocation = {
        image_featured_urls: [],
        image_urls: [],
        pdf_urls: []
      }
    } catch (err) {
      throw err;
    } finally {
      runInAction(() => {
        this.fetching = false;
      });
    }
  };

  @action updateLocation = async (values) => {
    this.fetching = true;
    const {image_featured_urls, image_urls, pdf_urls} = this.editedLocation;
    const keyword_id = [...values.age_style, ...values.interior_style, ...values.specific_features].filter((x, i, a) => a.indexOf(x) === i);
    const resValues = {
      category_id: values.location_category,
      active: +values.active,
      published: +values.published,
      featured: values.featured_location,
      location_type_id: values.location_type,
      wifi: values.wifi,
      water_source: values.water_source,
      power_supply: values.power_supply,
      location_setting: values.location_setting,
      parking: values.parking,
      filming_availability: values.filming_availability,
      bedrooms: values.bedroomsNo,
      keyword_id: keyword_id,
      additional_info: values.additional_info,
      image_featured_urls: image_featured_urls,
      image_urls: image_urls,
      pdf_urls: pdf_urls,
      user_email: typeof values.user_email ==='string' ? values.user_email : values.user_email[0],
      name: values.name,
      postcode: values.postCode,
      address_line1: values.addressLine1,
      address_line2: values.addressLine2,
      city: values.city,
      county: values.county,
      building: values.building,
      job: values.job,
      latitude: values.latitude,
      longitude: values.longitude,
      contact_name: values.contact_name,
      email_address: values.email_address,
      phone: values.phone,
      phone_2: values.phone_2,
      phone_professionals_only: values.phone_professionals_only,
      phone_2_professionals_only: values.phone_2_professionals_only,
      parking_details: values.parking_details,
      filming_details: values.filming_details,
      description: values.description,
      video_url: values.video_url,
      web_url: values.web_url
    };
    try {
      await api.patch(`/dashboard/locations/${this.editedLocation.id}`, resValues);
    } catch (e) {
      throw e;
    } finally {
      runInAction(() => {
        this.fetching = false;
      });
    }
  };


  @action fetchLocationParams = async () => {
    this.fetching = true;
    try {
      const fetchedParamsRequest = api.get('/v1/locations/location_parameters');
      const fetchedTypesRequest = api.get('/v1/keywords/types_list');
      const fetchedCategoriesRequest = api.get('/v1/category/children_list');
      const fetchedLocationsTypesRequest = api.get('/v1/locations/location_types');
      const [fetchedParams, fetchedTypes, fetchedCategories, fetchedLocationsTypes] = await Promise.all([
        fetchedParamsRequest,
        fetchedTypesRequest,
        fetchedCategoriesRequest,
        fetchedLocationsTypesRequest
      ]);
      runInAction(() => {
        this.locationParams = fetchedParams.data;
        this.styleParams = fetchedTypes.data;
        this.typesParams = fetchedLocationsTypes.data;
        this.categoriesParams = fetchedCategories.data.reduce((result, {name, category_id, children = []}) => {
          result = [...result, ...children.map(o => ({
            value: o.id,
            label: o.name,
            category_id: o.category_id,
            parentCatName: name,
            parentCatId: category_id
          }))];
          return result;
        }, []);
        // this.categoriesParams.slice().sort((a, b) => {
        //   if (a.label.toLowerCase() < b.label.toLowerCase()) return -1;
        //   if (a.label.toLowerCase() > b.label.toLowerCase()) return 1;
        //   return 0;
        // });
      });
    } catch (err) {
      throw err;
    } finally {
      runInAction(() => {
        this.fetching = false;
      });
    }
  };


  @action fetchLocations = async (values) => {
    this.fetching = true;
    const data = {
      params: {
        search: values.search || '',
        filter: values.filter || '',
        sort_by: values.sortBy || '',
        page: values.page || '',
        type: values.type || 1,
        per_page: values.per_page || ''
      }
    };
    try {
      const resp = await api.get('/dashboard/locations', data);
      runInAction(() => this.locations = resp.data.data);
      runInAction(() => this.locationsFilters = resp.data.filters);
      runInAction(() => this.locationsSortBy = resp.data.sort_by);
    } catch(e)  {
      throw e;
    } finally {
      runInAction(() => {
        this.fetching = false;
      });
    }
  };

  @action fetchEditedLocation = async (id) => {
    this.fetching = true;
    try {
      const resp = await api.get(`/dashboard/locations/${id}`);
      runInAction(() => this.editedLocation = resp.data.data);
    } catch (e) {
      throw e;
    } finally {
      runInAction(() => {
        this.fetching = false;
      });
    }
  };

  @action uploadFile = async (files, name) => {
    this.fetchingImg = true;
    const promisedRes = files.map(async (f) => {
      const formData = new FormData();
      formData.append('file', f);
      try {
        const resp = await api.post('/dashboard/locations/upload', formData,
          {headers: {'content-type': `multipart/form-data`}}
        );
        runInAction(() => {
            !!this.editedLocation[name] ? this.editedLocation[name].push(resp.data.name) : this.newLocation[name].push(resp.data.name);
            this.fetchingImg = false;
          }
        );
        return true;
      } catch (e) {
        uiStore.createToast({status: 'danger', title: 'Error uploading the image', body: `Error, please try again` });
        runInAction(() => {
          this.fetchingImg = false;
        });
        return false;
      }
    });
    return Promise.all(promisedRes);
  };



  @action deleteLocation = async (id) => {
    this.fetching = true;
    try {
      await api.delete(`/dashboard/locations/${id}`);
    } catch (e) {
      throw e;
    } finally {
      runInAction(() => {
        this.fetching = false;
      });
    }
  };

  @action deletePhoto = async (name, type, id = null, idx) => {
    try {
      if (id) {
        // eslint-disable-next-line
        const fileUrl = name.match(/[^\/]+$/)[0];
        await api.delete(`/dashboard/locations/${id}/${type}/${fileUrl}`);
        await api.delete(`/dashboard/locations/file/${fileUrl}`);
        this.editedLocation[type].splice(idx, 1);
      } else {
        runInAction(() => this.newLocation[type].splice(idx, 1));
      }
    } catch (e) {
      throw e;
    }
  };

  @action printLocation = async (id) => {
    this.fetching = true;
    try {
      await api.get(`/dashboard/locations/${id}/print`,{
        responseType: 'blob'
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `location-${id}.pdf`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
    }catch (e) {
      throw e;
    }finally {
      runInAction(() => {
        this.fetching = false;
      });
    }
  }
}

const locationsStore = new LocationsStore();
export default locationsStore;
