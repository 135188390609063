import React, {useEffect, useState} from 'react';
import {observer} from 'mobx-react-lite';
import {Map, Marker} from 'google-maps-react';
import {initMap} from '../../utils/google-api-helper';
import InfoWindowEx from '../../components/containers/googleInfoWindow/InfoWindowEx';
import generalConstants from '../../utils/constants';
import {go} from '../../utils/history';


const BrowseAllLocationsMap = ({locations}) => {
  const [isGoogleMapsLoaded, trigger] = useState(false);
  // eslint-disable-next-line
  const [ref, setRef] = useState({});
  const [activeMarker, setActiveMarker] = useState({});
  const [showingInfoWindow, setShowingInfoWindow] = useState(false);
  const [selectedPlace, setSelectedPlace] = useState({});


  useEffect(() => {
    let isSubscribed = true;
    initMap().then(() => {
      if (isSubscribed) trigger(true);
    }).catch(e => {
      console.log(e)});
    return () => {
      isSubscribed = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const onMarkerClick = (props, marker) => {
    setSelectedPlace(props);
    setActiveMarker(marker);
    setShowingInfoWindow(true);
  };

  const onMapClick = (props) => {
    if (showingInfoWindow) {
      // eslint-disable-next-line no-unused-expressions
      setShowingInfoWindow(false);
      setActiveMarker(null);
    }
  };

  const editPage = (id) => () => {
    go(`/edit-location/${id}`)
  };

  return (
    <div className="map-view p-2 bg-white">
      <div className="map-view-inner position-relative">
      {isGoogleMapsLoaded &&
        <Map
          ref={setRef}
          google={window.google}
          zoom={6}
          initialCenter={{lat: 54.821970, lng: -5.418672}}
          onClick={onMapClick}
          fullscreenControl={false}
          mapTypeControl={false}
          streetViewControl={false}
        >
          {locations && locations.map((item, index) => {
            return <Marker
              onClick={onMarkerClick}
              key={index}
              id={item.id}
              job={item.job || ''}
              location={item}
              locationId={item.id}
              name={item.name || ''}
              description={`${item.address_line1 || ''} ${item.address_line2 || ''}`}
              position={{lat: +item.latitude, lng: +item.longitude}}
            />;
          })}
          <InfoWindowEx
            marker={activeMarker}
            visible={showingInfoWindow}
          >
            <div className="map-view-modal-inner">
              <div className="map-view-name">{selectedPlace.name}</div>
              <div className="map-view-job">{selectedPlace.description}</div>
              <a href={`${generalConstants.stagingUrl}location_page/${selectedPlace.locationId}`} target="_blank" rel="noopener noreferrer" className="btn-table icon icon-eye-open pl-0 mr-2 border-0 font-weight-bold bg-transparent"><span>VIEW</span></a>
              <button className="btn-table icon icon-edit mr-2 border-0 font-weight-bold bg-transparent text-uppercase" type='button' onClick={editPage(selectedPlace.locationId)}><span>Edit</span></button>
            </div>
          </InfoWindowEx>
        </Map>
      }
    </div>
    </div>
  );
};

export default observer(BrowseAllLocationsMap);