import React from 'react';
import {observer} from 'mobx-react-lite';
import FormSelect from '../../components/common/forms/FormSelect';

const UrlSort = ({change, value, options, placeholder}) => {
  const onChange = (data) => {
    change({sortBy: data.value, page: 1});
  };
  
  return (
    <FormSelect 
      value={value} 
      options={options} 
      onChange={onChange} 
      placeholder={placeholder}
      label={false}
    />
  );
};

export default observer(UrlSort);
