import React from 'react';
import {observer} from 'mobx-react-lite';
import './sidebar.scss';
import {useStores} from '../../../hooks/use-stores';
import Accordion from '../../common/collapse/Accordion';
import {Link, NavLink} from 'react-router-dom';


const Sidebar = () => {
  const {uiStore} = useStores();

  return (
    // uiStore.showSidebar ? 
    <div className={`sidebar ${uiStore.showSidebar ? 'sidebar--expanded' : 'sidebar--collapsed'}`}>
      <div className="logo">
        <div className="logo-inner">
          <Link className="navbar-brand w-100 p-0 d-block overflow-hidden" to="/">kays location logo</Link>
        </div>
      </div>
      <div className="sidebar-wrap">
        <NavLink exact className="link" to="/" activeClassName="active">
          <i className={`icon icon-tachometer-alt-slow`}/>
          <span className="txt">Dashboard</span>
        </NavLink>
        <Accordion isCollapsed={uiStore.showSidebar} label={'Locations'} icon={'map-marker-alt'}>
          <NavLink className="level1" to="/add-new-location" activeClassName="active">
            <span>Add new location</span>
          </NavLink>
          <NavLink className="level1" to="/browse-locations?page=1" activeClassName="active">
            <span>Browse all locations</span>
          </NavLink>
        </Accordion>
        <Accordion isCollapsed={uiStore.showSidebar} label={'Users'} icon={'users'}>
          <NavLink className="level1" to="/location-owners?page=1" activeClassName="active">
            <span>Location owners</span>
          </NavLink>
          <NavLink className="level1" to="/industry-professionals?page=1" activeClassName="active">
            <span>Industry professionals</span>
          </NavLink>
          <NavLink className="level1" to="/admin-staff?page=1" activeClassName="active">
            <span>Admin staff</span>
          </NavLink>
        </Accordion>
        {/*<Accordion label={'Approval'} icon={'user-check'}>*/}
        {/*  <Link className="level1" to="/">*/}
        {/*    <span>Location approval</span>*/}
        {/*  </Link>*/}
        {/*  <Link className="level1" to="/">*/}
        {/*    <span>User approval</span>*/}
        {/*  </Link>*/}
        {/*</Accordion>*/}
        {/*<Accordion label={'Lists'} icon={'list'}>*/}
        {/*  <Link className="level1" to="/">*/}
        {/*    <span>Categories</span>*/}
        {/*  </Link>*/}
        {/*  <Link className="level1" to="/">*/}
        {/*    <span>Keywords</span>*/}
        {/*  </Link>*/}
        {/*  <Link className="level1" to="/">*/}
        {/*    <span>Special features</span>*/}
        {/*  </Link>*/}
        {/*  <Link className="level1" to="/">*/}
        {/*    <span>Age and style</span>*/}
        {/*  </Link>*/}
        {/*  <Link className="level1" to="/">*/}
        {/*    <span>Interior styles</span>*/}
        {/*  </Link>*/}
        {/*</Accordion>*/}
        <NavLink className="link" to="/documents?page=1" activeClassName="active">
          <i className={`icon icon-book-open`}/>
          <span className="txt">Documents</span>
        </NavLink>
        {/*<Accordion isCollapsed={uiStore.showSidebar} label={'Data'} icon={'database'}>*/}
        {/*  <Link className="level1" to="/import-data">*/}
        {/*    <span>Import data</span>*/}
        {/*  </Link>*/}
        {/*  /!*<Link className="level1" to="/">*!/*/}
        {/*  /!*  <span>Export data</span>*!/*/}
        {/*  /!*</Link>*!/*/}
        {/*  /!*<Link className="level1" to="/">*!/*/}
        {/*  /!*  <span>Create reports</span>*!/*/}
        {/*  /!*</Link>*!/*/}
        {/*</Accordion>*/}
      </div>
    </div>
  );
};

export default observer(Sidebar);
