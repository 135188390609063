import React from 'react';

const FormFileInput = ({
  onChange,
  value,
  name,
  error,
  disabled,
  className = 'document-uploader',
  isMulti = false,
  buttonText,
  labelText,
  accept
}) => {
  const handleChange = (e) => {
    onChange(name, isMulti ? e.target.files : e.target.files[0]);
  };

  const clear = (e) => {
    e.preventDefault();
    onChange(name, '');
  };

  return (
    <>
      <label className={`${className} ${error ? 'error-input' : ''}`}>
        <input
          style={{display: 'none'}}
          type="file"
          name={name}
          onChange={handleChange}
          disabled={disabled}
          accept={accept}
        />
        {value ? 
            <div className="d-flex justify-content-start">
              <div className="d-flex align-items-center"><span className="file-upload mr-4">{value.name}</span></div>
              <button type="button" className="btn btn-outline btn-upload btn-upload--del"  onClick={clear}><span className="icon icon-times"></span></button>
            </div> 
          : 
            <div className="row d-flex justify-content-start">
              <div className="col-4">{buttonText && <span className="btn btn-outline btn-upload">{buttonText}</span>}</div>
              <div className="col-8 d-flex align-items-center">{labelText && <span className="description-upload">{labelText}</span>}</div>
            </div>
        }
      </label>
      {error && (
        <div className="error-message">{error}</div>
      )}
    </>
  )
};

export default FormFileInput;