import React from 'react';
import Select from 'react-select';
import {Label} from 'reactstrap';
import './form.scss';


const FormSelect = ({
  options: preOptions,
  onChange,
  value,
  onlyValue = false,
  isLoading,
  className = '',
  name,
  placeholder,
  maximum,
  isMulti,
  values,
  setValues,
  error,
  disabled,
  isDisabled,
  onBlur,
  touched,
  isClearable,
  label = true
}) => {
  const options = preOptions.map(o => {
    return {
      value: o.value || o.id,
      label: o.label || o.name
    }
  });

  const multiOptions = isMulti && values[name] && values[name].map((val) => {
    return options.find(o => (o.value === val))
  });

  const val = options.find(o => (typeof o.value === 'string' ? o.value.trim() : o.value) === (typeof value === 'string' ? value.trim() : value));

  const activeValue = isMulti ? multiOptions : val || null;

  const handleMultiChange = (evt) => {
    const nextValue = evt && evt.map(option => option.value);
    setValues(maximum && values[name] && values[name].length >= maximum + 1  ?  {...values, [name]: nextValue } : {...values, [name]: nextValue });
  };

  const singleChange = (data) => {
    const a = onlyValue ? [name, data.value] : [data];
    onChange(...a);
  };

  const handleBlur = () => {
    if (onBlur) {
      onBlur(name, true)
    }
  };

  const isMax = maximum && values[name] && values[name].length >= maximum ? 'maximum' : '';


  return (
    <>
      {label && placeholder && <Label htmlFor={name} className={`${label ? 'form-select-label' : ''}`}>{placeholder}</Label>}
      <Select
        className={`form-select-container ${className} ${isMax}`}
        classNamePrefix="form-select"
        value={activeValue}
        placeholder={placeholder}
        options={options}
        isLoading={isLoading}
        onChange={isMulti ? handleMultiChange : singleChange}
        isMulti={isMulti}
        isDisabled={disabled || isDisabled}
        onBlur={handleBlur}
        isClearable={isClearable}
      />
      {error && touched && (
        <div className="error-message">{error}</div>
      )}
    </>
  )
};

export default  FormSelect;