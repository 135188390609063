import React from 'react';
import {observer} from 'mobx-react-lite';
import FormSelect from '../../components/common/forms/FormSelect';

const UrlFilter = ({change, value, options, placeholder}) => {
  const onChange = (data) => {
    change({filter: typeof data.value === 'undefined' ? '' : data.value, page: 1});
  };
  return (
    <FormSelect 
      value={value} 
      options={options}
      onChange={onChange}
      placeholder={placeholder}
      label={false}
    />
  );
};

export default observer(UrlFilter);
