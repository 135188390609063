import React, {useCallback} from 'react';
import {observer} from 'mobx-react-lite';
import {useQueryParams} from '../../hooks/use-query-params';
import UrlPagination from '../../components/url-filters/UrlPagination';
import {useStores} from '../../hooks/use-stores';

const LocationFooter = () => {
  const [queryParams, setQueryParams] = useQueryParams();
  const {usersStore} = useStores();
  const handlePageClick = useCallback((data) => {
    setQueryParams(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="location-footer">
      <UrlPagination
        change={handlePageClick}
        pageCount={usersStore.ownersPageCount}
        page={queryParams.page}
      />
      <div className="pagination-show">Showing {usersStore.locationOwners.from}-{usersStore.locationOwners.to} of {usersStore.locationOwners.total}</div>
    </div>
  );
};

export default observer(LocationFooter);
