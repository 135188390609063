import React, {useEffect} from 'react';
import {useStores} from '../../../hooks/use-stores';
import {observer} from 'mobx-react-lite';
import {useTableParams} from '../../../hooks/useTableParams';
import DataTable from '../../../components/common/table/Table';
import {Link} from 'react-router-dom';
import generalConstants from '../../../utils/constants';

const head = [
  {disableOrder: true, id: 'id', label: 'ID No'},
  {disableOrder: true, id: 'name', label: 'Location name or address'},
  {disableOrder: true, id: 'actions', label: 'Actions'}
];

const PopoverTable = (props) => {
  const {usersStore, uiStore} = useStores();
  const tableParams = useTableParams();

  useEffect(() => {
    usersStore.fetchUserLocations(props.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const closePopover = () => {
    uiStore.clearPopovers();
    uiStore.deletePopover(props.popoverId);
  };

  return (
    <DataTable
      params={tableParams}
      head={head}
      data={usersStore.userLocation}
      striped={false}
    >{
      (row, i) => (<tr key={i}>
        <td className="text-nowrap border-top-0 p-1 align-middle">{row.id}</td>
        <td className="text-nowrap border-top-0 p-1 align-middle">{row.name}</td>
        <td className="text-nowrap border-top-0 p-1 align-middle">
          <a href={`${generalConstants.stagingUrl}location_page/${row.id}`} target="_blank" rel="noreferrer noopener" className="btn-table icon icon-eye-open pl-0 mr-4 border-0 font-weight-bold bg-transparent"><span>VIEW</span></a>
          <Link to={`/edit-location/${row.id}`} onClick={closePopover} className="btn-table icon icon-edit mr-2 border-0 font-weight-bold bg-transparent text-uppercase">
              <span>Edit</span>
          </Link>
        </td>
      </tr>)
    }</DataTable>

  );
};

export default observer(PopoverTable);