import React from 'react';
import {observer} from 'mobx-react-lite';
import { Button, Col, FormGroup, Row } from 'reactstrap';
import {Form, Formik} from 'formik';
import {useStores} from '../../../hooks/use-stores';
import FormInput from '../../../components/common/forms/FormInput';
import {useQueryParams} from '../../../hooks/use-query-params';

// const phoneHelper = Yup.lazy((val = '') => {
//   if (!val.startsWith('0')) {
//     return Yup.string().length(0, 'Should start with zero').required('Phone number is required')
//   } else if (val.startsWith('020')) {
//     return Yup.string().matches(/^020-\d{4}\s?\d{4}$/, 'Enter 020-XXXX XXXX || 0XXXX-XXX XXX')
//   } else if (val.match(/^\d{4}-/)) {
//     return Yup.string().matches(/^\d{4}-\d{3}\s?\d{4}$/, 'Enter 0XXX-XXX XXXX')
//   }
//   return Yup.string().matches(/^\d{5}-\d{3}\s?\d{3}$/, 'Enter 0XXXX-XXX XXX')
// });
//
//
// const industrySchema = (user) => {
//   return Yup.object().shape({
//     first_name: Yup.string().required('First name is required'),
//     last_name: Yup.string().required('Surname is required'),
//     job: Yup.string().required('Job is required'),
//     email: Yup.string().email('Email is invalid').required('Email is required'),
//     phone: phoneHelper,
//     postCode: Yup.string()
//       .matches(GBPostCode, 'You should input correct UK postal code')
//       .max(8, 'UK postal code length: ranging from six to eight characters (including a space)')
//       .required('UK postal code is required'),
//     ...(user ? {} : {
//       send_email: Yup.number()
//         .required('Password params is required'),
//     }),
//     building: Yup.string()
//       .required('Building params is required'),
//   });
// };


const FormOwner = ({owner}) => {
  const {usersStore, dialogStore, uiStore} = useStores();
  const [qp] = useQueryParams();


  const onSubmit = async(values) => {
    try {
      await usersStore.updateLocationOwner(values, owner.id);
      uiStore.createToast({status: 'success', title: 'Update owner', body: 'Location owner account was updated' });
      dialogStore.closeDialog('FormOwner');
      await usersStore.fetchLocationOwners(qp);
    }catch (e) {
      uiStore.createToast({status: 'danger', title: 'Update owner', body: `Error, ${uiStore.errorHandler(e)}` || 'Error, please try again' });
    }
  };


  return (
    <div className="border-top pt-4">
      <Formik
        initialValues={{
          first_name: (owner && owner.first_name) || '',
          last_name: (owner && owner.last_name) || '',
          email: (owner && owner.email) || '',
          phone: (owner && owner.phone) || '',
        }}
        enableReinitialize
        // validationSchema={industrySchema()}
        onSubmit={onSubmit}
      >{({errors, submitForm, touched, handleBlur, values}) => (
        <Form>
          <Row>
            <Col sm="12">
              <Row>
                <Col md={6}>
                  <FormGroup>
                    <FormInput
                      type="text"
                      name="first_name"
                      placeholder="First Name"
                      error={errors.first_name}
                      onBlur={handleBlur}
                      touched={touched.first_name}
                      value={values.first_name}
                    />
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <FormInput
                      type="text"
                      name="last_name"
                      placeholder="Surname"
                      error={errors.last_name}
                      onBlur={handleBlur}
                      touched={touched.last_name}
                      value={values.last_name}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <FormGroup>
                    <FormInput
                      type="email"
                      name="email"
                      placeholder="Email Address"
                      error={errors.email}
                      onBlur={handleBlur}
                      touched={touched.email}
                      value={values.email}
                    />
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <FormInput
                      type="text"
                      name="phone"
                      placeholder="Telephone number"
                      error={errors.phone}
                      onBlur={handleBlur}
                      touched={touched.phone}
                      value={values.phone}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </Col>
          </Row>
          <Button className="btn-red" onClick={submitForm}>{'Update'}</Button>
        </Form>
      )}
      </Formik>
    </div>
  );
};

export default observer(FormOwner);