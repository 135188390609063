import React, {useState} from 'react';
import {observer} from 'mobx-react-lite';
import './header.scss';
import {useStores} from '../../../hooks/use-stores';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';



const ProfileDropDown = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen(prevState => !prevState);

  const {authStore} = useStores();
  const user = authStore.currentUser;

  return (
    <Dropdown className="user-dropdown" isOpen={dropdownOpen} toggle={toggle}>
      <DropdownToggle className="user-name bg-transparent shadow-none border-0 p-0 d-flex align-items-center font-weight-bold" caret>
        {user.first_name} {user.last_name}
      </DropdownToggle>
      <DropdownMenu className={'p-0'}>
        <DropdownItem onClick={authStore.logout} className={'p-2'}>Log Out</DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
};

export default observer(ProfileDropDown);
