import {action, observable, runInAction} from 'mobx';
import axios from 'axios';


class GetAddressStore {

  @observable addressByCode = {
    fetching: false,
    isError: false,
    latitude: false,
    longitude: false,
    addresses: [],
    building: {}
  };

  @action getLocationByPostalCode = async ({postalCode, building = false}) => {
    const address = building ? building : false;
    const getAddressForResp = (address) => {
      const address1 = address.split(',')[0];
      const address2 = address.split(',')[1];
      const address3 = address.split(',')[2];
      const address4 = address.split(',')[3];

      return (address1 + ',' + address2 + ',' + address3 + ',' + address4).replace('.', '?').trim();

    };
    const path = `${postalCode ? postalCode.replace(/ /g,'') : ''}${address ? '/' + getAddressForResp(address) : ''}`;


    this.addressByCode.fetching = true;
    return axios.get(`https://api.getAddress.io/find/${path}`, {
      params: {
        'api-key': 'rYWuUWomzUmDLwpudLqNuQ21671',
        ...(address ? {expand: true} : {})
      }
    }).then(({data}) => {
      runInAction(() => {
        const {addresses, latitude, longitude, postcode} = data;
        if (data.postcode) {
          const {
            country,
            building_number,
            building_name,
            county,
            district,
            formatted_address,
            line_1,
            line_2,
            line_3,
            line_4,
            locality,
            sub_building_name,
            sub_building_number,
            thoroughfare,
            town_or_city
          } = addresses[0];
          this.addressByCode = {
            isError: false,
            fetching: false,
            latitude,
            longitude,
            postcode,
            building: {
              buildingName: building_name,
              buildingNumber: building_number,
              country,
              county,
              district,
              formattedAddress: formatted_address,
              line_1,
              line_2,
              line_3,
              line_4,
              locality,
              subBuildingName: sub_building_name,
              subBuildingNumber: sub_building_number,
              thoroughfare: thoroughfare,
              townOrCity: town_or_city
            },
            addresses: this.addressByCode.addresses
          };
        } else {
          this.addressByCode = {
            isError: false,
            fetching: false,
            latitude,
            longitude,
            addresses,
            building: {}
          };
        }
      });
      return data;
    }).catch(action((e) => {
      this.addressByCode.fetching = false;
      if(e) this.addressByCode.isError = true;
    }));
  };

  @action getAddressByPostalCode = async (params) => {
    const respData = await this.getLocationByPostalCode(params);
    const onLoad = this.addressByCode.postcode ? params.onLoadAddress : params.onLoadList;
    const { latitude, longitude } = this.addressByCode;
    params.onLoadAddress({latitude, longitude});
    if (onLoad) {
      const address = (respData && respData.addresses.find(a => params.building && params.building.includes(a.formatted_address[0]))) || this.addressByCode.addresses[0];
      const { line_1, line_2, line_3, county, town_or_city } = address;
      onLoad({ addressLine1: !line_3 || line_3.trim().length === 0 ? line_1 : line_1 + ', ' + line_2, addressLine2: !line_3 || line_3.trim().length === 0 ? line_2 : line_3, city: town_or_city, postCode: params.postalCode, county, latitude, longitude })
    }
  };

}

const getAddressStore = new GetAddressStore();
export default getAddressStore;
