import React, {useCallback, useEffect, useState} from 'react';
import {observer} from 'mobx-react-lite';
import {useStores} from '../../hooks/use-stores';
import {TabContent, TabPane, Nav, NavItem, NavLink, Row, Col, Spinner, Alert} from 'reactstrap';
import classnames from 'classnames';

import {useQueryParams} from '../../hooks/use-query-params';
import DocumentsHeader from './DocumentsHeader';
import DocumentsTable from './DocumentsTable';
import DocumentsFooter from './DocumentsFooter';
import debounce from 'lodash/debounce';

const Documents = () => {
  const {documentsStore} = useStores();
  const [qp, setQueryParams] = useQueryParams();
  const change = useCallback(setQueryParams, []);

  const [activeTab, setActiveTab] = useState('all-documents');

  const toggle = tab => () => {
    if (activeTab === tab) return;
    documentsStore.setFetching();
    if (activeTab !== tab) setActiveTab(tab);
    switch (tab) {
      case 'industry-professionals':
        change({type_location: 'industry'});
        break;
      case 'location-owners':
        change({type_location: 'location'});
        break;
      default:
        change({type_location: ''});
    }
  };


  const fetchDebounce = useCallback(debounce((qp) => {
    documentsStore.fetchDocuments(qp);
  }, 500), []);

  useEffect(() => {
    documentsStore.setFetching();
    fetchDebounce(qp);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [qp.page, qp.search, qp.sortBy, qp.filter, qp.type_location]);

  return (
    <div className="main-holder browse-documents">
      <div className="page-title mb-3 font-weight-bold">Browse all Documents</div>
      <div className="d-flex justify-space-between">
        <Nav tabs className="flex-nowrap">
          <NavItem>
            <NavLink
              className={classnames({active: activeTab === 'all-documents'})}
              onClick={toggle('all-documents')}
            >
              {/* <span className="icon-list-ol">All Documents</span> */}
              <span>All Documents</span>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({active: activeTab === 'location-owners'})}
              onClick={toggle('location-owners')}
            >
              {/* <span className="icon-map-marked-alt">Location Owners</span> */}
              <span>Location Owners</span>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({active: activeTab === 'industry-professionals'})}
              onClick={toggle('industry-professionals')}
            >
              {/* <span className="icon-map-marked-alt">Industry Professionals</span> */}
              <span>Industry Professionals</span>
            </NavLink>
          </NavItem>
        </Nav>
        <DocumentsHeader/>
      </div>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="all-documents">
          <Row>
            <Col sm="12">
              {documentsStore.fetching ? <Spinner color="gray"/> :
                <>
                  {documentsStore.documents.data.length
                    ?
                    <>
                      <DocumentsTable/>
                      <DocumentsFooter/>
                    </>
                    : <Alert color="light">
                      No results!
                    </Alert>
                  }
                </>
              }
            </Col>
          </Row>
        </TabPane>
        <TabPane tabId="location-owners">
          <Row>
            <Col sm="12">
              {documentsStore.fetching ? <Spinner color="gray"/> :
                <>
                  {documentsStore.documents.data.length
                    ?
                    <>
                      <DocumentsTable/>
                      <DocumentsFooter/>
                    </>
                    : <Alert color="light">
                      No results!
                    </Alert>
                  }
                </>
              }
            </Col>
          </Row>
        </TabPane>
        <TabPane tabId="industry-professionals">
          <Row>
            <Col sm="12">
              {documentsStore.fetching ? <Spinner color="gray"/> :
                <>
                  {documentsStore.documents.data.length
                    ?
                    <>
                      <DocumentsTable/>
                      <DocumentsFooter/>
                    </>
                    : <Alert color="light">
                      No results!
                    </Alert>
                  }
                </>
              }
            </Col>
          </Row>
        </TabPane>
      </TabContent>
    </div>
  );
};

export default observer(Documents);
