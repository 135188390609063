import React, {useState} from 'react';
import {useObserver} from 'mobx-react-lite';
import './table.scss';
import {Table} from 'reactstrap';

const DataTable = ({head = [], data = [], children, onChangeParams, params, striped = true, padding = true }) => {
  const {initialOrder, initialOrderBy} = params;
  const [order, setOrder] = useState(initialOrder);
  const [orderBy, setOrderBy] = useState(initialOrderBy);

  const handleSort = ({disableOrder, id}) => () => {
    if (disableOrder) return;
    const orderName = order === 'desc' && orderBy === id ? 'asc' : 'desc';
    setOrder(orderName);
    setOrderBy(id);
    onChangeParams({order: `${id} ${orderName}`});
  };

  return useObserver(() => (
    <div className={`main-table ${padding ? 'p-2' : ''} bg-white table-responsive`}>
        <Table className={`table-container mb-0 w-100 ${striped ? 'table-striped' : ''}`}>
          <thead>
          <tr>
            {head.map(h => (<th key={h.id} className="td-ellipsis pt-0 pr-1 pl-1 pb-1 border-top-0 text-capitalize" onClick={handleSort(h)}>
              <span>{h.label}</span>
              {orderBy === h.id && <span className="arrow">{order}</span>}
            </th>))}
          </tr>
          </thead>
          <tbody>
            {data.map(children)}
          </tbody>
        </Table>
    </div>
  ));
};

export default DataTable;
