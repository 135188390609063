import React from 'react';
import {observer} from 'mobx-react-lite';
import {useStores} from '../../hooks/use-stores';
import AdminForm from './NewAdmin/AdminForm';


const AdminHeader = () => {
  const {dialogStore} = useStores();

  const addNew = () => {
    dialogStore.openDialog(<AdminForm />, 'AdminForm', {title: 'Add a new member of staff'})
  };

  return (
    <div className="location-filters d-flex align-items-center justify-content-end">
      <div className="col-btn">
        <button type="button" className="btn btn-red" onClick={addNew}>Add new</button>
      </div>
    </div>
  );
};

export default observer(AdminHeader);
