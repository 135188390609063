import React from 'react';
import {observer} from 'mobx-react-lite';
import WidgetIndustryTable from './WidgetIndustryTable';
import WidgetLocationTable from './WidgetLocationTable';
import {Link} from 'react-router-dom';


const Tables = () => {

  return (
    <div className="tables-row d-flex justify-content-between"> 
      <div className="table-holder table-holder--alt">
        <h1 className="page-title mb-3 font-weight-bold">Currently Active Industry Professionals</h1>
        <WidgetIndustryTable/>
        <div className="text-right mt-3">
          <Link className="link" to="/industry-professionals?page=1">
            <span className="font-weight-bold">VIEW ALL</span>
          </Link>
        </div>
      </div>
      <div className="table-holder">
        <h1 className="page-title mb-3 font-weight-bold">Currently Active Location Owners</h1>
        <WidgetLocationTable/>
        <div className="text-right mt-3">
          <Link className="link" to="/browse-locations?page=1">
            <span className="font-weight-bold">VIEW ALL</span>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default observer(Tables);
