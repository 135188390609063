import React from 'react';
import { Row, Col} from 'reactstrap';
import {observer} from 'mobx-react-lite';
import {useStores} from '../../../hooks/use-stores';


const ResetPassword = ({user}) => {
  const {dialogStore, usersStore, uiStore} = useStores();

  const reset = async () => {
    try {
      await usersStore.resetPassword(user);
      uiStore.createToast({status: 'success', title: 'Reset password', body: `Email with a new password was sent to ${user.email}`});
      dialogStore.closeDialog('ResetPassword');
    } catch(e)  {
      uiStore.createToast({status: 'danger', title: 'Reset password', body: `Error, ${uiStore.errorHandler(e)}` || 'Error, please try again' });
    }
  };

  const close = () => {
    dialogStore.closeDialog('ResetPassword');
  };

  return (
    <div className="border-top pt-4">
      <Row className="mb-4">
        <Col>
          <span className="mr-2">Are you sure to reset password for:</span>
          <span>{user.email || user.user_email}</span>
        </Col>
      </Row>
      <Row>
        <Col md={12} className="col-md-12 d-flex justify-content-end"> 
          <button className="btn btn-outline mr-4" onClick={reset}>RESET</button>
          <button className="btn btn-red" onClick={close}>CANCEL</button>
        </Col>
      </Row>
    </div>
  )
};

export default observer(ResetPassword);