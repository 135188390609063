import {action, observable, runInAction} from 'mobx';
import api from '../../utils/api';


class DashboardStore {
  @observable stats = [];
  @action fetchDashboardStat = async () => {
    try {
      const resp = await api.get('/dashboard');
      runInAction(() => this.stats = resp.data.data);
    }catch (e) {
      throw e;
    }
  };
}

const dashboardStore = new DashboardStore();
export default dashboardStore;
