import {action, computed, observable, runInAction} from 'mobx';
import {go} from '../utils/history';
import api from '../utils/api';
import { create, persist } from 'mobx-persist'


class AuthStore {
  @observable auth = !!localStorage.getItem('UUID');

  @persist('object') @observable currentUser = {};

  @computed get isAuth() {
    return this.auth && !!localStorage.getItem('UUID');
  }

  @action login = async (values) => {
    const data = {
      email: values.email,
      password: values.password,
      remember: true
    };
    try {
      const resp = await api.post('/dashboard/auth/login', data);
      localStorage.setItem('UUID', resp.data.access_token);
      runInAction(() => this.auth = true);
      runInAction(() => this.currentUser = resp.data.user);
      go('/');
    }catch (err) {
      throw err;
    }
  };

  @action logout = () => {
    this.auth = false;
    this.currentUser = {};
    localStorage.removeItem('auth');
    localStorage.removeItem('UUID');
    go('/login');
  };
}

const hydrate = create({
  storage: localStorage,
  jsonify: true
});



const authStore = new AuthStore();
hydrate('auth', authStore).then(() => {});
export default authStore;
