import React from 'react';
import {observer} from 'mobx-react-lite';
import FormRadio from '../../../components/common/forms/FormRadio';

const IndustryAccess = (props) => {
  const handleChangeRadioButton = (value) => {
    props.setFieldValue('send_email', +value.target.value)
  };

  return (
    <div className="products-filter">
      <FormRadio
        onChange={handleChangeRadioButton}
        name="send_email"
        value={1}
        checked={props.values.send_email === 1}
        label={<span>User <b>will</b> be sent an email to create a new password</span>}
        touched={props.touched.send_email}
        error={props.errors.send_email}
      />
      <FormRadio
        onChange={handleChangeRadioButton}
        name="send_email"
        value={0}
        checked={props.values.send_email === 0}
        label={<span>Auto generate a password and <b>do not</b> send user an email</span>}
        touched={props.touched.send_email}
        error={props.errors.send_email}
      />

    </div>
  );
};

export default observer(IndustryAccess);