import React, {useState} from 'react';
import {observer} from 'mobx-react-lite';
import { Button, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import {Form, Formik} from 'formik';
import * as Yup from 'yup';
import classnames from 'classnames';
import AdminDetails from './AdminDetails';
import AdminAccess from './AdminAccess';
import {useStores} from '../../../hooks/use-stores';
import {useQueryParams} from '../../../hooks/use-query-params';

const adminSchema = (admin) => {
  return Yup.object().shape({
    first_name: Yup.string().required('First name is required'),
    last_name: Yup.string().required('Surname is required'),
    email: Yup.string().email('Email is invalid').required('Email is required'),
    ...(admin ? {} : {
      send_email: Yup.number()
        .required('Password params is required'),
    }),
  });
};
// const roleOptions = [
//   {label: 'Supervising Location Manager', value: 'Supervising Location Manager'},
//   {label: 'Location Manager', value: 'Location Manager'},
//   {label: 'Location Scout', value: 'Location Scout'},
//   {label: 'Assistant Location Manager', value: 'Assistant Location Manager'},
//   {label: 'Unit Manager', value: 'Unit Manager'},
//   {label: 'Art Director', value: 'Art Director'},
//   {label: 'Producer', value: 'Producer'},
//   {label: 'Director', value: 'Director'},
//   {label: 'Designer', value: 'Designer'},
//   {label: 'Line Producer', value: 'Line Producer'},
//   {label: 'Production Manager', value: 'Production Manager'},
//   {label: 'Director of Photography', value: 'Director of Photography'},
//   {label: 'Stills Photographer', value: 'Stills Photographer'},
//   {label: 'Other', value: 'Other'}
// ];
//
// const accessOptions = [
//   {label: 'Supervising Location Manager', value: 'Supervising Location Manager'},
//   {label: 'Location Manager', value: 'Location Manager'},
//   {label: 'Location Scout', value: 'Location Scout'},
// ];

const AdminForm = ({admin}) => {
  const [activeTab, setActiveTab] = useState('1');
  const [qp] = useQueryParams();

  const toggle = tab => {
    if(activeTab !== tab) setActiveTab(tab);
  };
  const {adminStore, uiStore, dialogStore} = useStores();

  const onSubmit = async(values) => {
    try {
      await adminStore.addNewAdmin(values);
      uiStore.createToast({status: 'success', title: 'Add admin', body: 'New admin account was created' });
      dialogStore.closeDialog('AdminForm');
      await adminStore.fetchAdminStaff(qp);
    }catch (e) {
      uiStore.createToast({status: 'danger', title: 'Add admin', body: `Error, ${uiStore.errorHandler(e)}` || 'Error, please try again' });
    }

  };

  const onUpdate = async(values) => {
    try {
      await adminStore.editAdmin(admin.id, values);
      uiStore.createToast({status: 'success', title: 'Edit admin', body: 'Admin account was updated' });
      dialogStore.closeDialog('AdminForm');
      await adminStore.fetchAdminStaff(qp);
    }catch(e)  {
      uiStore.createToast({status: 'danger', title: 'Edit admin', body: `Error, ${uiStore.errorHandler(e)}` || 'Error, please try again' });
    }
  };

  return (
    <div>
      <Formik
        initialValues={{
          first_name: (admin && admin.first_name) || '',
          last_name: (admin && admin.last_name) || '',
          email: (admin && admin.email) || '',
          phone: (admin && admin.phone) || '',
          role: (admin && admin.role) || '',
          send_email: '',
          is_admin: 1,
          photo: (admin && [admin.photo]) || [],
          id: (admin && admin.id) || null
        }}
        enableReinitialize
        validationSchema={adminSchema(admin)}
        onSubmit={admin ? onUpdate : onSubmit}
        >{({errors, submitForm, values, setFieldValue, setValues, touched, handleBlur}) => (
          <Form>
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === '1' })}
                  onClick={() => { toggle('1'); }}
                >
                  Contact details
                </NavLink>
              </NavItem>
              {!admin && <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === '2' })}
                  onClick={() => { toggle('2'); }}
                >
                  Password & access
                </NavLink>
              </NavItem>}
            </Nav>
            <TabContent activeTab={activeTab}>
              <TabPane tabId="1">
                <AdminDetails
                  setValues={setValues}
                  errors={errors}
                  // roleOptions={roleOptions}
                  setFieldValue={setFieldValue}
                  values={values}
                  touched={touched}
                  handleBlur={handleBlur}
                />
              </TabPane>
              {!admin && <TabPane tabId="2">
                <AdminAccess
                  setFieldValue={setFieldValue}
                  values={values}
                  touched={touched}
                  // accessOptions={accessOptions}
                  errors={errors}
                />
              </TabPane>}
            </TabContent>
            {admin ? <Button className="btn-red mb-3"  onClick={ submitForm }>{'Update'}</Button> :
            <Button className="btn-red mb-3"  onClick={activeTab === '1' ? () => { toggle('2'); } : submitForm }>{activeTab === '1' ? 'Next' : 'Add'}</Button>}
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default observer(AdminForm);