import React from 'react';
import {observer} from 'mobx-react-lite';


const Search = ({change, value}) => {

  const onChange = (e) => {
    change({search: e.target.value, page: 1});
  };

  return (
    <input className="form-input" type="search" placeholder="Search" onChange={onChange} value={value} />
  );
};

export default observer(Search);
