import React from 'react';
import {observer} from 'mobx-react-lite';
import FormRadio from '../../../components/common/forms/FormRadio';
import {Col, Row} from 'reactstrap';

const AdminAccess = (props) => {
  const handleChangeRadioButton = (value) => {
    props.setFieldValue('send_email', +value.target.value);
  };

  return (
    <div className="products-filter">
      <Row>
        <Col md={12}>
          <FormRadio
            onChange={handleChangeRadioButton}
            name="send_email"
            value={1}
            checked={props.values.send_email === 1}
            label={<span>User <b>will</b> be sent an email to create a new password</span>}
            error={props.errors.send_email}
            touched={props.touched.send_email}
          />
        </Col>
        <Col md={12}>
          <FormRadio
            onChange={handleChangeRadioButton}
            name="send_email"
            value={0}
            checked={props.values.send_email === 0}
            label={<span>Auto generate a password and <b>do not</b> send user an email</span>}
            error={props.errors.send_email}
            touched={props.touched.send_email}
          />
        </Col>
      </Row>
      {/*<Row>*/}
      {/*  <Col md={6}>*/}
      {/*    <FormGroup>*/}
      {/*      <FormSelect*/}
      {/*        name="level_of_access"*/}
      {/*        value={props.values.level_of_access}*/}
      {/*        onlyValue={true}*/}
      {/*        onChange={props.setFieldValue}*/}
      {/*        placeholder="Level of access"*/}
      {/*        options={props.accessOptions}*/}
      {/*        error={props.errors.level_of_access}*/}
      {/*      />*/}
      {/*    </FormGroup>*/}
      {/*  </Col>*/}
      {/*</Row>*/}

    </div>
  );
};

export default observer(AdminAccess);