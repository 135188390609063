import React from 'react';

const FormRadio = ({
  name,
  value,
  onChange,
  checked,
  label,
  disabled,
  error,
  touched
}) => {
  return (
    <>
      <label className="radio-container mb-0">
        <input
          type="radio"
          name={name}
          onChange={onChange}
          value={value}
          checked={checked}
          disabled={disabled}
        />
        <span className="radio-btn"/>
        <span className="radio-text">{label}</span>
      </label>
      {error && touched && (
        <div className="error-message">{error}</div>
      )}
    </>
  )
};

export default FormRadio;