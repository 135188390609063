import React from 'react';
import {Row, Col} from 'reactstrap';
import {observer} from 'mobx-react-lite';
import {useStores} from '../../../hooks/use-stores';
import {useQueryParams} from '../../../hooks/use-query-params';


const RemoveOwner = ({user}) => {
  const {dialogStore, uiStore, usersStore} = useStores();
  const [qp] = useQueryParams();

  const remove = async () => {
    try {
      await usersStore.removeLocationOwner(user.id);
      uiStore.createToast({status: 'success', title: 'Location owner removed', body: 'Location owner was removed' });
      dialogStore.closeDialog('RemoveOwner');
      await usersStore.fetchLocationOwners(qp);
    }catch(e)  {
      uiStore.createToast({status: 'danger', title: 'Location owner removed', body: `Error, ${uiStore.errorHandler(e)}` || 'Error, please try again' });
    }
  };

  const close = () => {
    dialogStore.closeDialog('RemoveOwner');
  };

  return (
    <div className="border-top pt-4">
      <Row className="mb-4">
        <Col>
          <span className="mr-2">Are you sure to remove location owner:</span>
          <span>{user.first_name} {user.last_name} ? </span>
          <span>All his listings will be removed permanently.</span>
        </Col>
      </Row>
      <Row>
        <Col md={12} className="col-md-12 d-flex justify-content-end">
          <button className="btn btn-outline mr-4" onClick={remove}>REMOVE</button>
          <button className="btn btn-red" onClick={close}>CANCEL</button>
        </Col>
      </Row>
    </div>
  )
};

export default observer(RemoveOwner);