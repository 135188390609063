import React, {useEffect, useState} from 'react';
import {observer} from 'mobx-react-lite';
import '../industry.scss';
import {Map, Marker} from 'google-maps-react';
import {initMap} from '../../../utils/google-api-helper';

const IndustryMap = ({user}) => {
  const [isGoogleMapsLoaded, trigger] = useState(false);
  // eslint-disable-next-line
  const [ref, setRef] = useState({});

  useEffect(() => {
    let isSubscribed = true;
    initMap().then(() => {
      if (isSubscribed) trigger(true);
    });
    return () => isSubscribed = false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const boundsCoords = [];
  user && boundsCoords.push({lat: +user.latitude, lng: +user.longitude});

  const bounds = isGoogleMapsLoaded && new window.google.maps.LatLngBounds();
  isGoogleMapsLoaded && boundsCoords.forEach(item => {
    if (!item.lat || !item.lng) return;
    bounds.extend(item);
  });

  return (
    <div>
      {isGoogleMapsLoaded &&
        <Map
          ref={setRef}
          google={window.google}
          zoom={10}
          center={boundsCoords[0] || {lat: 54.821970, lng: -5.418672}}
          fullscreenControl={false}
          mapTypeControl={false}
          streetViewControl={false}
        >
          <Marker
            key={user.id}
            id={user.id}
            name={user.name}
            position={{lat: user.latitude, lng: user.longitude}}
          />
        </Map>
      }
    </div>
  );
};

export default observer(IndustryMap);