import React from 'react';
import {observer} from 'mobx-react-lite';
import {Switch, Route, Redirect} from 'react-router-dom';
import './main.scss';
import LocationOwners from '../../../pages/LocationOwners/LocationOwners';
import IndustryProfessionals from '../../../pages/IndustryProfessionals/IndustryProfessionals';
import AdminStaff from '../../../pages/AdminStaff/AdminStaff';
import AddNewLocation from '../../../pages/AddNewLocation/AddNewLocation';
import Documents from '../../../pages/Documents/Documents';
import BrowseAllLocations from '../../../pages/BrowseAllLocations/BrowseAllLocations';
import EditLocation from '../../../pages/AddNewLocation/EditLocation';
// import ImportData from '../../../pages/ImportData/ImportData';
import Dashboard from '../../../pages/Dashboard/Dashboard';

const Main = () => {
  return (
    <div className="main d-flex h-100 overflow-auto">
        <Switch>
          <Route exact path="/" component={Dashboard}/>
          <Route path="/location-owners" component={LocationOwners}/>
          <Route path="/industry-professionals" component={IndustryProfessionals}/>
          <Route path="/admin-staff" component={AdminStaff}/>
          <Route path="/add-new-location" component={AddNewLocation}/>
          <Route path="/edit-location/:id" component={EditLocation}/>
          <Route path="/documents" component={Documents}/>
          <Route path="/browse-locations" component={BrowseAllLocations}/>
          {/*<Route path="/import-data" component={ImportData}/>*/}
          <Redirect to="/"/>
        </Switch>
    </div>
  );
};

export default observer(Main);
