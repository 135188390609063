import React from 'react';
import { Row, Col} from 'reactstrap';
import {observer} from 'mobx-react-lite';
import {useStores} from '../../../hooks/use-stores';
import AdminForm from './AdminForm';
import UserImage from '../../../assets/images/user-image.jpg';


const AdminView = ({admin}) => {
  const {dialogStore} = useStores();

  const edit = () => {
    dialogStore.closeDialog('AdminView');
    dialogStore.openDialog(<AdminForm admin={admin}/>, 'AdminForm',{title: `${admin.first_name || ''} ${admin.last_name || ''}`.trim()});
  };

  return (
    <div className="border-top pt-3">
      <Row className="mb-2">
        <Col md={2}><b>Name:</b></Col>
        <Col md={10}>{admin.first_name} {admin.last_name}</Col>
      </Row>
      <Row className="mb-2">
        <Col md={2}><b>Email:</b></Col>
        <Col md={10}>{admin.email}</Col>
      </Row>
      <Row className="mb-2">
        <Col md={2}><b>Photo:</b></Col>
        <Col md={10}>
          <div className="admin-photo-holder">
            <img src={admin.photo || UserImage} alt="" />
          </div>
        </Col>
      </Row>
      {/*<Row className="mb-2">*/}
      {/*  <Col md={2}><b>Job Title:</b></Col>*/}
      {/*  <Col md={10}>{admin.job}</Col>*/}
      {/*</Row>*/}
      <button className="btn btn-red" onClick={edit}>EDIT</button>
    </div>
  )
};

export default observer(AdminView);