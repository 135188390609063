import axios from 'axios';
import authStore from '../store/authStore';

const api = axios.create({
  baseURL: 'https://api.kayslocations.co.uk',
});

api.interceptors.request.use(request => {
  const UUID = localStorage.getItem('UUID');
  request.headers.Authorization = UUID ? `Bearer ${UUID}` : '';
  return request;
});

api.interceptors.response.use(response => {
    return response;
  },  error => {
  if (error && error.response && error.response.status  === 401) authStore.logout();
  return Promise.reject(error);
});

export default api;
