import React from 'react';

const FormCheckbox = ({
   name,
   value,
   onChange,
   checked,
   label,
 }) => {
  return (
    <>
      <label className="checkbox-container">
        <input
          type="checkbox"
          name={name}
          onChange={onChange}
          value={value}
          checked={checked}
        />
        <span className="checkbox-btn"/>
        <span className="checkbox-text">{label}</span>
      </label>
    </>
  )
};

export default FormCheckbox;