import React from 'react';
import {Row, Col} from 'reactstrap';
import {observer} from 'mobx-react-lite';
import {useStores} from '../../../hooks/use-stores';
import FormOwner from './FormOwner';


const ViewOwner = ({owner}) => {
  const {dialogStore} = useStores();

  const edit = () => {
    dialogStore.closeDialog('ViewOwner');
    dialogStore.openDialog(<FormOwner owner={owner}/>, 'FormOwner',{title: `Edit ${owner.first_name || ''} ${owner.last_name || ''}`.trim()});
  };

  return (
    <div className="border-top pt-3">
      <Row className="mb-2">
        <Col md={2}><b>Name:</b></Col>
        <Col md={10}>{owner.first_name} {owner.last_name}</Col>
      </Row>
      <Row className="mb-2">
        <Col md={2}><b>Email:</b></Col>
        <Col md={10}>{owner.email}</Col>
      </Row>
      <Row className="mb-2">
        <Col md={2}><b>Tel:</b></Col>
        <Col md={10}>{owner.phone}</Col>
      </Row>
      {/*<Row className="mb-2">*/}
      {/*  <Col md={2}><b>Job Title:</b></Col>*/}
      {/*  <Col md={10}>{owner.job}</Col>*/}
      {/*</Row>*/}
      <Row>
        <Col> 
          <button type="button"  className="btn btn-red" onClick={edit}>EDIT</button>
        </Col>
      </Row>
    </div>
  )
};

export default observer(ViewOwner);