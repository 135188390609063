import React, {useCallback} from 'react';
import {observer} from 'mobx-react-lite';
import {useQueryParams} from '../../hooks/use-query-params';
import UrlFilter from '../../components/url-filters/UrlFilter';
import UrlSort from '../../components/url-filters/UrlSort';
import Search from '../../components/search/Search';
import {useStores} from '../../hooks/use-stores';
import IndustryForm from './NewIndustry/IndustryForm';


const sortOptions = [
  { value: 'name', label: 'Name A-Z' },
  { value: 'county', label: 'County A-Z' },
  { value: 'city', label: 'City/County' },
  { value: 'modify', label: 'Last modified' },
  { value: 'recently', label: 'Recently added' }
  ];

const IndustryHeader = () => {
  const [qp, setQueryParams] = useQueryParams();
  const change = useCallback(setQueryParams, []);
  const {dialogStore, industryStore} = useStores();

  const filters = [{label: 'All', value: ''}, ...industryStore.industryFilters];

  const addIndustryModal = () => {
    dialogStore.openDialog(<IndustryForm/>, 'IndustryForm', {title: 'Add a new Industry Professional'})
  };

  return (
    <div className="location-filters d-flex align-items-center justify-content-end flex-grow-1">
      <div className="col-input w-100 px-1 position-relative">
        <Search value={qp.search || ''} change={change}/>
      </div>
      <div className="col-select col-select w-100 px-1">
        <UrlFilter options={filters} value={qp.filter} change={change} placeholder={'Filters'}/>
      </div>
      <div className="col-select col-select w-100 px-1">
        <UrlSort options={sortOptions} value={qp.sortBy} change={change} placeholder={'Sort by'}/>
      </div>
      <div className="col-btn pl-1">
        <button type="button" className="btn btn-red" onClick={addIndustryModal}>Add new</button>
      </div>
    </div>
  );
};

export default observer(IndustryHeader);
