import React, {useState} from 'react';
// import './styles.scss';
import { Collapse, CardBody, Card } from 'reactstrap';


const Accordion = ({
  children,
  isCollapsed = true,
  label = '',
  icon = ''
}) => {
  const [isOpen, setIsOpen] = useState(true);
  const toggle = () => setIsOpen(!isOpen);

  return (
    <div className="sidebar-item">
      <div className="top-level" onClick={isCollapsed ? toggle : null}>
        <i className={`icon icon-${icon}`}/>
        <span className="name txt">{label}</span>
        <span className={`arrow ${isOpen ? 'open' : ''}`}/>
      </div>

      <Collapse className="accordion-dropdown" isOpen={isCollapsed && isOpen}>
        <Card>
          <CardBody>
            {children}
          </CardBody>
        </Card>
      </Collapse>
    </div>
  )
};

export default  Accordion;