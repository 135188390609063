import React  from 'react';
import {observer} from 'mobx-react-lite';
import {
  Col,
  Row,
  FormGroup,
} from 'reactstrap';
import FormInput from '../../../components/common/forms/FormInput';
import DropZoneWrapper from '../../../components/common/forms/DropZoneWrapper'

const AdminDetails = (props) => {
  const DropzonePlaceHolder = (props) => {

    return(
      <div className="dropzone-placeholder text-center">Drag and drop a file here or click</div>
    )
  };
  return (
    <div>
      <Row>
        <Col sm="12">
          <Row>
            <Col md={6}>
              <FormGroup>
                <FormInput
                  type="text"
                  name="first_name"
                  placeholder="First Name"
                  error={props.errors.first_name}
                  onBlur={props.handleBlur}
                  touched={props.touched.first_name}
                  value={props.values.first_name}
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <FormInput
                  type="text"
                  name="last_name"
                  placeholder="Surname"
                  error={props.errors.last_name}
                  onBlur={props.handleBlur}
                  touched={props.touched.last_name}
                  value={props.values.last_name}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormGroup>
                <FormInput
                  type="email"
                  name="email"
                  placeholder="Email Address"
                  error={props.errors.email}
                  onBlur={props.handleBlur}
                  touched={props.touched.email}
                  value={props.values.email}
                />
              </FormGroup>
            </Col>
            {/*<Col md={6}>*/}
            {/*  <FormGroup>*/}
            {/*    <FormSelect*/}
            {/*      name="role"*/}
            {/*      value={props.values.role}*/}
            {/*      onlyValue={true}*/}
            {/*      onChange={props.setFieldValue}*/}
            {/*      placeholder="Select their role"*/}
            {/*      // touched={touched.job}*/}
            {/*      options={props.roleOptions}*/}
            {/*      error={props.errors.role}*/}
            {/*    />*/}
            {/*  </FormGroup>*/}
            {/*</Col>*/}
          </Row>
          <DropZoneWrapper
            className="dropzone--avatar"
            multi={false}
            quantity={1}
            placeholder={<DropzonePlaceHolder/>}
            description={'You can drag an image or click below to upload a profile photo for this user...'}
            note={'File types: JPG, PNG max file size 3mb'}
            acceptedFiles='image/*'
            setFieldValue={props.setFieldValue}
            values={props.values.photo}
            name={'photo'}
            id={props.values.id || null}
          />
        </Col>
      </Row>
    </div>
  );
};

export default observer(AdminDetails);