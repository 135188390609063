import React from 'react';
import {observer} from 'mobx-react-lite';
import {Switch, Route, Redirect} from 'react-router-dom';
import Login from '../pages/Login';

const UnAuthorizedLayout = () => {
  return (
    <div className="App d-flex h-100">
      <Switch>
        <Route path="/login" component={Login}/>
        <Redirect to="/login"/>
      </Switch>
    </div>
  );
};

export default observer(UnAuthorizedLayout);
