import React from 'react';
import { Popover, PopoverHeader, PopoverBody } from 'reactstrap';
import {useStores} from '../../../hooks/use-stores';
import {observer} from 'mobx-react-lite';

const ShowPopover = (props) => {
  const {uiStore} = useStores();

  const toggle = (id) => () => {
    uiStore.deletePopover(id);
  };

  return (
    <div>
      {uiStore.popoversList.map((popover) => (
        <Popover placement="bottom" trigger="legacy" key={popover.id} target={popover.target} isOpen={true} toggle={toggle(popover.id)}>
          <PopoverHeader>{popover.title}</PopoverHeader>
          <PopoverBody>
            {popover.content}
          </PopoverBody>
        </Popover>
      ))}
    </div>
  );
};

export default observer(ShowPopover);