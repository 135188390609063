import {action, computed, observable} from 'mobx';


class DialogStore {
  @observable dialogs = {
    // [name]: {
    //   data: {
    //     title: 'Add a new Industry Professional'
    //   },
    //   component: null
    // },
  };

  @action closeDialog = (name) => {
    delete this.dialogs[name];
  };

  @action openDialog = (component, name, data= {}) => {
    if (!name) {
      console.error('Invalid dialog component or missing name props:', component);
      return;
    }
    if (this.dialogs[name]) {
      console.error(`Modal with name "${name}" already opened`);
      return;
    }
    this.dialogs[name] = {component, data};
  };

  @computed get open() {
    return !!this.dialogs;
  }

  @computed get dialogsList() {
    return Object.keys(this.dialogs).map(key => ({
      key,
      ...this.dialogs[key]
    }));
  }
}

const dialogStore = new DialogStore();
export default dialogStore;
