import {action, computed, observable, runInAction} from 'mobx';
import api from '../../utils/api';


class DocumentsStore {
  @observable fetching = true;

  @observable typeLocations = [];

  @observable category = [];

  @observable documents = {
    data: [],
    total: 0,
    per_page: 1
  };

  @computed  get documentsPageCount() {
    return Math.ceil(this.documents.total / this.documents.per_page)
  };

  @computed get documentsCategory() {
    return this.category.map(i => ({value: i, label: i}));
  };

  @action setFetching = () => {
    this.fetching = true;
  };

  @action fetchDocuments = async (values) => {
    this.fetching = true;
    const data = {
      params: {
        search: values.search || '',
        filter: values.filter || '',
        sort_by: values.sortBy || '',
        type_location: values.type_location || '',
        page: values.page || ''
      }
    };
    try {
      const resp = await api.get('/dashboard/documents', data);
      runInAction(() => this.documents = resp.data.data);
      runInAction(() => this.typeLocations = resp.data.type_location);

    }catch(e)  {
      throw e;
    } finally {
      runInAction(() => {
        this.fetching = false;
      });
    }
  };

  @action fetchDocumentsCategory = async () => {
    try {
      const resp = await api.get('/dashboard/documents/list_category');
      runInAction(() => this.category = resp.data.data);
    }catch (e) {
      throw e;
    }finally {
      runInAction(() => {
        this.fetching = false;
      });
    }
  };

  @action deleteDocument = async (id) => {
    this.fetching = true;
    try {
      await api.delete(`/dashboard/documents/${id}`);
    }catch (e) {
      throw e;
    }finally {
      runInAction(() => {
        this.fetching = false;
      });
    }
  };

  @action addNewDocument = async (values) => {
    this.fetching = true;
    const formData = new FormData();
    formData.append('category', values.category);
    formData.append('type_location', values.location);
    formData.append('file', values.file);
    try {
      await api.post('/dashboard/documents',formData,
        {headers: {'content-type': `multipart/form-data`}}
      );
    }catch (e) {
      throw e;
    }finally {
      runInAction(() => {
        this.fetching = false;
      });
    }
  };

 @action downloadDocument = async (name) => {
   this.fetching = true;
   try {
     await api.get(`/dashboard/documents/${name}`,{
       responseType: 'blob'
     }).then((response) => {
       const url = window.URL.createObjectURL(new Blob([response.data]));
       const link = document.createElement('a');
       link.href = url;
       link.setAttribute('download', `file${name.match(/.[a-zA-Z0-9]+$/)[0]}`);
       document.body.appendChild(link);
       link.click();
       document.body.removeChild(link);
     });
   }catch (e) {
     throw e;
   }finally {
     runInAction(() => {
       this.fetching = false;
     });
   }
 };
}

const documentsStore = new DocumentsStore();
export default documentsStore;
