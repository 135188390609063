import React, {useEffect} from 'react';
import {observer} from 'mobx-react-lite';
import {useStores} from '../../hooks/use-stores';
import DataTable from '../../components/common/table/Table';
import {useTableParams} from '../../hooks/useTableParams';
import ViewOwner from './NewOwner/ViewOwner';
import FormOwner from './NewOwner/FormOwner';
import PopoverTable from './NewOwner/PopoverTable';
import ResetPassword from '../../components/containers/resetPassword/ResetPassword';
import RemoveOwner from './NewOwner/RemoveOwner';

const head = [
  {disableOrder: true, id: 'name', label: 'Name', className: 'wrapped-cell'},
  {disableOrder: true, id: 'email', label: 'Email', className: 'wrapped-cell'},
  {disableOrder: true, id: 'telephone', label: 'Telephone'},
  {disableOrder: true, id: 'createdOn', label: 'Created on'},
  {disableOrder: true, id: 'lastLoggedIn', label: 'Last Logged in'},
  {disableOrder: true, id: 'actions', label: 'Actions'}
];

const LocationTable = () => {
  const {usersStore, uiStore, dialogStore} = useStores();
  const tableParams = useTableParams();

  useEffect(() => {
    return () => {
      uiStore.clearPopovers();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const resetUserPassword = (row) =>  () => {
    dialogStore.openDialog(<ResetPassword user={row}/>, 'ResetPassword',{title: `Reset Password`, className: 'modal-confirm'})
  };

  const viewOwner = (row) => () => {
    dialogStore.openDialog(<ViewOwner owner={row} />, 'ViewOwner', {title: `View ${row.first_name || ''} ${row.last_name || ''}`.trim(), className: 'modal-confirm'})
  };

  const editOwner = (row) => () => {
    dialogStore.openDialog(<FormOwner owner={row}/>, 'FormOwner',{title: `Edit ${row.first_name || ''} ${row.last_name || ''}`.trim()})
  };

  const showLocation = (row) => (e) => {
    uiStore.createPopover({user: row, title: 'Linked locations', target: e.target, content: <PopoverTable id={row.id}/> });
  };

  const removeOwner = (row) => () => {
    dialogStore.openDialog(<RemoveOwner user={row} />, 'RemoveOwner', {title: `Remove ${row.first_name || ''} ${row.last_name || ''}`.trim(), className: 'modal-confirm'})
  };

  return (
    <DataTable
      params={tableParams}
      head={head}
      data={usersStore.locationOwners.data}
    >{
      (row, i) => (<tr key={i}>
        <td className="border-top-0 p-1 align-top wrapped-cell">{row.first_name || ''} {row.last_name || ''}</td>
        <td className="border-top-0 p-1 align-top wrapped-cell">{row.email}</td>
        <td className="text-nowrap border-top-0 p-1 align-top">{row.phone}</td>
        <td className="text-nowrap border-top-0 p-1 align-top">{row.created_at}</td>
        <td className="text-nowrap border-top-0 p-1 align-top">{row.updated_at}</td>
        <td className="text-nowrap border-top-0 p-1 align-top">
          <button className="btn-table icon icon-link-symbol mr-2 pl-0 border-0 font-weight-bold bg-transparent"  disabled={!row.locations_count} onClick={showLocation(row)}><span>LINKED LOCATIONS</span></button>
          <button className="btn-table icon icon-eye-open mr-2 border-0 font-weight-bold bg-transparent" onClick={viewOwner(row)}><span>VIEW</span></button>
          <button className="btn-table icon icon-edit mr-2 border-0 font-weight-bold bg-transparent" onClick={editOwner(row)}><span>EDIT</span></button>
          <button className="btn-table icon icon-key mr-2 border-0 font-weight-bold bg-transparent" disabled={row.disabled} onClick={resetUserPassword(row)}><span>SEND PASS RESET</span></button>
          <button className="btn-table icon icon-trash-alt btn-del mr-2 border-0 font-weight-bold bg-transparent" onClick={removeOwner(row)}><span>DELETE</span></button>
        </td>
      </tr>)
    }</DataTable>
  );
};

export default observer(LocationTable);
