import React from 'react';
import {observer} from 'mobx-react-lite';
import {useStores} from '../hooks/use-stores';
import Main from '../components/containers/main/Main';
import Header from "../components/containers/header/Header";
import Sidebar from "../components/containers/sidebar/Sidebar";

const AuthorizedLayout = () => {
  const {uiStore} = useStores();

  return (
    <div className={`main-container d-flex flex-row h-100 overflow-hidden ${uiStore.showSidebar ? 'is-sidebar-visible' : 'is-sidebar-hidden'}`}>
      <Sidebar />
      <div className="content d-flex flex-column h-100">
        <Header />
        <Main />
      </div>
    </div>
  );
};

export default observer(AuthorizedLayout);
