import React, { useEffect, useCallback, useRef } from 'react';
import { Row, Col, FormGroup } from 'reactstrap';
import {observer} from 'mobx-react-lite';

import {useStores} from '../../../hooks/use-stores';
import FormInput from './FormInput';
import FormSelect from './FormSelect';

const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

const FormGetAddress = (props) => {
  const {getAddressStore} = useStores();
  const apiAddresses =  getAddressStore.addressByCode.addresses.map(item => ({ label: item.replace(/(,\s){2,}/g, ', '), value: item }));
  const apiLoading =  getAddressStore.addressByCode.fetching;
  // const apiError = getAddressStore.addressByCode.isError;

  const prevPostCode = usePrevious(props.values.postCode);

  const getAddressApiRequest = useCallback(
    params => {
      getAddressStore.getAddressByPostalCode(params);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const onLoadAddress = address => {
    const newValues = {...props.values, ...address};
    if (prevPostCode && prevPostCode === props.values.postCode) {
      props.setValues(newValues)
    }

  };
  const isBuildingDisabled = (props.errors.postCode && !props.values.building) || !props.values.postCode;

  useEffect(
    () => {
      if (props.values.postCode.length > 4) {
        getAddressApiRequest({ postalCode: props.values.postCode, building: prevPostCode === props.values.postCode ? props.values.building : null, onLoadAddress })
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.values.postCode, props.values.building]
  );

  useEffect(
    () => {
      if (prevPostCode && prevPostCode !== props.values.postCode) {
        props.setFieldValue('building', '', false);
        props.setFieldValue('addressLine1', '', false);
        props.setFieldValue('addressLine2', '', false);
        props.setFieldValue('city', '', false);
        props.setFieldValue('county', '', false);
        props.setFieldValue('latitude', '', false);
        props.setFieldValue('longitude', '', false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.values.postCode]
  );


  return (
    <>
      <Row className="location-row-holder">
        <Col md={4}>
          <FormGroup>
          <div className="form-input-holder w-100 mb-2">
            <FormInput
              placeholder="Post code"
              type="text"
              name="postCode"
              touched={props.touched.postCode}
              error={props.errors.postCode}
              value={props.values.postCode}
            />
          </div>
          </FormGroup>
        </Col>
        <Col md={8}>
          <FormGroup>
          <div className="form-input-holder w-100 mb-2">
            <FormSelect
              className="form-select-container--light"
              name="building"
              onlyValue={true}
              value={props.values.building}
              onChange={props.setFieldValue}
              onBlur={props.onBlur}
              placeholder="Select your address"
              touched={props.touched.building}
              options={apiAddresses}
              isDisabled={isBuildingDisabled}
              error={props.errors.building}
              isLoading={apiLoading}
            />
            </div>
          </FormGroup>
        </Col>
      </Row>
      <Row className="location-row-holder">
        <Col md={6}>
          <FormGroup>
          <div className="form-input-holder w-100 mb-2">
            <FormInput
              type="text"
              name="addressLine1"
              value={props.values.addressLine1 || ''}
              placeholder="Address Line 1"
            />
          </div>
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
          <div className="form-input-holder w-100 mb-2">
            <FormInput
              type="text"
              name="addressLine2"
              value={props.values.addressLine2 || ''}
              placeholder="Address Line 2"
            />
          </div>
          </FormGroup>
        </Col>
      </Row>
      <Row className="location-row-holder">
        <Col md={6}>
          <FormGroup>
          <div className="form-input-holder w-100 mb-2">
            <FormInput
              type="text"
              name="city"
              value={props.values.city || ''}
              placeholder="City or town"
            />
          </div>
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
          <div className="form-input-holder w-100 mb-2">
            <FormInput
              type="text"
              name="county"
              value={props.values.county || ''}
              placeholder="County"
            />
          </div>
          </FormGroup>
        </Col>
      </Row>
    </>
  );
};

export default observer(FormGetAddress);
