import React from 'react';
import {Link} from 'react-router-dom';
import './card.scss';

const StatsCard = ({title, description, link, icon, color, count }) => {

  return (
    <div className={`stats-card stats-card--${color}`}>
        <div className="d-flex align-items-center">
            {icon &&
                <div className="stats-card-icon">
                    <div className={`icon ${icon}`}></div>
                </div>
            }
            <div className="flex-grow-1 overflow-hidden">
                <div className="stats-card-title text-white page-title mb-2 font-weight-bold">
                    <span className="mr-1">{count}</span>
                    <span>{title}</span>
                </div>
                <div className="stats-card-description text-white">Total to {description}</div>
                <div className="stats-card-link-holder text-right">
                    <Link className="link" to={link}>
                        <span className="text-white pr-4 position-relative">View
                            <span className="arrow"></span>
                        </span>
                    </Link>
                </div>
            </div>
        </div>
    </div>
  );
};

export default StatsCard;
