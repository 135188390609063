import React from 'react';
import {observer} from 'mobx-react-lite';
import {useStores} from '../../hooks/use-stores';
import DataTable from '../../components/common/table/Table';
import {useTableParams} from '../../hooks/useTableParams';
import AdminForm from './NewAdmin/AdminForm';
import AdminView from './NewAdmin/AdminView';
import AdminRemove from './NewAdmin/AdminRemove';
import ResetPassword from '../../components/containers/resetPassword/ResetPassword';


const head = [
  {disableOrder: true, id: 'name', label: 'Name', className: 'wrapped-cell'},
  // {disableOrder: true, id: 'role', label: 'Role'},
  {disableOrder: true, id: 'email', label: 'Email', className: 'wrapped-cell'},
  {disableOrder: true, id: 'createdOn', label: 'Created on'},
  {disableOrder: true, id: 'lastLoggedIn', label: 'Last logged in'},
  {disableOrder: true, id: 'actions', label: 'Actions'}
];

const AdminTable = () => {
  const {adminStore, dialogStore} = useStores();
  const tableParams = useTableParams();

  const resetUserPassword = (row) =>  () => {
    dialogStore.openDialog(<ResetPassword user={row}/>, 'ResetPassword', {title: `Reset Password`, className: 'modal-confirm'})
  };

  const editAdmin = (row) => () => {
    dialogStore.openDialog(<AdminForm admin={row} />, 'AdminForm', {title: `Edit ${row.first_name || ''} ${row.last_name || ''}`.trim()})
  };

  const viewAdmin = (row) => () => {
    dialogStore.openDialog(<AdminView admin={row} />, 'AdminView',{title: `View ${row.first_name || ''} ${row.last_name || ''}`.trim(), className: 'modal-confirm'})
  };

  const removeAdmin = (row) => () => {
    dialogStore.openDialog(<AdminRemove admin={row} />, 'AdminRemove', {title: `Remove ${row.first_name || ''} ${row.last_name || ''}`.trim(), className: 'modal-confirm'})
  };

  return (
    <DataTable
      params={tableParams}
      head={head}
      data={adminStore.adminStaff.data}
    >{
      (row, i) => (<tr key={i}>
        <td className="border-top-0 p-1 align-top wrapped-cell">{row.first_name} {row.last_name}</td>
        {/*<td className="text-nowrap border-top-0 p-1 align-top">{row.role}</td>*/}
        <td className="border-top-0 p-1 align-top wrapped-cell">{row.email}</td>
        <td className="text-nowrap border-top-0 p-1 align-top">{row.created_at}</td>
        <td className="text-nowrap border-top-0 p-1 align-top">{row.updated_at}</td>
        <td className="text-nowrap border-top-0 p-1 align-top">
          <button className="btn-table icon icon-eye-open mr-2 pl-0 border-0 font-weight-bold bg-transparent" onClick={viewAdmin(row)}><span>VIEW</span></button>
          <button className="btn-table icon icon-edit mr-2 border-0 font-weight-bold bg-transparent" onClick={editAdmin(row)}><span>EDIT</span></button>
          <button className="btn-table icon icon-key mr-2 border-0 font-weight-bold bg-transparent" disabled={row.disabled} onClick={resetUserPassword(row)}><span>SEND PASS RESET</span></button>
          <button className="btn-table icon icon-trash-alt btn-del mr-2 border-0 font-weight-bold bg-transparent" onClick={removeAdmin(row)}><span>DELETE</span></button>
        </td>
      </tr>)
    }</DataTable>
  );
};

export default observer(AdminTable);
