import React, {useEffect, useState} from 'react';
import {observer} from 'mobx-react-lite';
import '../industry.scss';
import '../../../components/common/table/table.scss';
import {Map, Marker} from 'google-maps-react';
import {initMap} from '../../../utils/google-api-helper';
import {useStores} from '../../../hooks/use-stores';
import IndustryForm from '../NewIndustry/IndustryForm';
import ViewIndustry from '../NewIndustry/ViewIndustry';
import InfoWindowEx from '../../../components/containers/googleInfoWindow/InfoWindowEx';

const MapViewIndustry = () => {
  const {industryStore, dialogStore} = useStores();

  const [isGoogleMapsLoaded, trigger] = useState(false);
  // eslint-disable-next-line
  const [ref, setRef] = useState({});
  const [activeMarker, setActiveMarker] = useState({});
  const [showingInfoWindow, setShowingInfoWindow] = useState(false);
  const [selectedPlace, setSelectedPlace] = useState({});
  // const [center, setCenter] = useState({lat: 54.821970, lng: -5.418672});


  useEffect(() => {
    let isSubscribed = true;
    initMap().then(() => {
      if (isSubscribed) trigger(true);
    });
    return () => {
      isSubscribed = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isJob = (job) => {
    return job ? `(${job})` : '';
  };

  const showModalEdit = (row) => () => {
    dialogStore.openDialog(<IndustryForm
      user={row}/>, 'IndustryForm', {title: `${row.first_name || ''} ${row.last_name || ''} ${isJob(row.job)}`.trim()});
  };

  const showModalView = (row) => () => {
    dialogStore.openDialog(<ViewIndustry
      user={row}/>, 'ViewIndustry', {title: `${row.first_name || ''} ${row.last_name || ''} ${isJob(row.job)}`.trim()});
  };

  const industryProfessionals = industryStore.industryProfessionals.data;


  const boundsCoords = [];
  industryProfessionals && industryProfessionals.forEach(item => {
    boundsCoords.push({lat: +item.latitude, lng: +item.longitude});
  });

  const bounds = isGoogleMapsLoaded && new window.google.maps.LatLngBounds();
  isGoogleMapsLoaded && boundsCoords.forEach(item => {
    if (!item.lat || !item.lng) return;
    bounds.extend(item);
  });

  const onMarkerClick = (props, marker) => {
    // setCenter({lat: +marker.user.latitude, lng: +marker.user.longitude});
    setSelectedPlace(props);
    setActiveMarker(marker);
    setShowingInfoWindow(true);
  };

  const onMapClick = (props) => {
    if (showingInfoWindow) {
      // eslint-disable-next-line no-unused-expressions
      setShowingInfoWindow(false);
      setActiveMarker(null);
    }
  };

  return (
    <div className="map-view p-2 bg-white">
      <div className="map-view-inner position-relative">
        {isGoogleMapsLoaded &&
        <Map
          ref={setRef}
          google={window.google}
          zoom={ 6 }
          initialCenter={{lat: 54.821970, lng: -5.418672}}
          // center={{lat: center.lat, lng:center.lng}}
          onClick={onMapClick}
          fullscreenControl={false}
          mapTypeControl={false}
          streetViewControl={false}
        >
          {industryProfessionals && industryProfessionals.map((item, index) => {
            return <Marker
              onClick={onMarkerClick}
              key={index}
              id={item.id}
              job={item.job || ''}
              user={item}
              name={`${item.first_name || ''} ${item.last_name || ''}`}
              position={{lat: +item.latitude, lng: +item.longitude}}
            />;
          })}
          <InfoWindowEx
            marker={activeMarker}
            visible={showingInfoWindow}
          >
            <div className="map-view-modal-inner">
              <div className="map-view-name">{selectedPlace.name}</div>
              <div className="map-view-job">{selectedPlace.job}</div>
              <button className="btn-table icon icon-eye-open mr-2 pl-0 border-0 font-weight-bold bg-transparent"
                      onClick={showModalView(selectedPlace.user)}><span>VIEW</span></button>
              <button className="btn-table icon icon-edit mr-2 border-0 font-weight-bold bg-transparent"
                      onClick={showModalEdit(selectedPlace.user)}><span>EDIT</span></button>
            </div>
          </InfoWindowEx>
        </Map>
        }
      </div>
    </div>
  );
};

export default observer(MapViewIndustry);