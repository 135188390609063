import React, {useCallback} from 'react';
import {observer} from 'mobx-react-lite';
import {useQueryParams} from '../../hooks/use-query-params';
import UrlPagination from '../../components/url-filters/UrlPagination';
import {useStores} from '../../hooks/use-stores';

const IndustryFooter = (props) => {
  const [queryParams, setQueryParams] = useQueryParams();
  const {industryStore} = useStores();
  const handlePageClick = useCallback((data) => {
    setQueryParams(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const showAll = () => {
    handlePageClick({per_page: industryStore.industryProfessionals.total, page: 1});
    props.setAll(true)
  };

  const hideAll = () => {
    handlePageClick({per_page: '', page: 1});
    props.setAll(false)
  };

  return (
    <div className="location-footer" style={{position: 'relative'}}>
      <UrlPagination
        change={handlePageClick}
        pageCount={industryStore.industryPageCount}
        page={queryParams.page}
      />
      <div className="pagination-show d-inline-block mr-4">Showing {industryStore.industryProfessionals.from}-{industryStore.industryProfessionals.to} of {industryStore.industryProfessionals.total}</div>
      {props.forMap && <button type='button' className="btn-table icon icon-users mr-2 pl-0 border-0 font-weight-bold bg-transparent" onClick={props.all ? hideAll : showAll}><span>{props.all ? 'Hide All' : 'Show All'}</span></button>}
    </div>
  );
};

export default observer(IndustryFooter);
