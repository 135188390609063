import React from 'react';
import {observer} from 'mobx-react-lite';
import DropZoneWrapper from '../../../components/common/forms/DropZoneWrapper';

const ContentLoaderForm = (props) => {

  const DropzonePlaceHolder = (props) => {

    return(
        <div className="dropzone-placeholder text-center">Drag and drop a file here or click</div>
    )
  };

  return (
    <div>
      <div className="location-item bg-white p-3 mb-3">
        <div className="form-title mb-3 font-weight-bold">Photos</div>
        <DropZoneWrapper
          className="dropzone--img"
          placeholder={<DropzonePlaceHolder/>}
          quantity={1}
          multi={false}
          description={'Upload your featured image. This will be the main image displayed in the search results and your gallery.'}
          note={'File types: JPG, PNG max file size 3mb'}
          acceptedFiles='image/*'
          setFieldValue={props.setFieldValue}
          values={props.values.image_featured_urls}
          name={'image_featured_urls'}
          id={props.values.id || null}
          setFeaturedError={props.setFeaturedError}
        />
        {/* {props.featuredError && (
          <div className="error-message">{'Please upload master photo'}</div>
        )} */}
        <DropZoneWrapper
          className="dropzone--img"
          placeholder={<DropzonePlaceHolder/>}
          multi={true}
          quantity={40}
          description={'Upload up to 40 images to your gallery. Select images that best show all angles and features of your location.'}
          note={'File types: JPG, PNG max file size 3mb'}
          acceptedFiles='image/*'
          setFieldValue={props.setFieldValue}
          values={props.values.image_urls}
          name={'image_urls'}
          id={props.values.id || null}
        />
      </div>
      <div>
      <div className="location-item bg-white p-3 mb-3">
        <div className="form-title mb-3 font-weight-bold">Floor plans</div>
        <DropZoneWrapper
          className="dropzone--img"
          placeholder={<DropzonePlaceHolder/>}
          multi={true}
          quantity={8}
          description={'You may upload up to 8 PDFs'}
          note={'File types: PDF max file size 3mb'}
          acceptedFiles='application/pdf'
          setFieldValue={props.setFieldValue}
          values={props.values.pdf_urls}
          name={'pdf_urls'}
          id={props.values.id || null}

        />
      </div>
      </div>
    </div>
  );
};

export default observer(ContentLoaderForm);