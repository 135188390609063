import React from 'react';
import {observer} from 'mobx-react-lite';
import {useStores} from '../../hooks/use-stores';
import DataTable from '../../components/common/table/Table';
import {useTableParams} from '../../hooks/useTableParams';
import {Link} from 'react-router-dom';
import generalConstants from '../../utils/constants';
import RemoveLocation from './RemoveLocation';
import ViewOwner from '../LocationOwners/NewOwner/ViewOwner';


const head = [
  {disableOrder: true, id: 'id', label: 'ID #'},
  {disableOrder: true, id: 'name', label: 'Locations name', className: 'wrapped-cell'},
  {disableOrder: true, id: 'owner', label: 'Owner/user', className: 'wrapped-cell'},
  {disableOrder: true, id: 'cityCounty', label: 'City/County', className: 'wrapped-cell'},
  {disableOrder: true, id: 'category', label: 'Category'},
  {disableOrder: true, id: 'account', label: 'Account status'},
  {disableOrder: true, id: 'created', label: 'Created on'},
  {disableOrder: true, id: 'last-modified', label: 'Last modified'},
  {disableOrder: true, id: 'type', label: 'Location type'},
  // {disableOrder: true, id: 'approved', label: 'Approved'},
  {disableOrder: true, id: 'actions', label: 'Actions'}
];

const locationTypes = {
  0: 'Standard',
  1: 'Featured'
};

const BrowseAllLocationsTable = () => {
  const {locationsStore, dialogStore} = useStores();
  const tableParams = useTableParams();

  const removeLocation = (location) => () => {
    dialogStore.openDialog(<RemoveLocation location={location} />, 'RemoveLocation', {title: `Remove location #${location.id}`, className: 'modal-confirm'})
  };

  const print = (id) => () => {
    locationsStore.printLocation(id)
  };

  const viewUser = (user) => () => {
    user && dialogStore.openDialog(<ViewOwner owner={user} />, 'ViewOwner', {title: `View ${(user && user.first_name) || ''} ${(user && user.last_name) || ''}`.trim(), className: 'modal-confirm'})
  };



  return (
    <DataTable
      params={tableParams}
      head={head}
      data={locationsStore.locations.data}
    >{
      (row, i) => (<tr key={i}>
        <td  className="text-nowrap border-top-0 p-1 align-top">
          <a href={`${generalConstants.stagingUrl}location_page/${row.id}`} target="_blank" rel="noopener noreferrer">{row.id}</a>
        </td>
        <td className="border-top-0 p-1 align-top wrapped-cell">{row.name}</td>
        <td className="border-top-0 p-1 align-top wrapped-cell" onClick={viewUser(row.user && row.user)}>{row.user && row.user.first_name} {row.user && row.user.last_name}</td>
        <td className="border-top-0 p-1 align-top wrapped-cell">{row.city || row.county}</td>
        <td title={row.categories ? row.categories.map(i => i.name) : null} className="text-nowrap border-top-0 p-1 align-middle td-ellipsis">{row.categories ? row.categories.map(i => i.name) : null}</td>
        <td className="text-nowrap border-top-0 p-1 align-top">
          <span className={`status ${row.payment}`}>{row.payment}</span>
        </td>
        <td className="text-nowrap border-top-0 p-1 align-top">{row.created_at}</td>
        <td className="text-nowrap border-top-0 p-1 align-top">{row.updated_at}</td>
        <td className="text-nowrap border-top-0 p-1 align-top">
          <span className={` location-type ${locationTypes[row.featured]}`}>{locationTypes[row.featured]}</span>
        </td>
        {/*<td className="text-nowrap border-top-0 p-1 align-top text-center td-approved">*/}
        {/*  {row.published === 0 && <span className="error icon-disable"/>}*/}
        {/*  {row.published === 1 && <span className="success icon-enable"/>}*/}
        {/*  {row.published === 2 && <span className="warning icon-pending"/>}*/}
        {/*</td>*/}
        <td className="text-nowrap border-top-0 p-1 align-top">
          <a href={`${generalConstants.stagingUrl}location_page/${row.id}`} target="_blank" rel="noopener noreferrer" className="btn-table icon icon-eye-open pl-0 mr-2 border-0 font-weight-bold bg-transparent"><span>VIEW</span></a>
          <Link  to={`/edit-location/${row.id}`} className="btn-table icon icon-edit mr-2 border-0 font-weight-bold bg-transparent text-uppercase">
              <span>Edit</span>
          </Link>
          <button className="btn-table icon icon-print mr-2 border-0 font-weight-bold bg-transparent" onClick={print(row.id)}><span>PRINT</span></button>
          <button className="btn-table btn-del icon icon-trash-alt mr-2 border-0 font-weight-bold bg-transparent" onClick={removeLocation(row)}><span>DELETE</span></button>

        </td>
      </tr>)
    }</DataTable>
  );
};

export default observer(BrowseAllLocationsTable);
