import uiStore from './uiStore';
import authStore from './authStore';
import usersStore from './users/usersStore';
import industryStore from './users/industryStore';
import getAddressStore from './getAddressStore';
import dialogStore from './dialogStore';
import adminStore from './users/adminStore';
import documentsStore from './documents/documentsStore';
import locationsStore from './locations/locationsStore';
import dashboardStore from './dashboard/dashboardStore';

export default {
  uiStore,
  authStore,
  usersStore,
  industryStore,
  getAddressStore,
  dialogStore,
  adminStore,
  documentsStore,
  locationsStore,
  dashboardStore
}
