import * as Yup from 'yup';

const GBPostCode = /([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\s?[0-9][A-Za-z]{2})/

const videoUrl = new RegExp(/^^(http:\/\/|https:\/\/)(player\.vimeo\.com|vimeo\.com|youtu\.be|www\.youtube\.com|my\.matterport\.com)\/([\w\\/]+)([\\?].*)?$/)

// const phoneHelper = Yup.lazy((val = '') => {
//     if (!val.startsWith('0')) {
//         return Yup.string().length(0, 'Should start with zero').required('Phone number is required')
//     } else if (val.startsWith('020')) {
//         return Yup.string().matches(/^020-\d{4}\s?\d{4}$/, 'Enter 020-XXXX XXXX || 0XXXX-XXX XXX')
//     } else if (val.match(/^\d{4}-/)) {
//         return Yup.string().matches(/^\d{4}-\d{3}\s?\d{4}$/, 'Enter 0XXX-XXX XXXX')
//     }
//     return Yup.string().matches(/^\d{5}-\d{3}\s?\d{3}$/, 'Enter 0XXXX-XXX XXX')
// });

const phoneHelper = Yup.string().required('Phone number is required')

const addressSchema = {
  postCode: Yup.string()
    .matches(GBPostCode, 'You should input correct UK postal code')
    .max(8, 'UK postal code length: ranging from six to eight characters (including a space)')
    .required('UK postal code is required'),
  building: Yup.string().required('Home is required')
  // addressLine1: Yup.string()
  //   .required
};

// export const addNewLocationSchema = () =>
export const addNewLocationSchema = () =>

  Yup.object().shape({
    name: Yup.string()
      .max(55, 'Name can contain maximum 55 characters')
      .required('Location Name is required field'),
    ...addressSchema,
    contact_name: Yup.string()
      .max(255, 'Contact name can contain maximum 255 characters'),
      // .required('Contact name is required field'),
    currentEmail: Yup.string()
        .when(['newEmail'], {
            is: (newEmail) => !newEmail,
            then: Yup.string().required('Contact Email address is required field'),
        }),
    newEmail: Yup.string()
        .when(['currentEmail'], {
            is: (currentEmail) => !currentEmail,
            then: Yup.string().required('Contact Email address is required field'),
        }),
    phone: phoneHelper,
    phone_professionals_only: Yup.bool().required(),
    age_style: Yup.array(Yup.number())
      .typeError('Please select one of the options')
      .max(3, 'You can select maximum three options'),
      // .required('Age or style of your location is required field'),
    interior_style: Yup.array(Yup.number())
      .typeError('Please select one of the options')
      .max(8, 'You can select not more then 8 interior style options'),
      // .required('Interior style is required field'),
    location_setting: Yup.number(),
      // .moreThan(0, 'Please select one of the options')
      // .required('Location setting is required field'),
    bedroomsNo: Yup.number(),
      // .moreThan(0, 'Please select one of the options')
      // .required('No of bedrooms is required field'),
    specific_features: Yup.array(Yup.number())
      .typeError('Please select one of the options'),
      // .required('Special Features is required field'),
    power_supply: Yup.number()
      // .moreThan(0, 'Please select one of the options')
      .required('Power supply is required field'),
    water_source: Yup.number()
      // .moreThan(0, 'Please select one of the options')
      .required('Water source is required field'),
    wifi: Yup.number()
      // .moreThan(0, 'Please select one of the options')
      .required('Wifi is required field'),
    parking: Yup.number()
      // .moreThan(0, 'Please select one of the options')
      .required('Parking availability is required field'),
    parking_details: Yup.string().max(255, 'Parking details can contain maximum 255 characters'),
    filming_availability: Yup.number()
      // .moreThan(0, 'Please select one of the options')
      .required('Filming availability is required field'),
    filming_details: Yup.string().max(255, 'Filming availability can contain maximum 255 characters'),
    additional_info: Yup.array(Yup.number()).typeError('Please select one of the options'),
    description: Yup.string()
      .max(1200, 'In description allowed maximum 1200 characters'),
      // .required(),
    video_type: Yup.number(),
    video_url: Yup.string()
      .matches(videoUrl, 'No valid link'),
    location_type: Yup.number()
      .required('Please select category'),
    location_category: Yup.array(Yup.number())
      .typeError('Please select one of the options')
      .required('Please select master category'),
    featured_location: Yup.number()
      .typeError('Please select one of the options')
      .required('Please select category'),
    // type: Yup.number(),
    email_address: Yup.string()
      .required('Email address is required field'),
  // });
}, ['newEmail', 'currentEmail']);


export const editLocationSchema = () =>
  Yup.object().shape({
      name: Yup.string()
        .max(55, 'Name can contain maximum 55 characters')
        .required('Location Name is required field'),
      ...addressSchema,
      contact_name: Yup.string()
        .max(255, 'Contact name can contain maximum 255 characters'),
        // .required('Contact name is required field')
      phone: phoneHelper,
      phone_professionals_only: Yup.bool().required(),
      age_style: Yup.array(Yup.number())
        .typeError('Please select one of the options')
        .max(3, 'You can select maximum three options'),
        // .required('Age or style of your location is required field'),
      interior_style: Yup.array(Yup.number())
        .typeError('Please select one of the options')
        .max(8, 'You can select not more then 8 interior style options'),
        // .required('Interior style is required field'),
      location_setting: Yup.number(),
        // .moreThan(0, 'Please select one of the options')
        // .required('Location setting is required field')
      bedroomsNo: Yup.number(),
        // .moreThan(0, 'Please select one of the options')
        // .required('No of bedrooms is required field'),
      specific_features: Yup.array(Yup.number())
        .typeError('Please select one of the options'),
        // .required('Special Features is required field'),
      power_supply: Yup.number()
        // .moreThan(0, 'Please select one of the options')
        .required('Power supply is required field'),
      water_source: Yup.number()
        // .moreThan(0, 'Please select one of the options')
        .required('Water source is required field'),
      wifi: Yup.number()
        // .moreThan(0, 'Please select one of the options')
        .required('Wifi is required field'),
      parking: Yup.number()
        // .moreThan(0, 'Please select one of the options')
        .required('Parking availability is required field'),
      parking_details: Yup.string().max(255, 'Parking details can contain maximum 255 characters'),
      filming_availability: Yup.number()
        // .moreThan(0, 'Please select one of the options')
        .required('Filming availability is required field'),
      filming_details: Yup.string().max(255, 'Filming availability can contain maximum 255 characters'),
      additional_info: Yup.array(Yup.number()).typeError('Please select one of the options'),
      description: Yup.string()
        .max(1200, 'In description allowed maximum 1200 characters'),
        // .required(),
      video_type: Yup.number(),
      video_url: Yup.string()
        .matches(videoUrl, 'No valid link'),
      location_type: Yup.number()
        .required('Please select category'),
      location_category: Yup.array(Yup.number())
        .typeError('Please select one of the options')
        .required('Please select master category'),
      featured_location: Yup.number()
        .typeError('Please select one of the options')
        .required('Please select category'),
      // type: Yup.number(),
    email_address: Yup.string()
      .required('Email address is required field'),
  });
