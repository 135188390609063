import React, {useCallback} from 'react';
import {observer} from 'mobx-react-lite';
import {useQueryParams} from '../../hooks/use-query-params';
import UrlSort from '../../components/url-filters/UrlSort';
import Search from '../../components/search/Search';
import {useStores} from '../../hooks/use-stores';
import DocumentForm from './NewDocument/DocumentForm';

const sortOptions = [
  { value: 'name', label: 'Document name A-Z' },
  { value: 'type', label: 'Type A-Z' },
  { value: 'category', label: 'Category A-Z' },
  ];

const DocumentsHeader = () => {
  const [qp, setQueryParams] = useQueryParams();
  const change = useCallback(setQueryParams, []);
  const {dialogStore} = useStores();

  const addDocumentModal = () => {
    dialogStore.openDialog(<DocumentForm/>, 'DocumentForm',{title: 'Add a new document', className: 'modal-document'})
  };

  return (
    <div className="location-filters d-flex align-items-center justify-content-end flex-grow-1">
      <div className="col-select col-select w-100 px-1">
        <UrlSort options={sortOptions} value={qp.sortBy} change={change} placeholder={'Sort by'}/>
      </div>
      <div className="col-input col-input w-100 px-1 position-relative">
        <Search value={qp.search || ''} change={change}/>
      </div>
      <div className="col-btn">
        <button className="btn btn-red" onClick={addDocumentModal}>Add new</button>
      </div>
    </div>
  );
};

export default observer(DocumentsHeader);
