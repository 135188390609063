import React from 'react';
import {observer} from 'mobx-react-lite';
import StatsInfoRow from './StatsInfoRow/StatsInfoRow';
// import StatsGraphics from './StatsGraphics/StatsGraphics';


const Widgets = () => {

  return (
    <div>
      <StatsInfoRow/>
      {/*<StatsGraphics/>*/}
    </div>
  );
};

export default observer(Widgets);
